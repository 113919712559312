import * as fs from "fs";
import * as $ from "jquery";
import * as moment from 'moment';
import * as ko from "knockout";
import '../node_modules/jwt-decode/build/jwt-decode.min.js';
import { Utils } from "./utils";
import { User, UserData, UserDataWrapper } from "./UserData";
import Navigo = require("navigo");
import { LawFirmEmployeAuthentication } from "./types/LawFirmEmployeAuthentication";
import { LawFirmRegistration } from "./types/LawFirmRegistration";
import { UserVerifyPassword } from "./types/UserVerifyPassword";
import { CalculateResubmission } from "./types/CalculateResubmission";

export class ReturnWrapper<T> {
    Type: string
    Payload: T

    constructor(type: string, payload: T) {
        this.Type = type;
        this.Payload = payload;
    }
}

$.ajaxSetup({
    xhrFields: {
        withCredentials: true
    },
    crossDomain: true
});


export class Routing {
    name: string
    params: any

    constructor(name: string, params: any) {
        this.name = name;
        this.params = params;
    }
}


export class RNSAPI {
    public static config = JSON.parse(fs.readFileSync(__dirname + "/../renocloudURL.json", "utf8"));
    public static origin = RNSAPI.config.renocloud;
    public static base = RNSAPI.origin + "/api"

    private static router = new Navigo(null, true);

    public static User: ko.Observable<User | undefined> = ko.observable(undefined)
    private static UserData: UserData = null;

    public static async receiveUserData() {
        if (!RNSAPI.UserData) {
            let data = (await RNSAPI.getUserData()).Payload;
            if (data.Value) {
                RNSAPI.UserData = (JSON.parse(data.Value));
            } else {
                RNSAPI.UserData = new UserData("foobar");
            }

            return RNSAPI.UserData;
        }
    };

    public static async writeUserData(data: UserData) {
        RNSAPI.UserData = data;
        RNSAPI.setUserData(data);
    }

    public static CurrentRouting: ko.Observable<Routing> = ko.observable<Routing>();

    public static Token = "";

    public static mapTypeToScope = {
        "mandant": "2",
        "gegner": "3",
        "bank": "4",
        "versicherung": "5",
        "behörde": "6",
        "anwalt": "7",
        "gericht": "8",
        "sonstige": "9"
    };

    public static AuthenticationFailed: number = 0;

    private static rpcRequest(url: string, data: string, httpType: string = "POST", cType: string = "application/json"): Promise<ReturnWrapper<any>> {
        return new Promise<ReturnWrapper<void>>(function (resolve, reject) {
            if (RNSAPI.AuthenticationFailed === 0) {
                $.ajax(`${RNSAPI.base}${url}`, {
                    data: data,
                    contentType: cType,
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('access_token', RNSAPI.Token);
                    },
                    method: httpType,
                    type: 'json',
                    error: function (jqXHR, exception) {
                        if (jqXHR.status === 401) {
                            RNSAPI.AuthenticationFailed = RNSAPI.AuthenticationFailed + 1;
                            if (RNSAPI.AuthenticationFailed === 1) {
                                alert("Ihre Session ist abgelaufen, bitte melden Sie sich erneut bei rainmaker an.");
                            }
                            Utils.eraseCookie('access_token');
                            RNSAPI.router.navigate('/login');
                        }
                    }
                }).done(resolve).fail(reject);
            }
        }
        );
    }

    private static rpcUploadRequest(url: string, data: string): Promise<ReturnWrapper<any>> {
        return new Promise<ReturnWrapper<void>>(function (resolve, reject) {
            if (RNSAPI.AuthenticationFailed === 0) {
                $.ajax(`${RNSAPI.base}${url}`, {
                    data: data,
                    contentType: false,
                    processData: false,
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('access_token', RNSAPI.Token);
                    },
                    method: "POST",
                    error: function (jqXHR, exception) {
                        if (jqXHR.status === 401) {
                            RNSAPI.AuthenticationFailed = RNSAPI.AuthenticationFailed + 1;
                            if (RNSAPI.AuthenticationFailed === 1) {
                                alert("Ihre Session ist abgelaufen, bitte melden Sie sich erneut bei cloud.rainmaker.de an.");
                            }
                            Utils.eraseCookie('access_token');
                            RNSAPI.router.navigate('/login');
                        }
                    }
                }).done(resolve).fail(reject);
            }
        }
        );
    }

    public static login(token: string) {
        RNSAPI.Token = token;
        let decoded = (window as any).jwt_decode(RNSAPI.Token);
        RNSAPI.User(new User(decoded.ShortHandSymbol, decoded.Role));
        Utils.createCookie("access_token", RNSAPI.Token, 1);
    }

    public static loginRegisterApi(url, data): Promise<ReturnWrapper<any>> {
        return new Promise<ReturnWrapper<void>>(function (resolve, reject) {
            $.ajax(`${url}`, {
                data: data,
                processData: false,
                contentType: false,
                // enctype: 'multipart/form-data',
                // contentType: 'application/x-www-form-urlencoded',
                type: "POST",
            }).done(resolve).fail(reject);
        });
    }

    public static getCurrentLoggedInUser() {
        const decoded = (window as any).jwt_decode(RNSAPI.Token);
        return decoded;
    }

    public static authUser = (authData: LawFirmEmployeAuthentication) => RNSAPI.rpcRequest("/Account/Auth", ko.toJSON(authData), "POST");
    public static registerLawFirm = (registerData: LawFirmRegistration) => RNSAPI.rpcRequest("/Account/Register", ko.toJSON(registerData), "POST");
    public static verifyUserPassword = (verifyData: UserVerifyPassword) => RNSAPI.rpcRequest("/Account/VerifyPassword", ko.toJSON(verifyData), "POST");
    public static uploadAccountImage = (uploadData: any) => RNSAPI.rpcUploadRequest("/Account/UploadImage", uploadData);
    public static getAppointment = (Id: string) => RNSAPI.rpcRequest("/Appointments/Get", JSON.stringify({ Id: Id }));
    public static createAppointment = (Appointment: any, OutlookExport: any, OutlookAccountId: any, Attendees: any, NotifySB: any) => RNSAPI.rpcRequest("/Appointments/Create", ko.toJSON({ Appointment, OutlookExport, OutlookAccountId, Attendees, NotifySB }));
    public static updateAppointment = (Appointment: any, exportToOutlook = false, outlookAccountId = "") => {
        let obj = {
            Appointment: Appointment,
            exportToOutlook: exportToOutlook,
            OutlookAccountId: outlookAccountId
        }
        return RNSAPI.rpcRequest("/Appointments/edit", ko.toJSON(obj))
    };
    public static deleteAppointment = (Id: string) => RNSAPI.rpcRequest("/Appointments/Delete", JSON.stringify({ Id: Id }));
    public static completeAppointment = (Id: string) => RNSAPI.rpcRequest("/Appointments/Complete", JSON.stringify({ Id: Id }));
    
    public static getAppointmentsByRange(start: Date, end: Date): Promise<ReturnWrapper<any>> {
        let startString = start.toISOString();
        let endString = end.toISOString();
        let data = JSON.stringify({
            StartDate: startString,
            EndDate: endString
        });
        return RNSAPI.rpcRequest("/Appointments/ByRange", data);
    }
    public static getAppointmentsForCase(caseId: string, start: Date, end: Date): Promise<ReturnWrapper<any>> {
        let startString = start.toISOString();
        let endString = end.toISOString();
        let data = JSON.stringify({
            CaseID: caseId,
            StartDate: startString,
            EndDate: endString
        });
        return RNSAPI.rpcRequest("/Appointments/ForCase", data);
    }
    public static getDeadlinesByRange = (start: moment.Moment, end: moment.Moment) => RNSAPI.rpcRequest("/Deadlines/ByRange", JSON.stringify({ StartDate: start.format("YYYY-MM-DD"), stopDate: end.format("YYYY-MM-DD") }));
    public static getDeadlinesForCase = (id: string) => RNSAPI.rpcRequest("/Deadlines/ForCase", JSON.stringify({ CaseId: id }));
    public static getDedlinesForLawfirms = (start: moment.Moment, end: moment.Moment, locations: any) => RNSAPI.rpcRequest("/Deadlines/ByLocationRange", JSON.stringify({ StartDate: start.format("YYYY-MM-DD"), StopDate: end.format("YYYY-MM-DD"), Locations: locations }));
    public static getDeadlineReasons = () => RNSAPI.rpcRequest("/Deadlines/GetReasons", JSON.stringify({}));
    public static createDeadline = (deadline: any) => RNSAPI.rpcRequest("/Deadlines/Create", JSON.stringify(deadline));
    public static calcDeadline = (obj: any) => RNSAPI.rpcRequest("/Deadlines/CalcDeadline", JSON.stringify(obj));
    public static completeDeadline = (deadline: any) => RNSAPI.rpcRequest("/Deadlines/Complete", JSON.stringify(deadline));
    public static extendDeadline = (oldDeadline: any, newDeadlineDate: string, newPreDeadlineDate: string, note: string, newClerk: string, outlookExport: boolean, reason = "") => {
        let obj = {

            NewDeadLineDate: newDeadlineDate,
            OldDeadLine: oldDeadline,
            NewPreDeadlineDate: newPreDeadlineDate,
            Note: note,
            NewClerk: newClerk,
            OutlookExport: outlookExport,
            Reason: reason
        };

        return RNSAPI.rpcRequest("/Deadlines/Extend", JSON.stringify(obj));
    }
    public static delegateDeadline = (oldDeadline: any, clerkId: string) => {
        let obj = {
            DeadLine: oldDeadline,
            NewUser: clerkId
        };
        return RNSAPI.rpcRequest("/Deadlines/Delegate", JSON.stringify(obj));
    }
    public static reportDeadline = (Start: moment.Moment, End: moment.Moment, filter: any) => RNSAPI.rpcRequest("/Deadlines/Report", JSON.stringify({ startDate: Start.format("YYYY-MM-DD"), endDate: End.format("YYYY-MM-DD"), Filter: filter }))
    public static getDeadlineProtocolTasks = (start: moment.Moment, end: moment.Moment) => RNSAPI.rpcRequest("/Task/DeadlinesByRange", JSON.stringify({ StartDate: start.format("YYYY-MM-DD"), stopDate: end.format("YYYY-MM-DD") }));
    public static getAppointmentProtocolTasks = (start: moment.Moment, end: moment.Moment) => RNSAPI.rpcRequest("/Task/AppointmentsByRange", JSON.stringify({ StartDate: start.format("YYYY-MM-DD"), stopDate: end.format("YYYY-MM-DD") }));
    
    public static reportLeadPartnerDeadline = (Start: moment.Moment, End: moment.Moment, ClerkId: string) => RNSAPI.rpcRequest("/Deadlines/ReportPerClerk", JSON.stringify({ Start: Start.format("YYYY-MM-DD"), End: End.format("YYYY-MM-DD"), ClerkId: ClerkId }))
    public static reportDeadlinePerDay = (Day: moment.Moment, Location: string, DeadlineCalendarAccountID: string) => RNSAPI.rpcRequest("/Deadlines/ReportPerDay", JSON.stringify({ Day: Day.format("YYYY-MM-DD"), Location: Location, DeadlineCalendarAccountID: DeadlineCalendarAccountID }))
    public static getFristDeadlineReasons = () => RNSAPI.rpcRequest("/Deadlines/GetFristExtensionReasons", JSON.stringify({}));
    public static insFristDeadlineReasons = (reason: string) => RNSAPI.rpcRequest("/Deadlines/InsertFristExtensionReasons", JSON.stringify({ Reason: reason }));
    public static delFristDeadlineReasons = (id: string) => RNSAPI.rpcRequest("/Deadlines/DeleteFristExtensionReasons", JSON.stringify({ Id: id }));
    public static createInbox = (inbox: any) => RNSAPI.rpcRequest("/Zpe/CreateInbox", ko.toJSON(inbox));
    public static updateInbox = (inbox: any) => RNSAPI.rpcRequest("/Zpe/UpdateInbox", ko.toJSON(inbox));
    public static getInboxById = (id: string) => RNSAPI.rpcRequest("/ZPE/GetAccount", JSON.stringify({ AccountId: id }));
    public static deleteInboxById = (id: string) => RNSAPI.rpcRequest("/ZPE/DeleteAccount", JSON.stringify({ AccountId: id }));
    public static getResubmissionByCaseId = (id: string) => RNSAPI.rpcRequest("/Resubmissions/GetByCaseId", JSON.stringify({ CaseId: id }));
    public static getResubmissionReasons = () => RNSAPI.rpcRequest("/Resubmissions/GetReasons", JSON.stringify({}));
    public static createResubmission = (resubmission: any) => RNSAPI.rpcRequest("/Resubmissions/Create", ko.toJSON(resubmission));
    public static updateResubmission = (resubmission: any) => RNSAPI.rpcRequest("/Resubmissions/Edit", ko.toJSON(resubmission));
    public static deleteResubmission = (ID: string) => RNSAPI.rpcRequest("/Resubmissions/Delete", JSON.stringify({ ID: ID }));
    public static completeResubmission = (ID: string) => RNSAPI.rpcRequest("/Resubmissions/Complete", JSON.stringify({ ID: ID }));
    public static calculateResubmission = (calc: CalculateResubmission) => RNSAPI.rpcRequest("/Resubmissions/Calculate", ko.toJSON(calc));
    public static suggestCaseId = () => RNSAPI.rpcRequest("/Cases/SuggestId", JSON.stringify({}));
    public static getSachbearbeiter = () => RNSAPI.rpcRequest("/Clerks/Get", JSON.stringify({}));
    public static getReferate = () => RNSAPI.rpcRequest("/Units/Get", JSON.stringify({}));
    public static getPostcode = (Postcode: string) => RNSAPI.rpcRequest("/Postcode/Postcode", JSON.stringify({ Postcode: Postcode }));
    public static addReferat = (pId: string, pName: string) => {
        let data = JSON.stringify({
            Id: pId,
            Name: pName
        });
        return RNSAPI.rpcRequest("/Units/Add", data);
    }
    public static getCases = () => RNSAPI.rpcRequest("/Cases/Search", JSON.stringify({ query: "" }));
    public static getCasesOverview = (page: any, pageSize: any) => RNSAPI.rpcRequest("/Cases/OverviewFilter", JSON.stringify({ Page: page, PageSize: pageSize }), "POST");
    public static getCaseByCaseId = (caseId: string) => RNSAPI.rpcRequest("/Cases/GetCase", JSON.stringify({ Id: caseId }));
    public static createCase = (newCase: any) => RNSAPI.rpcRequest("/Cases/Create", ko.toJSON(newCase));
    public static updateCase = (newCase: any) => RNSAPI.rpcRequest("/Cases/Update", ko.toJSON(newCase));
    public static updateCaseEntry = (entry: any) => RNSAPI.rpcRequest("/ECases/Set", ko.toJSON(entry));
    public static setCaseEntryWorkflowMark = (recordId: string, mark: string) => RNSAPI.rpcRequest("/ECases/SetWorkFlowMark", ko.toJSON({ RecordId: recordId, WorkFlowMark: mark }));
    public static getAllTagTypes = () => RNSAPI.rpcRequest("/Tags/GetAll", JSON.stringify({}));
    public static updateUserData = (data: any) => RNSAPI.rpcRequest("/LawFirm/UpdateUserData", ko.toJSON(data));
    public static createPerson = (person: any, handler: string) => RNSAPI.rpcRequest(`/Addresses/Add${handler}`, ko.toJSON(person));
    public static updatePerson = (person: any, type: string) => RNSAPI.rpcRequest(`/Addresses/Update${type}`, ko.toJSON(person));
    public static deletePerson(keyword: string, type: string): Promise<ReturnWrapper<any>> {
        let scope = RNSAPI.mapTypeToScope[type.toLowerCase()];
        return RNSAPI.rpcRequest(`/Addresses/Delete`, JSON.stringify({ PersonKeyword: keyword, Scope: scope }));
    }
    public static checkAssignment(keyword: string, type: string): Promise<ReturnWrapper<any>> {
        let scope = RNSAPI.mapTypeToScope[type.toLowerCase()];
        return RNSAPI.rpcRequest(`/Addresses/CheckAssignment`, JSON.stringify({ PersonKeyword: keyword, Scope: scope }));
    }
    public static joinPerson(personKeyword: string, caseId: string, handler: string): Promise<ReturnWrapper<any>> {
        let data = JSON.stringify({
            CaseId: caseId,
            PersonKeyword: personKeyword,
            Scope: RNSAPI.mapTypeToScope[handler.toLowerCase()]
        });
        return RNSAPI.rpcRequest(`/Addresses/Join`, data);
    }
    public static unjoinPerson(personKeyword: string, caseId: string, handler: string): Promise<ReturnWrapper<void>> {
        let data = JSON.stringify({
            CaseId: caseId,
            PersonKeyword: personKeyword,
            Scope: RNSAPI.mapTypeToScope[handler.toLowerCase()]
        });
        return RNSAPI.rpcRequest(`/Addresses/Unjoin`, data);
    }
    public static getPersonForKeyword(keyword: string, type: string): Promise<ReturnWrapper<any>> {
        let data = JSON.stringify({
            Scope: RNSAPI.mapTypeToScope[type.toLowerCase()],
            SearchValue: keyword
        });
        return RNSAPI.rpcRequest("/Addresses/ExactSearch", data);
    }
    public static getPersonsForCaseId = (caseId: string) => RNSAPI.rpcRequest("/Addresses/Search", JSON.stringify({ scope: 'bycaseid', searchValue: caseId }))
    public static getPersonsForScope(type: string): Promise<ReturnWrapper<any>> {
        let data = JSON.stringify({
            Scope: RNSAPI.mapTypeToScope[type.toLowerCase()],
            SearchValue: ""
        });
        return RNSAPI.rpcRequest("/Addresses/Search", data);
    }
    public static getAdressPagin = (type: number, filter: string) => RNSAPI.rpcRequest("/Addresses/Overview?page=1&pageSize=100000&scope=" + type + "&query=" + filter, null, "GET");
    public static checkCollision = (lastName: string) => RNSAPI.rpcRequest("/Addresses/CheckCollision", JSON.stringify({ searchValue: lastName }))
    public static deleteReferat = (id: string) => RNSAPI.rpcRequest("/Units/Delete", JSON.stringify({ Id: id }))
    public static updateReferat = (id: string, name: string) => RNSAPI.rpcRequest("/Units/update", JSON.stringify({ Id: id, Name: name }))
    public static addFrist = (fristData: any) => RNSAPI.rpcRequest("/Deadlines/AddReason", ko.toJSON(fristData));
    public static updateFrist = (updateData: any) => RNSAPI.rpcRequest("/Deadlines/UpdateReason", ko.toJSON(updateData));
    public static deleteFrist = (DeadlineNr: string) => RNSAPI.rpcRequest("/Deadlines//DeleteReason", JSON.stringify({ DeadlineNumber: DeadlineNr }));
    public static addWeider = (weiderData: any) => RNSAPI.rpcRequest("/Resubmissions/AddReason", ko.toJSON(weiderData));
    public static updateWeider = (updateData: any) => RNSAPI.rpcRequest("/Resubmissions/updateReason", ko.toJSON(updateData));
    public static deleteWeider = (DeadlineNr: string) => RNSAPI.rpcRequest("/Resubmissions/DeleteReason", JSON.stringify({ DeadlineNumber: DeadlineNr }));
    public static getRechtsformen = () => RNSAPI.rpcRequest("/LegalForms/Get", JSON.stringify({}));
    public static addRechtsformen = (addData: any) => RNSAPI.rpcRequest("/LegalForms/Add", ko.toJSON(addData));
    public static updateRechtsformen = (updateData: any) => RNSAPI.rpcRequest("/LegalForms/Update", ko.toJSON(updateData));
    public static deleteRechtsformen = (deleteData: string) => RNSAPI.rpcRequest("/LegalForms/Delete", JSON.stringify({ NameID: deleteData }));
    public static createExtendedCase = (eCase: any) => RNSAPI.rpcRequest("/ExtendedData/CreateCase", ko.toJSON(eCase));
    public static getExtendedCase = (eCase: string) => RNSAPI.rpcRequest("/ExtendedData/GetCase", JSON.stringify({ "id": eCase }));
    public static saveExtendedCase = (eCase: any) => RNSAPI.rpcRequest("/ExtendedData/UpdateCase", ko.toJSON(eCase));
    public static saveLawFirmAbonnementData = (data: any) => RNSAPI.rpcRequest("/LawFirm/UpdateAbonnement", ko.toJSON(data));
    public static getLawFirmAbonnementData = () => RNSAPI.rpcRequest("/LawFirm/GetAbonnement", JSON.stringify({}));
    public static createHistoryEntry = (registernummer: any, aktion: any) => RNSAPI.rpcRequest("/History/AddEntry", JSON.stringify({ "Case": registernummer, "Aktion": aktion }));
    public static getLawFirmUsers = () => RNSAPI.rpcRequest("/LawFirm/GetUsers", JSON.stringify({}));
    public static createLawFirmUser = (entryObject: any) => RNSAPI.rpcRequest("/LawFirm/CreateUser", ko.toJSON(entryObject));
    public static getLawFirmUser = (user: any) => RNSAPI.rpcRequest("/LawFirm/GetUser", JSON.stringify({ "Benutzer": user }));
    public static updateLawFirmUser = (entryObject: any) => RNSAPI.rpcRequest("/LawFirm/UpdateUser", ko.toJSON(entryObject));
    public static CreateLawFirm = (entryObject: any) => RNSAPI.rpcRequest("/LawFirms/Create", ko.toJSON(entryObject));
    public static deleteLawFirmUser = (userId: string) => RNSAPI.rpcRequest("/LawFirm/DeleteUser", JSON.stringify({ "UserId": userId }));
    public static lockLawFirmUser = (userId: string) => RNSAPI.rpcRequest("/LawFirm/LockUser", JSON.stringify({ "UserId": userId }));
    public static unlockLawFirmUser = (userId: string) => RNSAPI.rpcRequest("/LawFirm/UnlockUser", JSON.stringify({ "UserId": userId }));
    private static getUserData = () => RNSAPI.rpcRequest("/UserData/get", "") as Promise<ReturnWrapper<UserDataWrapper>>;
    private static setUserData = (data: UserData) => RNSAPI.rpcRequest("/UserData/set", JSON.stringify(new UserDataWrapper(data)));
    public static sessionLogout = () => RNSAPI.rpcRequest("/Account/Logout", "");
    public static UpdateTutorial = (Page: Number) => RNSAPI.rpcRequest("/Lawfirm/UpdateTutorial", JSON.stringify({ "Tutorial": ko.toJSON(Page) }));
    public static GetBank = (BLZ: string) => RNSAPI.rpcRequest("/Addresses/GetBank", JSON.stringify({ "SearchValue": BLZ }));
    public static GetStateByGerId = (GerId: string) => RNSAPI.rpcRequest("/Addresses/GetStateByGerId", JSON.stringify({ "GerId": GerId }));
    public static getAssignements = () => RNSAPI.rpcRequest("/Lawfirm/GetAssignments", JSON.stringify({}));
    public static assignLocation = (LawfirmID: string, UserID: string, Password: string) => RNSAPI.rpcRequest("/Lawfirm/Assign", JSON.stringify({ "LawfirmID": LawfirmID, "UserID": UserID, "Password": Password }));
    public static removeLocation = (LawfirmID: string, UserID: string) => RNSAPI.rpcRequest("/Lawfirm/Remove", JSON.stringify({ "LawfirmID": LawfirmID, "UserID": UserID }));
    public static addOutlook = (EWSURL: string, UserLogin: string, UserPassword: string, useOAuth: boolean) => RNSAPI.rpcRequest("/Outlook/Add", JSON.stringify({ "EWSURL": EWSURL, "UserLogin": UserLogin, "UserPassword": UserPassword, "UseOAuth2": useOAuth }));
    public static updateOutlook = (EWSURL: string, UserLogin: string, UserPassword: string, useOAuth: boolean, outlookAccountID: string) => RNSAPI.rpcRequest("/Outlook/Update", JSON.stringify({"EWSURL": EWSURL, "UserLogin": UserLogin, "UserPassword": UserPassword, "UseOAuth2": useOAuth, "OutlookAccountID": outlookAccountID, "LastChange": ""}));
    public static getOutlook = () => RNSAPI.rpcRequest("/Outlook/LoadAll", "{}");
    public static removeOutlook = (accountID: string) => RNSAPI.rpcRequest("/Outlook/RemoveAccount", JSON.stringify({ "AccountID": accountID }));
    public static notifyDeadline = (OutlookAccountID: string, deadline: any, notifySB = false, Attendees = []) => RNSAPI.rpcRequest("/Outlook/Export", JSON.stringify({ OutlookAccountID: OutlookAccountID, Deadline: deadline, NotifySB: notifySB, Attendees: Attendees }))
    public static redoOutlookDeadlineExport = (Deadline: any, Attendees = [], NotifySB: true) => RNSAPI.rpcRequest("/Deadlines/EWS", JSON.stringify({ Deadline: Deadline, Attendees: Attendees, NotifySB: NotifySB }))
    public static redoExternalDeadlineExport = (Deadline: any) => RNSAPI.rpcRequest("/Deadlines/External", JSON.stringify({ Deadline: Deadline }))
    public static editExternalUniqueId = (caseId: string, uniqueId: string, externalProgramm: string) => RNSAPI.rpcRequest("/ExtendedData/UpdateUniqueId", JSON.stringify({ caseId: caseId, uniqueId: uniqueId, externalProgramm: externalProgramm }))
    public static getInstances = () => RNSAPI.rpcRequest("/courtinstances", null, "GET");
    public static updateInstance = (id: string, name: string) => RNSAPI.rpcRequest("/courtinstances/update", JSON.stringify({ "id": ko.toJSON(id), "name": name.toString() }));
    public static createInstance = (name: string) => RNSAPI.rpcRequest("/courtinstances", JSON.stringify({ "instanceName": name.toString() }));
    public static createCourt = (name: string, city: string, keyword: string, mail: string, phone: string, postcode: string, street: string, state: number) => RNSAPI.rpcRequest("/addresses/AddCourt", JSON.stringify({ "Name": name, "City":city, "Keyword": keyword, "Mail": mail, "Phone": phone, "Postcode": postcode, "Street": street, "State": state }));
    public static setCourtStateByKeyword = (keyword: string, state: number) => RNSAPI.rpcRequest("/addresses/SetCourtState", JSON.stringify({"Keyword": keyword, "State": state }));
}

if (!localStorage["notes"]) localStorage["notes"] = JSON.stringify([]);
if (!localStorage["deadlines"]) localStorage["deadlines"] = JSON.stringify([]);
