import * as fs from "fs";
import * as ko from "knockout";
import Navigo = require("navigo");
import { Utils } from "../../utils";
import { RNSAPI } from "../../api";
import { Postbox } from "../postbox/postbox";

export class LayoutViewModel {
    router = new Navigo(null, true);

    currentUser = ko.observable({
        Anrede: '',
        kanzleiname: '',
        Name: ''
    });

    activeLink = ko.observable('');
    versionnumber = ko.observable('1.0.98');

    MALE_AVATAR = '/assets/dummypicture-man.png';
    FEMALE_AVATAR = '/assets/dummypicture-women.png';

    myRainmakerDropDown = ko.observable(false);
    lawFirmManagementDropDown = ko.observable(false);

    sidebarCollapsed = ko.observable(false);

    openDropDown(name: string) {
        switch (name) {
            case 'MyRainmaker':
                this.lawFirmManagementDropDown(false);
                this.myRainmakerDropDown(!this.myRainmakerDropDown());
                break;
            case 'LawFirmManagement':
                this.myRainmakerDropDown(false);
                this.lawFirmManagementDropDown(!this.lawFirmManagementDropDown());
                break;
        }
    }

    collapseSidebar() {
        this.sidebarCollapsed(!this.sidebarCollapsed());
        if (this.sidebarCollapsed()) {
            Postbox.publisher().publish(true, "CollapsedSidebar");
            $(".rainmaker-header").addClass('header-collapsed');
            $(".rainmaker-content").addClass('content-collapsed');
        } else {
            Postbox.publisher().publish(false, "CollapsedSidebar");
            $(".rainmaker-header").removeClass('header-collapsed');
            $(".rainmaker-content").removeClass('content-collapsed');
        }
    }

    logout() {
        Utils.eraseCookie('access_token');
        RNSAPI.sessionLogout();
        this.router.navigate('/login');
    }


    hidenavBar() {
        $("body").toggleClass('show-min-nav-bar');
    }

    activateLink(id: string, path: string = '/') {
        this.activeLink(id);
        this.router.navigate(path);
        $("html, body").animate({ scrollTop: 0 }, "fast");
    }

    openRecherche() {
        window.open("/#/recherche");
    }

    private async getAccountImage(): Promise<void> {
        $('#my_image').attr('src', this.currentUser().Anrede.includes('Frau') ? this.FEMALE_AVATAR : this.MALE_AVATAR);
    }

    private async setUser() {
        let getUser = RNSAPI.getCurrentLoggedInUser();
        let res = (await RNSAPI.getLawFirmUser(getUser.ShortHandSymbol)).Payload.User;
        let sideBarData = {
            Anrede: "",
            kanzleiname: res.LawfirmName,
            Name: res.Vorname + " " + res.Nachname
        }
        this.currentUser(sideBarData);
        this.getAccountImage();
    }

    constructor() {
        Postbox.publisher().subscribe(() => {
            this.setUser();
        }, "setLoggedInUserLayout");

        if (location.hash !== "#/login")
            this.setUser();

        Postbox.publisher().subscribe((response) => {
            if (response) {
                this.getAccountImage();
            }
        }, "RefreshLawFirmImage");
    }
}

var html = fs.readFileSync(__dirname + '/layout.html', 'utf8');

ko.components.register("layout-view", {
    viewModel: LayoutViewModel,
    template: html
});