import { Utils } from '../../utils';
import * as moment from 'moment';
import * as ko from "knockout";
import * as fs from 'fs';
import { RNSAPI } from "../../api";
import { MainViewModel } from '../../main';

export class DataTableViewModel {
    colLength = ko.observable();
    icons = ko.observable("");
    columns: Array<string>
    columnHeaders: Array<string>
    entries: ko.Computed<Array<any>>
    allItems: ko.ObservableArray<any>
    filteredItems: ko.Computed<any>
    filter = ko.observable('');
    sortByHeader = ko.observable("");
    sortBy = ko.computed({
        owner: this,
        read: () => {
            if (this.sortByHeader())
                return ko.toJS(this.columns)[ko.toJS(this.columnHeaders).indexOf(this.sortByHeader())];
            else
                return "";
        }
    });
    sortAsc = ko.observable(true);
    emptyMessage: string
    hasActions: boolean
    isEmpty: ko.Computed<boolean>
    hasButton = ko.observable(false);
    hasDTLayout = ko.observable(true);
    tooltips = ko.observableArray([]);
    resetpadleft = ko.observable(false);
    AllReferate = ko.observable();
    CheckedReferate = ko.observableArray();
    AllReferateUnchecked = ko.observable(true);

    showCaseViewButton = ko.observable(false);

    selectedElement = ko.observable(null);

    sort = (name: string) => {
        this.sortByHeader(name);
        this.sortAsc(!this.sortAsc())
    }

    addCaret = (name: string) => {
        if (this.sortByHeader() == name && this.sortAsc())
            return '<span class="fa fa-fw fa-sort-asc"></span>' + name;
        else if (this.sortByHeader() == name && !this.sortAsc())
            return '<span class="fa fa-fw fa-sort-desc"></span>' + name;
        return '<span class="fa fa-fw fa-sort"></span>' + name;
    }

    resetFilter = () => this.filter("");

    executeAction = (elem: any, handler: any) => {
        if (handler.selectable) {
            this.selectedElement(elem);
        }

        handler.action();
    }

    hasColumnAction = (parent: any, data: any) => parent.columnActions && parent.columnActions[data];
    hasTooltip = (parent: any, data: any) => parent.columnTooltips && parent.columnTooltips[data];


    postprocess = (elements: Array<HTMLElement>) => {
        for (let element of elements) {
            if (element.classList && element.classList.contains("tooltip-container")) {
                $(element).tooltip();
                break;
            }
        }
    };

    setFilter = (caseID: string) => {
        let somethingMarked = 0;
        for (let i = 0; i < this.entries().length; i++) {
            if (this.entries()[i].TransferToBill)
                somethingMarked++;
        }
        if (somethingMarked == 0)
            this.filter("");
        else {
            if (somethingMarked > 1) {
                $("#multipleDLK").show();
            }
            else {
                $("#multipleDLK").hide();
            }
            this.filter(caseID);
        }
    }

    async getReferate() {
        let res = (await RNSAPI.getReferate()).Payload.Units;
        let ref = res.map((r) => {
            r.NotChecked = false;
            return r;
        });
        this.AllReferate(ref);
    }

    changedRefs(refId: string) {
        let somethingChecked = false;
        this.CheckedReferate([]);
        for (let i = 0; i < this.AllReferate().length; i++) {
            if (ko.toJS(this.AllReferate()[i].Id) === refId)
                this.AllReferate()[i].NotChecked = !(<HTMLInputElement>document.getElementById(refId + "_referat_checkbox")).checked;
            if (ko.toJS(this.AllReferate()[i].NotChecked) === false) {
                this.CheckedReferate.push(this.AllReferate()[i].Name)
                somethingChecked = true;
            }
        }
        this.AllReferateUnchecked(!somethingChecked);
    }

    page = 1;
    totalCount = 0;
    cases = ko.observableArray([]);

    async loadCases() {
        let sb = (await RNSAPI.getSachbearbeiter()).Payload.Clerks;
        let SBs = new Map();
        sb.map((b) => { SBs.set(b.Sachbearbeiter_ID, b.Sachbearbeiter); return b })
        let cases = [];

        await RNSAPI.getCasesOverview(this.page, ko.toJS(20)).then((data) => {
            cases = data["Result"];
            this.totalCount = data["TotalAmount"];
        }).catch((error) => {
            cases = [];
        });

        cases.reverse();
        this.allItems(cases.map((c) => {
            c.SachbearbeiterName = SBs.get(c.SachbearbeiterId);

            c.actionHandlers = [{
                icon: "pencil-alt",
                name: "Bearbeiten",
                action: () => {
                    MainViewModel.RoutingTable.showNewView({ caseId: c.Registernummer });
                }
            }];

            if (c.IMEX) {
                c.actionHandlers.push({
                    icon: "file-import",
                    name: "Import von " + c.ExternalProgramId,
                    action: () => { }
                })
                c.actionHandlers.reverse();
            }

            c.columnActions = {
                Registernummer: MainViewModel.RoutingTable.generateLink(
                    `/new/${encodeURIComponent(c.Registernummer)}`
                ),
            };
            return c;
        }));
    }

    async extendCases() {
        let cases = [];
        this.page = ko.toJS(this.page) + 1;
        await RNSAPI.getCasesOverview(this.page, ko.toJS(20)).then((data) => {
            cases = data["Result"];
            this.totalCount = data["TotalAmount"];
        }).catch((error) => {
            cases = [];
        });

        cases.reverse();
        let newCases=(cases.map((c) => {
            c.actionHandlers = [{
                icon: "pencil-alt",
                name: "Bearbeiten",
                action: () => {
                    MainViewModel.RoutingTable.showNewView({ caseId: c.Registernummer });
                }
            }];

            if (c.IMEX) {
                c.actionHandlers.push({
                    icon: "file-import",
                    name: "Import von " + c.ExternalProgramId,
                    action: () => { }
                })
                c.actionHandlers.reverse();
            }

            c.columnActions = {
                Registernummer: MainViewModel.RoutingTable.generateLink(
                    `/new/${encodeURIComponent(c.Registernummer)}`
                ),
            };
            return c;
        }));

        this.allItems(this.allItems().concat(newCases));
    }

    constructor(params: any) {
        this.columns = ko.toJS(params.columns);
        this.columnHeaders = ko.toJS(params.columnHeaders);
        this.emptyMessage = params.emptyMessage;
        this.hasActions = params.hasActions;
        this.allItems = params.entries;
        if (params.hasButton)
            this.hasButton(ko.toJS(params.hasButton));
        else
            this.hasButton(false);
        this.hasDTLayout = params.hasDTLayout;
        this.showCaseViewButton = params.showCaseViewButton;
        this.resetpadleft = params.resetpadleft;
        if (params.tooltips) {
            this.tooltips = params.tooltips;
        }
        this.filteredItems = ko.computed({
            owner: this,
            read: () => {
                let filter = this.filter().toLowerCase();
                let items = this.allItems();

                return items.filter(item => {
                    for (let value of ko.toJS(this.columns).map(column => item[column]) as Array<string>) {
                        if (value && value.toLowerCase().indexOf(filter) !== -1) {
                            if (!ko.toJS(this.AllReferateUnchecked)) {
                                let refChecked = false;
                                for (let ref of ko.toJS(this.CheckedReferate)) {
                                    if (ref === item.Referat || ref === item.ref)
                                        refChecked= true;
                                }
                                return refChecked;
                            }
                            return true;
                        }
                    }
                    return false;
                });
            }
        });

        this.isEmpty = ko.computed({
            owner: this,
            read: () => {
                return this.filteredItems().length === 0;
            }
        });

        this.entries = ko.computed({
            owner: this,
            read: () => {
                let items = this.filteredItems();
                let sortBy = this.sortBy();
                let sortAsc = this.sortAsc();
                let isDate = null;
                if (items.length) {
                    items.forEach(element => {
                        isDate = !isDate ? /^\d{2}\.\d{2}\.\d{4}$/.test(element[sortBy]) : true;
                    });
                }
                items.forEach(element => {
                });
                if (sortBy && sortAsc) {
                    if (isDate) {
                        items.sort((a, b) => (moment.utc(a[sortBy], "DD.MM.YYYY", true) > moment.utc(b[sortBy], "DD.MM.YYYY", true)) ? 1 : -1);
                    }
                    else {
                        items.sort((a, b) => { return a[sortBy].trim().toLowerCase().localeCompare(b[sortBy].trim().toLowerCase()); });
                    }
                }
                else if (sortBy && !sortAsc) {
                    if (isDate) {
                        items.sort((a, b) => (moment.utc(a[sortBy], "DD.MM.YYYY", true) < moment.utc(b[sortBy], "DD.MM.YYYY", true)) ? 1 : -1);
                    }
                    else {
                        items.sort((a, b) => { return a[sortBy].trim().toLowerCase().localeCompare(b[sortBy].trim().toLowerCase()) * -1; });
                    }
                }
                else {

                }
                return Utils.partition(items, 10000000)[0];
            }
        });

        if (ko.toJS(this.showCaseViewButton) === true) {
            this.getReferate();
        }

        this.loadCases();

        window.addEventListener('scroll', (event) => {
            try {
                if ($(window).scrollTop() >= document.getElementById('dashboardView').offsetTop + document.getElementById('dashboardView').clientHeight - window.innerHeight) {
                    this.extendCases();
                }
            } catch{}
        });
    }
}

export interface Color {
    color: string
    "background-color": string
}

export class IntermediateListItem {
    stars: any
    entryType: string
    entryTypeName: string
    date: string
    dateObj: moment.Moment
    subject: string
    number: string
    originalObj: any
    actionHandlers: any
    color: Color
    rubrum: string
    entryType1: string
    SB: string
    note: string
    ref: string

    constructor(stars: any, entryType: string, entryTypeName: string, date: string, subject: string, number: string, originalObj: any, actionHandlers?: any, color?: Color, rubrum?: string, sb?: string, note?: string, ref?: string) {
        this.stars = stars
        this.entryType = entryType
        this.entryTypeName = entryTypeName;
        this.dateObj = moment.utc(date);
        this.date = this.dateObj.format("DD.MM.YYYY");
        this.subject = subject;
        this.number = number;
        this.originalObj = originalObj;
        this.actionHandlers = actionHandlers || [{ name: "Vorschau", action: () => { alert("Aktion für Typ: " + this.entryType) } }];
        this.color = color;
        this.rubrum = rubrum;
        this.note = note;
        this.SB = sb;
        this.ref = ref;
    }
};

var html = fs.readFileSync(__dirname + '/caseTable.html', 'utf8');

ko.components.register("case-table", {
    viewModel: DataTableViewModel,
    template: html
});