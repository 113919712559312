import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment";
import { Utils } from "../../utils";
import { RNSAPI } from "../../api";
import { Postbox } from '../postbox/postbox'
import './dialog';
import './message';
import Navigo = require("navigo");

export class resubmissionDialogViewModel {
    givenId = "NewDeadlineDialogExt";
    PregiID = "fristDeadlinePrelineDate";
    router = new Navigo(null, true);
    deadlineNr = ko.observable("");
    predeadlineNr = ko.observable("");
    deadlineCreationDate = ko.observable(moment().format("YYYY-MM-DD"));
    deadlinePrelineDate = ko.computed(() => {
        let predead = moment(ko.toJS(this.FristTemp), "YYYY-MM-DD").subtract(7, "day");
        if (ko.toJS(this.predeadlineNr) !== '') {
            predead = moment(ko.toJS(this.FristTemp), "YYYY-MM-DD").subtract(parseInt(ko.toJS(this.predeadlineNr)), "day");
        }
        return predead.format("YYYY-MM-DD");

    });
    akteFrist = this.newAkteFrist();
    infoMessage = ko.observable('');
    infoTitle = ko.observable('');
    modalHandleSelection = ko.observable();
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    fKAId = ko.observable('');
    fkAName = ko.observable('');
    isAfterFrist = ko.observable(false);
    savedSuccessfull = ko.observable(false);
    showOutlookLoader = ko.observable(false);
    showError = ko.observable(false);
    showExternError = ko.observable(false);
    showErrorOfInstances = ko.observable(false);
    showSuccess = ko.observable(false);
    showExternSuccess = ko.observable(false);
    isSelected = ko.observable(false);
    attendeesType() {
        let obj = {
            EMailAddress: ko.observable(''),
            IsRequired: true
        }
        return obj
    }
    attendees = ko.observableArray([]);
    attendeesOfSBs = ko.observableArray([]);
    InstanceInCase = ko.observableArray([]);
    FristTemp = ko.observable(moment().format("YYYY-MM-DD"));
    pastFristApproved = ko.observable(false);
    gerCase = ko.observable('');
    movedDeadlineText = ko.observable("");
    movedPreDeadlineText = ko.observable("");
    deactivateCalculation = ko.observable(false);
    verifyDeadlineDate = ko.observable("YYYY-MM-DD");
    notifyVerifyDeadline = ko.observable(false);
    newAkteFrist() {
        let obj = {
            SachbearbeiterId: ko.observable(RNSAPI.User().username),
            Sachbearbeiter: ko.observable(null),
            Clerk: ko.observable(null),
            CaseId: ko.observable(null),
            Rubrum: ko.observable("Kein Rubrum vorhanden."),
            DeadlineNumber: ko.observable("-/-"),
            DeadlineText: ko.observable(null),
            TypeId: ko.observable(""),
            TypeDescription: ko.observable(""),
            AddressKeyword: ko.observable(""),
            CompletedBy: ko.observable(""),
            CompletionDate: ko.observable(""),
            CreatedBy: ko.observable(""),
            StartDate: ko.observable(moment().format("YYYY-MM-DD")),
            Notizen: ko.observable(""),
            Note: ko.observable(""),
            Deadline: ko.observable("YYYY-MM-DD"),
            PreDeadline: ko.observable('YYYY-MM-DD'),
            State: ko.observable(""),
            Attendees: ko.observableArray([]),
            NotifySB: ko.observable(false),
            OutlookExport: ko.observable(false),
            InstanceId: ko.observable("")
        };
        return ko.observable(obj);
    }

    newNotifyObj() {
        let obj = {
            CaseId: ko.observable(""),
            TypeId: ko.observable(""),
            TypeDescription: ko.observable(""),
            Clerk: ko.observable(""),
            Deadline: ko.observable("")
        }
        return ko.observable(obj)
    }

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    }

    async postFrist() {
        $("#Deadline_Body").removeClass("show");
        $("#Deadline_Body").addClass("hide");
        $("#verifyDeadlineBody").removeClass("hide");
        $("#verifyDeadlineBody").addClass("show");
    }

    async verifyDeadline(){
        if(ko.toJS(this.akteFrist().Deadline) !== ko.toJS(this.verifyDeadlineDate()))
        {
            this.notifyVerifyDeadline(true);
        }
        else{
            $("#Deadline_Body").removeClass("hide");
            $("#Deadline_Body").addClass("show");
            $("#verifyDeadlineBody").removeClass("show");
            $("#verifyDeadlineBody").addClass("hide");
            this.postFristData();
        }
    }

    async closeModal() {
        $("#" + this.givenId).modal("hide");
        if (document.URL.includes("easycase")){
            this.router.navigate("/easycase");
            window.location.reload();
        }
    }

    formatDeadlineDuration(deadduration: string, preduration = "") {
        let duration_names = { s: { t: 'Tag', w: 'Woche', m: 'Monat', j: 'Jahr' }, p: { t: 'Tage', w: 'Wochen', m: 'Monate', j: 'Jahre' } }
        let oldDuration = ko.toJS(deadduration).slice(1);
        let durationtext = "";
        if (oldDuration.startsWith("0"))
            oldDuration = oldDuration.slice(1);
        if (/^\w\d+$/.test(deadduration)) {
            let duration = Number(deadduration.slice(1));
            let dtype = deadduration[0];
            durationtext = duration + ' ' + duration_names[duration == 1 ? 's' : 'p'][dtype];
        }
        if (ko.toJS(preduration) !== "") {
            try {
                let days = parseInt(preduration)
                if (days > 1)
                    preduration = preduration + " Tage";
                else
                    preduration = preduration + " Tag";

                durationtext = durationtext + " | Vorfrist: " + preduration;
            } catch{ }
        }
        return durationtext;
    }

    newCalculationCall() {
        let obj = {
            StartDate: ko.observable(ko.toJS(this.deadlineCreationDate)),
            DeadlineNumber: ko.observable(ko.toJS(this.akteFrist().DeadlineNumber)),
            State: ko.observable(ko.toJS(this.akteFrist().State))
        };
        return ko.observable(obj);
    }

    newCalculationDeadlineCall() {
        let obj = {
            StartDate: ko.observable(ko.toJS(this.akteFrist().Deadline)),
            DeadlineNumber: ko.observable(ko.toJS(this.akteFrist().DeadlineNumber)),
            State: ko.observable(ko.toJS(this.akteFrist().State))
        };
        return ko.observable(obj);
    }

    async calulateFristWithParameters() {
        if (ko.toJS(this.deadlineCreationDate) !== '' && ko.toJS(this.akteFrist().DeadlineNumber) !== "-/-" && ko.toJS(this.akteFrist().State) !== '') {
            let res = (await RNSAPI.calcDeadline(ko.toJS(this.newCalculationCall())));
            if (res.Type === "CalculationResult") {
                let form = res.Payload.Deadline.Deadline.split('T')[0];
                let pred = res.Payload.Deadline.PreDeadline.split('T')[0];
                let rawForm = res.Payload.Deadline.RawDeadline.split('T')[0];
                let rawPred = res.Payload.Deadline.RawPreDeadline.split('T')[0];

                if(rawForm !== form)                  
                    this.movedDeadlineText("Verlegt vom " + moment(rawForm).format("DD.MM.yyyy"));                    
                else
                    this.movedDeadlineText("");

                if(rawPred !== pred)
                    this.movedPreDeadlineText("Verlegt vom " + moment(rawPred).format("DD.MM.yyyy"));
                else
                    this.movedPreDeadlineText("");

                if(ko.toJS(this.deadlineNr) === "")
                    this.deactivateCalculation(true);
                
                this.akteFrist().Deadline(form);
                this.akteFrist().PreDeadline(pred);

                if(ko.toJS(this.deadlineNr) === "")
                    this.deactivateCalculation(false);
            }
        }
    }

    async calulateFristWithDeadline(){
        if(ko.toJS(this.deadlineNr) === "" && !ko.toJS(this.deactivateCalculation)){
            if (ko.toJS(this.deadlineCreationDate) !== '' && ko.toJS(this.akteFrist().DeadlineNumber) !== "-/-" && ko.toJS(this.akteFrist().State) !== '') {
                let res = (await RNSAPI.calcDeadline(ko.toJS(this.newCalculationDeadlineCall())));
                if (res.Type === "CalculationResult") {
                    let form = res.Payload.Deadline.Deadline.split('T')[0];
                    let pred = res.Payload.Deadline.PreDeadline.split('T')[0];
                    let rawForm = res.Payload.Deadline.RawDeadline.split('T')[0];
                    let rawPred = res.Payload.Deadline.RawPreDeadline.split('T')[0];

                    if(rawForm !== form)                  
                        this.movedDeadlineText("Kein Werktag (nächster Termin: " +moment(form).format("DD.MM.yyyy")+")");                    
                    else
                        this.movedDeadlineText("");

                    if(rawPred !== pred)
                        this.movedPreDeadlineText("Verlegt vom " + moment(rawPred).format("DD.MM.yyyy"));
                    else
                        this.movedPreDeadlineText("");


                    this.akteFrist().Deadline(rawForm);
                    this.akteFrist().PreDeadline(pred);
                }
            }
        }
    }

    async setParamsForInstance() {
        for (let i = 0; i < this.InstanceInCase().length; i++) {
            if (ko.toJS(this.InstanceInCase)[i].InstanceId === ko.toJS(this.akteFrist().InstanceId)) {
                this.setBLInstance(ko.toJS(this.InstanceInCase)[i].CourtId);
                this.gerCase(ko.toJS(this.InstanceInCase)[i].CourtId);
                this.attendees(ko.toJS(this.InstanceInCase)[i].Clerks);
            }
        }
    }

    async approvePastDeadline() {
        $("#Deadline_Body").removeClass("hide");
        $("#Deadline_Body").addClass("show");
        $("#approvePastDeadlineBody").removeClass("show");
        $("#approvePastDeadlineBody").addClass("hide");
        this.pastFristApproved(true);
        this.postFristData();
    }
    async editPastFrist() {
        this.pastFristApproved(false);
        $("#Deadline_Body").removeClass("hide");
        $("#Deadline_Body").addClass("show");
        $("#approvePastDeadlineBody").removeClass("show");
        $("#approvePastDeadlineBody").addClass("hide");
    }

    async cancelVerifyFrist(){
        this.verifyDeadlineDate("YYYY-MM-DD");
        this.notifyVerifyDeadline(false);
        $("#Deadline_Body").removeClass("hide");
        $("#Deadline_Body").addClass("show");
        $("#verifyDeadlineBody").removeClass("show");
        $("#verifyDeadlineBody").addClass("hide");
    }

    async postFristData() {
        this.showError(false);
        $(".form-group").each(function () {
            $(this).removeClass("has-error");
        });
        if (!Utils.checkErrors(["CaseId", "Sachbearbeiter", 'DeadlineText'], this.akteFrist(), "deadline", [Utils.checkString])) return;

        if (ko.toJS(this.InstanceInCase).length === 0) {
            this.showErrorOfInstances(true)
            return;
        }
        else
            this.showErrorOfInstances(false);

        let deadlineObj = ko.toJS(this.akteFrist());
        deadlineObj.OutlookExport = (<HTMLInputElement>document.getElementById('exportToFKA')).checked;
        deadlineObj.NotifySB = (<HTMLInputElement>document.getElementById('notifyClerk')).checked;
        deadlineObj.StartDate = moment(ko.toJS(this.deadlineCreationDate), "YYYY-MM-DD").format("YYYY-MM-DD");
        deadlineObj.Deadline = moment(deadlineObj.Deadline, "YYYY-MM-DD").format("YYYY-MM-DD");
        deadlineObj.PreDeadline = moment($("#" + this.PregiID).val(), "YYYY-MM-DD").format("YYYY-MM-DD");

        if (Number(moment(deadlineObj.PreDeadline).format("YYYYMMDD")) > Number(moment(deadlineObj.Deadline).format("YYYYMMDD"))) {
            $("#" + this.PregiID).addClass("has-error");
            this.isAfterFrist(true)
            return;
        }
        else {
            this.isAfterFrist(false);
            $("#" + this.PregiID).removeClass("has-error");
        }

        if ((Number(moment(moment.now()).format("YYYYMMDD")) > Number(moment(deadlineObj.Deadline).format("YYYYMMDD")) || Number(moment(moment.now()).format("YYYYMMDD")) > Number(moment(deadlineObj.PreDeadline).format("YYYYMMDD"))) && !ko.toJS(this.pastFristApproved)) {
            $("#Deadline_Body").removeClass("show");
            $("#Deadline_Body").addClass("hide");
            $("#approvePastDeadlineBody").removeClass("hide");
            $("#approvePastDeadlineBody").addClass("show");
            return;
        }
        else {
            this.pastFristApproved(false);
        }

        try {
            (<HTMLButtonElement>document.getElementById("postFristBtn")).disabled = true;
            if (ko.toJS(this.fKAId) !== '') {
                (<HTMLButtonElement>document.getElementById("exportToFKA")).disabled = true;
                (<HTMLButtonElement>document.getElementById("notifyClerk")).disabled = true;
            }

            let att = [];
            if (deadlineObj.OutlookExport && deadlineObj.NotifySB) {
                for (let d of ko.toJS(this.attendees)) {
                    for (let sbs of ko.toJS(this.attendeesOfSBs)) {
                        if (sbs.SachbearbeitertId === d.ClerkId) {
                            let ob = this.attendeesType();
                            ob.EMailAddress = sbs.Email;
                            ob.IsRequired = true;
                            att.push(ob);
                            break;
                        }
                    }
                }
            }
            deadlineObj.Attendees = att;
            this.showOutlookLoader(true); 

            await RNSAPI.createDeadline(deadlineObj).then((response) => {
                let result = response as any;
                this.savedSuccessfull(true);
                this.showOutlookLoader(false);
                if (result.OutlookExport === "SUCCESS") {
                    this.showError(false);
                    this.showSuccess(true);
                }
                else if (result.OutlookExport === "FAILURE") {
                    this.showSuccess(false);
                    this.showError(true);
                }
                else {
                    this.showError(false);
                    this.showSuccess(false);
                }

                if (result.ExternalExport === "SUCCESS") {
                    this.showExternError(false);
                    this.showExternSuccess(true);
                }
                else if (result.ExternalExport === "FAILURE") {
                    this.showExternSuccess(false);
                    this.showExternError(true);
                }
                else {
                    this.showExternError(false);
                    this.showExternSuccess(false);
                }

                setTimeout(() => {

                    if (!ko.toJS(this.showError)) {
                        let idname = "#" + this.givenId;
                        $(idname).modal("hide");
                        if (!window.location.href.includes('new')) {
                            this.akteFrist().CaseId("");
                            this.akteFrist().Rubrum("");
                        }
                        this.akteFrist().DeadlineText("");
                        this.akteFrist().Notizen("");
                        this.deadlineCreationDate(moment().format("YYYY-MM-DD"));
                        RNSAPI.createHistoryEntry(deadlineObj.CaseId, "Frist angelegt");

                        (<HTMLButtonElement>document.getElementById("postFristBtn")).disabled = false;
                        this.savedSuccessfull(false);
                        if (window.location.href.endsWith('tfw') || window.location.href.includes('new'))
                            Postbox.publisher().publish("", "updateTFW")

                        this.infoTitle('Erledigt')
                        this.infoMessage('Daten erfolgreich erfasst.')

                        setTimeout(() => {
                            $('#messageModal').modal('hide');
                        }, 3000);

                        if (ko.toJS(this.givenId) === 'EasyCaseNewDeadlineDialogExt') {
                            $("html, body").animate({ scrollTop: 0 }, "fast");
                            this.router.navigate("/easycase");
                            window.location.reload();
                        }
                    }

                    this.showOutlookLoader(false);
                    this.showError(false);
                    this.showExternError(false);
                    this.showSuccess(false);
                    this.showExternSuccess(false);

                    (<HTMLButtonElement>document.getElementById("exportToFKA")).disabled = false;
                    (<HTMLButtonElement>document.getElementById("notifyClerk")).disabled = false;

                }, 1000);
            }).catch((error) => {
                (<HTMLButtonElement>document.getElementById("postFristBtn")).disabled = false;
                (<HTMLButtonElement>document.getElementById("exportToFKA")).disabled = false;
                (<HTMLButtonElement>document.getElementById("notifyClerk")).disabled = false;
                console.log(error);
                this.infoTitle('')
                this.infoTitle('Fehler')
                this.infoMessage('');
                this.infoMessage('Fehler beim Anlegen der Frist.');
                $('#messageModal').modal('show');
            });
            
        } catch (e) {
            (<HTMLButtonElement>document.getElementById("postFristBtn")).disabled = false;
            (<HTMLButtonElement>document.getElementById("exportToFKA")).disabled = false;
            (<HTMLButtonElement>document.getElementById("notifyClerk")).disabled = false;
            console.log(e);
            this.infoTitle('')
            this.infoTitle('Fehler')
            this.infoMessage('');
            this.infoMessage('Fehler beim Anlegen der Frist.');
            $('#messageModal').modal('show');
        }
    }

    workDayOffset(startDate, endDate) {
        let startWeekDay = startDate.clone();
        if (startDate.day() != 0) {
            startWeekDay = startWeekDay.subtract(startDate.day(), 'day');
        }
        else {
            startWeekDay = startWeekDay.subtract(1, 'w');
        }
        let endWeekDay = endDate.clone();
        if (endDate.day() != 0) {
            endWeekDay = endWeekDay.add(7 - endDate.day(), 'day');
        }
        let diff = endWeekDay.diff(startWeekDay, 'days');
        let weekends = 2 * (Math.floor(diff / 7) - 1);
        return weekends;
    };

    checkFunctions() {
        setTimeout(() => {
            if (this.isSelected() && !this.openedModal())
                this.pickCase();
        }, 200);
    }

    openedModal = ko.observable(false);

    async pickCase() {
        this.openedModal(true);
        Postbox.publisher().publish("", "ResetDialogFilterOption");
        let cases = [];
        await RNSAPI.getCasesOverview(0, ko.toJS(20)).then((data) => {
            cases = data as any;
        }).catch((error) => {
            cases = [];
        });
        this.pickGeneric("Akte", ["caseid", "rubrum", "court_caseid_1", "refId"], ["Akte", "Rubrum", "AZ-Gericht 1. Instanz", "Standort"], cases);
        this.modalHandleSelection(async (selectedObject) => {
            this.akteFrist().CaseId(selectedObject()["caseid"]);
            let resCase = (await RNSAPI.getExtendedCase(ko.toJS(this.akteFrist().CaseId))).Payload;
            let res = resCase.Akte;
            this.akteFrist().Rubrum(res.Rubrum);

            this.gerCase('');
            if (res.Gericht1Id !== null && res.Gericht1Id !== '') {
                this.gerCase(res.Gericht1Id);
            }
            if (res.Gericht2Id !== null && res.Gericht2Id !== '') {
                this.gerCase(res.Gericht2Id);
            }
            if (res.Gericht3Id !== null && res.Gericht3Id !== '') {
                this.gerCase(res.Gericht3Id);
            }

            this.InstanceInCase(resCase.Instances);
            if (resCase.Instances.length > 0) {
                this.akteFrist().InstanceId(resCase.Instances[resCase.Instances.length - 1].InstanceId);
                this.showErrorOfInstances(false);
                (<HTMLButtonElement>document.getElementById("postFristBtn")).disabled = false;
            }
            else {
                this.showErrorOfInstances(true);
                (<HTMLButtonElement>document.getElementById("postFristBtn")).disabled = true;
            }

            this.attendeesOfSBs(res.WeitereSachbearbeiterRecords);

            if (ko.toJS(this.gerCase) !== '') {
                this.setBL();
            }

            this.getFKA();
        });
        $("#Deadmodal").modal("show");
        this.openedModal(false);
    }

    async getSBs(caseId: string) {
        let res = (await RNSAPI.getExtendedCase(caseId)).Payload;
        this.attendeesOfSBs(res.WeitereSachbearbeiterRecords);
    }

    async setBL() {
        var state = (await RNSAPI.GetStateByGerId(ko.toJS(this.gerCase))).Payload.state;
        this.akteFrist().State(state);
    }

    async setBLInstance(gerId) {
        var state = (await RNSAPI.GetStateByGerId(gerId)).Payload.state;
        (<HTMLSelectElement>document.getElementById("Bundesland")).value = state;
        this.akteFrist().State(state);
    }

    pickSachbearbeiter = async () => {
        Postbox.publisher().publish("", "ResetDialogFilterOption");
        let sachbearbeiter = (
            await RNSAPI.getSachbearbeiter()
        ).Payload.Clerks.filter((s) => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric(
            "Sachbearbeiter",
            ["Sachbearbeiter"],
            ["Sachbearbeiter"],
            sachbearbeiter
        );
        this.modalHandleSelection((selectedObject) => {
            this.akteFrist().SachbearbeiterId(selectedObject()["Sachbearbeiter_ID"]);
            this.akteFrist().Sachbearbeiter(selectedObject()["Sachbearbeiter_ID"]);
        });
        $("#Deadmodal").modal("show");
    };

    async pickDeadlineNumber() {
        Postbox.publisher().publish("", "ResetDialogFilterOption");
        let reasons = (await RNSAPI.getDeadlineReasons()).Payload.Reasons;
        //reasons = reasons.filter((a) => (a.DeadlineNumber !== "We" && a.DeadlineNumber !== "WE"));
        for (let i = 0; i < reasons.length; i++) {
            reasons[i].DeadlineDuration = this.formatDeadlineDuration(reasons[i].DeadlineDuration, "");
        }
        this.pickGeneric(
            "Grund",
            ["DeadlineDescription", "DeadlineDuration"],
            ["Beschreibung", "Fristdauer"],
            reasons
        );
        this.modalHandleSelection((selectedObject) => {
            this.akteFrist().DeadlineNumber(selectedObject()["DeadlineNumber"]);
            this.deadlineNr(selectedObject()["DeadlineDuration"] + this.formatDeadlineDuration("", selectedObject()["PreDeadlineDuration"]))
            this.akteFrist().DeadlineText(selectedObject()["DeadlineDescription"]);
            if (ko.toJS(this.deadlineNr) === '')
                document.getElementById('deadlineDeadlineDate').removeAttribute('disabled');
            else
                document.getElementById('deadlineDeadlineDate').setAttribute('disabled', "");

        });
        $("#Deadmodal").modal("show");
    }

    async getDefaultSachbearbeiter() {
        let sach = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(
            (d) => d.Sachbearbeiter_ID === RNSAPI.User().username
        );
        if (sach.length > 0) {
            this.akteFrist().Sachbearbeiter(sach[0].Sachbearbeiter_ID);
        }
    }

    async getFristenKalender(): Promise<any> {
        let result = { FKA: "", FKAId: "" };
        if (ko.toJS(this.akteFrist().CaseId) !== "" && ko.toJS(this.akteFrist().CaseId) !== null) {
            let res = (await RNSAPI.getExtendedCase(ko.toJS(this.akteFrist().CaseId))).Payload.Akte;
            result.FKA = res.DeadlineCalendarAccount;
            result.FKAId = res.DeadlineCalendarAccountId;
        }
        return result;
    }

    async getRubrumFromCase() {
        if (ko.toJS(this.akteFrist().CaseId) !== "") {
            let resCase = (await RNSAPI.getExtendedCase(ko.toJS(this.akteFrist().CaseId))).Payload;
            let res = resCase.Akte;
            this.akteFrist().Rubrum(res.Rubrum)

            this.gerCase('');
            if (res.Gericht1Id !== null && res.Gericht1Id !== '') {
                this.gerCase(res.Gericht1Id);
            }
            if (res.Gericht2Id !== null && res.Gericht2Id !== '') {
                this.gerCase(res.Gericht2Id);
            }
            if (res.Gericht3Id !== null && res.Gericht3Id !== '') {
                this.gerCase(res.Gericht3Id);
            }

            this.InstanceInCase(resCase.Instances);
            if (resCase.Instances.length > 0) {
                this.akteFrist().InstanceId(resCase.Instances[resCase.Instances.length - 1]).InstanceId;
                this.showErrorOfInstances(false);
                (<HTMLButtonElement>document.getElementById("postFristBtn")).disabled = false;
            }
            else {
                this.showErrorOfInstances(true);
                (<HTMLButtonElement>document.getElementById("postFristBtn")).disabled = true;
            }

            this.attendeesOfSBs(res.WeitereSachbearbeiterRecords);

            if (ko.toJS(this.gerCase) !== '') {
                this.setBL();
            }
        }
    }

    async getFKA() {
        let AccountId = await this.getFristenKalender();
        let res = (await RNSAPI.getOutlook()).Payload.OutlookAccounts;
        if (res.length > 0) {
            let account = "";
            let accountId = "";
            for (let acc of res) {
                if (acc.OutlookAccountID === AccountId.FKAId) {
                    account = acc.UserLogin;
                    accountId = acc.OutlookAccountID;
                }
            }
            if (account === "") {
                account = res[0].UserLogin;
                accountId = res[0].OutlookAccountID;
            }
            this.fkAName(account);
            this.fKAId(accountId);
        }
    }

    constructor(params: any) {
        if (params.Case) {
            this.akteFrist().CaseId(params.Case);
            this.getRubrumFromCase();
        }
        if (params.prefix) {
            this.givenId = params.prefix + this.givenId;
            this.PregiID = params.prefix + this.PregiID;
        }

        Postbox.publisher().subscribe((CaseID) => {
            this.akteFrist().CaseId(CaseID);
            this.getRubrumFromCase();
            this.getFKA();
        }, "SetCaseID");

        this.akteFrist().InstanceId.subscribe(() => this.setParamsForInstance());
        this.akteFrist().State.subscribe(() => this.calulateFristWithParameters());
        this.akteFrist().DeadlineText.subscribe(() => this.calulateFristWithParameters());
        this.akteFrist().Deadline.subscribe(() => this.calulateFristWithDeadline());
        this.deadlineCreationDate.subscribe(() => this.calulateFristWithParameters());
        this.isSelected.subscribe(() => this.checkFunctions());
        this.getDefaultSachbearbeiter();

        this.getFKA();
    }
}

let html = fs.readFileSync(__dirname + "/deadlineDialog.html", "utf8");

ko.components.register("deadline-dialog-view", {
    viewModel: resubmissionDialogViewModel,
    template: html,
});
