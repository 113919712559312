import * as fs from "fs";
import * as ko from "knockout";
import Navigo = require("navigo");
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import { Postbox } from "../postbox/postbox";

export class LoginViewModel {
    router = new Navigo(null, true);
    token = ko.observable("");
    password = ko.observable('');
    username = ko.observable('')
    courtId = ko.observable('')
    logins = ko.observable({
        username: ko.observable(''),
        courtId: ko.observable(''),
        password: ko.observable('')
    });

    checkLogin = ko.observable(false);

    async login() {
        if (!this.logins().password() || !this.logins().username() || !this.logins().courtId()) {
            $("#pc_validation_message").show();
            $("#pc_message").html("Es wurden nicht alle Pflichtfelder ausgefüllt.");
            return;
        }

        this.checkLogin(true);

        await RNSAPI.authUser({ LawFirmId: this.logins().courtId(), Username: this.logins().username(), Password: this.logins().password()}).then(data => {
            RNSAPI.login(data["AccessToken"]);
            Postbox.publisher().publish("", "setLoggedInUserHeader");
            Postbox.publisher().publish("", "setLoggedInUserLayout");
            MainViewModel.RoutingTable.showTfwView();
        }).catch(error => {
            if(error.status === 400) {
                this.checkLogin(false);
                document.getElementById('loginloader').setAttribute("style", "display: none;");
                $("#pc_validation_message").show();
                $("#pc_message").html("Bitte geben Sie die korrekten Zugangsdaten Ihres Rainmaker-Kontos an.");
            }
        });
    }

    constructor() {
        if (typeof localStorage !== 'undefined') {
            try {
                if (localStorage.getItem('LawFirmInfo')) {
                    let lawFirmInfo = JSON.parse(localStorage.getItem('LawFirmInfo'))
                    this.logins().username(lawFirmInfo['ShorthandSymbol'])
                    this.logins().courtId(lawFirmInfo['LawFirmId'])
                }
            } catch (e) { }
        }

        RNSAPI.AuthenticationFailed = 0;
    }
}

var html = fs.readFileSync(__dirname + '/login.html', 'utf8');

ko.components.register("login-view", {
    viewModel: LoginViewModel,
    template: html
});