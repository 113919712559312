import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import '../dialog/dialog';
import "../dialog/VerifyPasswordDialog";
import { MainViewModel } from "../../main";
import { Utils } from "../../utils";
import { Postbox } from "../postbox/postbox";
import moment = require("moment");

export class SettingsViewModel {
    deleteModalTitle = ko.observable("");
    deleteModalText = ko.observable("");
    temp = ko.observable("");
    error = ko.observable("");
    IsStandard = ko.observable(false);
    TextHeaderName = ko.observable("");
    fristModalTitle = ko.observable("FRISTGRUND ANLEGEN");
    referatModalTitle = ko.observable("NEUES REFERAT");
    weiderModalTitle = ko.observable("WIEDERVORLAGENGRUND ANLEGEN");
    rachtsModalTitle = ko.observable("NEUE RECHTSFORM");
    referateStatus = ko.observable(true);
    weiderStatus = ko.observable(true);
    fristStatus = ko.observable(true);
    rachtsStatus = ko.observable(true);
    yesDelete = ko.observable(false);
    lockStatus = ko.observable(false);
    users = ko.observableArray([]);
    anreden = ko.observableArray(['Bitte wählen Sie', 'Frau', 'Frau Dr.', 'Herr', 'Herr Dr.', 'Dr.', 'Prof.', 'Prof.Dr.']);
    rollen = ko.observableArray(['Anwalt', 'ReFa', 'PA / Sekretariat', 'Administrator']);
    inboxes = ko.observableArray([]);
    filterType = ko.observable('email');
    bl = ko.observable(false);
    HourlyMoney = ko.observable();
    filteredInboxes: ko.Computed<any>;
    allStandorte = ko.observableArray([]);
    allFKA = ko.observableArray([]);
    allInstances = ko.observableArray([]);
    allGerichtAddresses = ko.observableArray([]);
    allDeadlineExtensionReasons = ko.observableArray([]);
    DeadlineExtensionReason = ko.observable('');
    statusModalTitle = ko.observable("");
    mailAppend = ko.observable("@gmail.com");
    cityName = ko.observable("");
    emailID = ko.observable("");
    mailModalTitle = ko.observable("");
    statusModalText = ko.observable("");
    statusText = ko.observable("");
    statusVisible = ko.observable(false);
    hasError = ko.observable(false);
    currentUserSymbol = ko.observable();
    units = ko.observableArray([]);
    legalforms = ko.observableArray([]);
    documentData = null;
    reasons = ko.observableArray([]);
    fristReasons = ko.observableArray([]);
    referatResult = ko.observable("");
    lawFirmData = ko.observable({
        LawFirmName: ko.observable(""),
        Address: ko.observable(""),
        AdditionalAddress: ko.observable(""),
        PostCode: ko.observable(""),
        Place: ko.observable(""),
        Website: ko.observable(""),
        FormOfAddress: ko.observable(""),
        Firstname: ko.observable(""),
        Lastname: ko.observable(""),
        Phone: ko.observable(""),
        Mail: ko.observable(""),
        LawFirmId: ko.observable(""),
        Username: ko.observable(""),
        Password: ko.observable(""),
        PasswordRetry: ko.observable(""),
        SoldanMail: ko.observable(""),
        SoldanPassword: ko.observable(""),
        UserRole: ko.observable(""),
        RoleName: ko.observable(""),
        Title: ko.observable(""),
        Mobile: ko.observable(""),
        IsLocked: ko.observable(false),
        isAdmin: ko.observable(false),
    });
    lawFirmAbonnementData = ko.observable({
        SEPAError: ko.observable(false),
        Kreditinstitut: ko.observable(''),
        Kontoinhaber: ko.observable(''),
        BIC: ko.observable(''),
        IBAN: ko.observable(''),
        ExternalFlagNumber: ko.observable(''),
        ExternalFlagActive: ko.observable(false),
        ExternalErrorMessage: ko.observable(''),
        Produkt: ko.observable(''),
        StandardVersion: ko.observable(false)
    });
    showSEPA = ko.observable(false);
    showSEPAError = ko.observable(false);
    transmittSEPAError = ko.observable(false);
    transmittSEPASucce = ko.observable(false);
    enableConsultant = ko.observable(false);
    userInformation: any = ko.observable({});
    lawFirmDetails = ko.observable({});
    providersList: ko.ObservableArray<string> = ko.observableArray(["GMail", "GMX", "T-Online", "Me", "Web", "Domainfactory", "Yahoo", "AOL", "Andere"]);
    deleteDataValue = ko.observable("");
    newMailUser = ko.observable(false);
    IN_Proto = ko.observable("");
    showUserError = ko.observable("");
    base64Content = ko.observable("");
    expirationTime = ko.observable("W");
    expirationTimeF = ko.observable("w");
    durations = ['D', 'W', 'M', 'J'];
    durationsSinglar = ['Tag', 'Woche', 'Monat', 'Jahr'];
    durationsPlural = ['Tage', 'Wochen', 'Monate', 'Jahre'];
    FileName = ko.observable("");
    hasRightsForStandorte = ko.observable(false);
    testAboEnd = ko.observable("");
    aboAlert = ko.observable(false);
    standLawfirmID = ko.observable("");
    standUserID = ko.observable("");
    standPassword = ko.observable("");
    createDeadlineReason = ko.observable(true);
    useOAuth_Creation = ko.observable(false);
    editFKA = ko.observable(false);
    editInstanceId = ko.observable("");
    editInstanceName = ko.observable("");

    Ger_Name = ko.observable("");
    Ger_City = ko.observable("");
    Ger_Keyword = ko.observable("");
    Ger_Mail = ko.observable("");
    Ger_Phone  = ko.observable("");
    Ger_Postcode  = ko.observable("");
    Ger_Street = ko.observable("");
    Ger_State = ko.observable(0);

    Edit_Ger_Keyword = ko.observable("");
    Edit_Ger_State = ko.observable(0);


    createInstanceName = ko.observable("");

    assignedUserCases = ko.observable([]);

    EWSURL = ko.observable("");
    UserLogin = ko.observable("");
    UserPassword = ko.observable("");
    OutlookAccountID = ko.observable("");

    uploadAccountImageError = ko.observable("");

    statesTable = {
    0: "Deutschland",
    2: "Baden-Württemberg",
    3: "Bayern (EV)",
    4:"Bayern (RK)",
    5:"Berlin",
    6:"Brandenburg",
    7:"Bremen",
    8:"Hamburg",
    9:"Hessen",
    10:"Mecklenburg-Vorpommern",
    11:"Niedersachsen",
    12:"Nordrhein-Westfalen",
    13:"Rheinland-Pfalz",
    14:"Saarland",
    15:"Sachsen",
    16:"Sachsen-Anhalt",
    17:"Schleswig Holstein",
    18:"Thüringen (EV)", 
    19:"Thüringen (RK)"
    }

    currentId = ko.observable(null);
    wopiToken = {
        "AccessToken": "",
        "AccessTokenTtl": 0
    }
    currentMimeType = ko.observable(null);

    submitExternalFlagData() {
        if (this.lawFirmAbonnementData().ExternalFlagNumber.length > 2) {
            this.lawFirmAbonnementData().ExternalErrorMessage(ko.toJS('Ungültige Consultant-ID.'));
        } else {
            this.lawFirmAbonnementData().ExternalErrorMessage(ko.toJS(''));
        }
    }

    submitLawFirmSEPAData() {
        this.submitExternalFlagData();
        if (this.lawFirmAbonnementData().ExternalErrorMessage() === ko.toJS("")) {
            if (this.lawFirmAbonnementData().Kreditinstitut() !== ko.toJS("") && this.lawFirmAbonnementData().Kontoinhaber() !== ko.toJS("") && this.lawFirmAbonnementData().IBAN() !== ko.toJS("") && this.lawFirmAbonnementData().BIC() !== ko.toJS("")) {
                this.showSEPAError(false);
                this.saveLawFirmAbonnementData();
            } else {
                this.showSEPAError(true);
            }
        }
    }

    async loadLawFirmAbonnementData() {
        const result = await RNSAPI.getLawFirmAbonnementData();
        if (result.Type === "GetAbonnementDataResult") {
            this.lawFirmAbonnementData(result.Payload.Data);
            if (ko.toJS(this.lawFirmAbonnementData().Produkt) === "Standard") {
                this.lawFirmAbonnementData().StandardVersion = ko.observable<boolean>(true);
                this.enableConsultant(true);
                this.showSEPA(true);
            }
            else {
                let end = moment(result.Payload.Data.TestAboEnd).format("DD.MM.YYYY");
                this.testAboEnd(end);
                if (moment(result.Payload.Data.TestAboEnd).format("YYYYMMDD") < moment(moment.now()).format("YYYYMMDD")) {
                    this.aboAlert(true);
                }
            }

            if (ko.toJS(this.lawFirmAbonnementData().ExternalFlagNumber) !== "0") {
                this.lawFirmAbonnementData().ExternalFlagActive(ko.toJS(true));
                this.enableConsultant(false);
            }
        } else {
            alert("Die SEPA Daten konnten nicht geladen werden!");
        }
    }

    switchProduct() {
        if (ko.toJS(this.lawFirmAbonnementData().Produkt) === "Standard") {
            this.showSEPA(true);
            this.enableConsultant(true);
        }
        else {
            this.showSEPA(false);
            this.enableConsultant(false);
        }
    }

    async saveLawFirmAbonnementData() {
        let res = await RNSAPI.saveLawFirmAbonnementData({
            Kreditinstitut: this.lawFirmAbonnementData().Kreditinstitut,
            Kontoinhaber: this.lawFirmAbonnementData().Kontoinhaber,
            BIC: this.lawFirmAbonnementData().BIC,
            IBAN: this.lawFirmAbonnementData().IBAN,
            ExternalFlagNumber: this.lawFirmAbonnementData().ExternalFlagNumber,
            Produkt: this.lawFirmAbonnementData().Produkt
        });

        $("html, body").animate({ scrollTop: 0 }, "slow");

        if (res.Type === "UpdateAbonnementSuccessful") {
            this.transmittSEPAError(false);
            this.transmittSEPASucce(true);
        }
        else {
            this.transmittSEPASucce(false);
            this.transmittSEPAError(true);
        }
    }

    async uploadAccountImage(file: any) {
        if(file) {
            const fileExtension = file.name.split('.').pop();
            if (['jpg', 'png', 'gif'].indexOf(fileExtension.toLowerCase()) == -1) return this.uploadAccountImageError('<i class="fas fa-exclamation-triangle"></i> Ung&uuml;ltiges Bild-Format.');
            if(file.size <= 1000000) {
                const formData = new FormData();
                formData.append("accountImage", file);
                formData.append("fileExtension", fileExtension);

                await RNSAPI.uploadAccountImage(formData).then((data) => {
                    Postbox.publisher().publish(true, "RefreshLawFirmImage");
                    this.uploadAccountImageError("");
                }).catch((error) => {
                    this.uploadAccountImageError('<i class="fas fa-exclamation-triangle"></i> Fehler beim Hochladen.');
                });
            } else {
                this.uploadAccountImageError('<i class="fas fa-exclamation-triangle"></i> Das Kanzleibild ist zu groß. (Max. 1 MB)');
            }
        }
    }

    providers() {
        let obj = {
            name: ko.observable(""),
            in: ko.observableArray([]),
            out: ko.observable(""),
            imapPort: ko.observable(""),
            pop3Port: ko.observable(""),
            smtp: ko.observableArray([]),
            encryption: ko.observableArray([])
        };
        this.mailAppend("@gmail.com")
        return ko.observable(obj);
    }

    provider = this.providers();

    selectProtocol() {
        if (ko.toJS(this.IN_Proto()) == "POP3") {
            this.inbox().IN_ProtokollType(0);
            this.inbox().IN_Port(ko.toJS(this.provider().pop3Port()));
            if (ko.toJS(this.provider().name) == "Domainfactory") {
                this.inbox().IN_Host(ko.toJS(this.provider().in()[0].toString()));
            } else {
                this.inbox().IN_Host(ko.toJS(this.provider().in()[1]));
            }
        } else {
            this.inbox().IN_ProtokollType(1);
            this.inbox().IN_Port(ko.toJS(this.provider().imapPort()));
            this.inbox().IN_Host(ko.toJS(this.provider().in()[0]));
        }

    }

    async cityFilter() {
        let result = await RNSAPI.getPostcode(this.lawFirmData().PostCode());
        if (result.Type == "GetPostcodeResult" && result.Payload.Cities.length > 0) {
            this.lawFirmData().Place = result.Payload.Cities[0];
            this.cityName(result.Payload.Cities[0]);
        }
        else {
            this.cityName("");
        }
    }

    selectOption() {
        this.newMailUser(false);
        this.mailModalTitle(ko.toJS(this.provider().name));
        if (ko.toJS(this.provider().name) == "Domainfactory") {
            this.provider().in(["sslin.df.eu"]);
            this.provider().out("sslout.df.eu");
            this.provider().imapPort("993");
            this.provider().pop3Port("995");
            this.provider().smtp(["465"]);
            this.provider().encryption(["SSL", "TLS"]);
            this.mailAppend("@df.eu");
        }
        else if (ko.toJS(this.provider().name) == "GMail") {
            this.provider().in(["imap.gmail.com", "pop.gmail.com",]);
            this.provider().out("smtp.gmail.com");
            this.provider().imapPort("993");
            this.provider().pop3Port("995");
            this.provider().smtp(["587", "465"]);
            this.provider().encryption(["SSL", "TLS"]);
            this.mailAppend("@gmail.com");
        }
        else if (ko.toJS(this.provider().name) == "Yahoo") {
            this.provider().in(["imap.mail.yahoo.com", "pop.mail.yahoo.com"]);
            this.provider().out("imap.mail.yahoo.com ");
            this.provider().imapPort("993");
            this.provider().pop3Port("995");
            this.provider().smtp(["465"]);
            this.provider().encryption(["SSL", "TLS"]);
            this.mailAppend("@yahoo.com ");
        }
        else if (ko.toJS(this.provider().name) == "AOL") {
            this.provider().in(["imap.de.aol.com", "pop.aol.com"]);
            this.provider().out("smtp.de.aol.com");
            this.provider().imapPort("993");
            this.provider().pop3Port("995");
            this.provider().smtp(["465"]);
            this.provider().encryption(["SSL", "TLS"]);
            this.mailAppend("@df.eu");
        }
        else if (ko.toJS(this.provider().name) == "Web") {
            this.provider().in(["imap.web.de", "pop3.web.de"]);
            this.provider().out("smtp.web.de");
            this.provider().imapPort("993");
            this.provider().pop3Port("995");
            this.provider().smtp(["465"]);
            this.provider().encryption(["SSL", "TLS"]);
            this.mailAppend("@web.de");
        }
        else if (ko.toJS(this.provider().name) == "GMX") {
            this.provider().in(["imap.gmx.net", "pop.gmx.net"]);
            this.provider().out("mail.gmx.net");
            this.provider().imapPort("993");
            this.provider().pop3Port("995");
            this.provider().smtp(["587"]);
            this.provider().encryption(["SSL", "TLS"]);
            this.mailAppend("@gmx.net");
        }
        else if (ko.toJS(this.provider().name) == "T-Online") {
            this.provider().in(["secureimap.t-online.de", "securepop.t-online.de "]);
            this.provider().out("securesmtp.t-online.de");
            this.provider().imapPort("993");
            this.provider().pop3Port("995");
            this.provider().smtp(["465"]);
            this.provider().encryption(["SSL", "TLS"]);
            this.mailAppend("@t-online.de");
        }
        else if (ko.toJS(this.provider().name) == "Me") {
            this.provider().in(["imap.mail.me.com"]);
            this.provider().out("smtp.mail.me.com");
            this.provider().imapPort("993");
            this.provider().pop3Port("995");
            this.provider().smtp(["587"]);
            this.provider().encryption(["SSL", "TLS"]);
            this.mailAppend("@me.com");
        }
        else if (ko.toJS(this.provider().name) == "Andere") {
            this.newMailUser(true);
            this.mailAppend("");
        }
        this.inbox().OUT_Host(ko.toJS(this.provider().out()))
        this.inbox().IN_ProtokollType(0);
        this.inbox().IN_Port(ko.toJS(this.provider().pop3Port()));
        if (ko.toJS(this.newMailUser())) {
            this.mailModalTitle("Neuer Account");
        }
    }

    providerAccounts() {
        let account = {
            uname: ko.observable(""),
            email: ko.observable(""),
            password: ko.observable("")
        };
        return ko.observable(account);
    }

    accounts = this.providerAccounts();

    async addFKA() {
        if (ko.toJS(this.editFKA)) {
            let res = (await RNSAPI.updateOutlook(ko.toJS(this.EWSURL), ko.toJS(this.UserLogin), ko.toJS(this.UserPassword), ko.toJS(this.useOAuth_Creation), ko.toJS(this.OutlookAccountID)));
            if (res.Type === "UpdateAccountSuccessful") {
                this.EWSURL("");
                this.UserLogin("");
                this.UserPassword("");
                this.OutlookAccountID("");
                $("#newFKA").modal("hide");
                this.getFKA();
                this.statusModalTitle('<i class="far fa-check-circle  mr-2"></i> Erfolgreich');
                this.statusModalText('Die Adresse wurde erfolgreich bearbeitet.');
                $("#statusModal").modal('show');
            }
            else {
                $("#newFKA").modal("hide");
                this.statusModalTitle('<i class="fas fa-exclamation-circle  mr-2"></i> Fehler');
                this.statusModalText('Die Adresse konnte nicht editiert werden. Bitte prüfen Sie Ihre Eingaben.');
                $("#statusModal").modal('show');
            }
        }
        else {

            let res = (await RNSAPI.addOutlook(ko.toJS(this.EWSURL), ko.toJS(this.UserLogin), ko.toJS(this.UserPassword), ko.toJS(this.useOAuth_Creation)));
            if (res.Type === "AddAccountSuccessful") {
                this.EWSURL("");
                this.UserLogin("");
                this.UserPassword("");
                $("#newFKA").modal("hide");
                this.getFKA();
                this.statusModalTitle('<i class="far fa-check-circle  mr-2"></i> Erfolgreich');
                this.statusModalText('Die Adresse wurde erfolgreich hinzugefügt.');
                $("#statusModal").modal('show');
            }
            else {
                $("#newFKA").modal("hide");
                this.statusModalTitle('<i class="fas fa-exclamation-circle  mr-2"></i> Fehler');
                this.statusModalText('Die Adresse konnte nicht hinterlegt werden. Bitte prüfen Sie Ihre Eingaben.');
                $("#statusModal").modal('show');
            }
        }
    }

    openNewFKAModal() {
        this.editFKA(false);
        this.OutlookAccountID("");
        this.EWSURL("");
        this.UserLogin("");
        this.UserPassword("");
        this.useOAuth_Creation(false);
        $("#newFKA").modal("show");
    }

    openNewInstanceModal() {
        this.createInstanceName("");
        $("#createInstance").modal("show");
    }

    openNewCourtModal(){
        this.Ger_City("");
        this.Ger_Postcode("");
        this.Ger_Name("");
        this.Ger_Keyword("");
        this.Ger_Street("");
        this.Ger_State(0);
        this.Ger_Mail("");
        this.Ger_Phone("");
        $("#createCourt").modal("show");
    }

    openNewStandortModal() {
        this.standLawfirmID("");
        this.standUserID("");
        this.standPassword("");
        $("#newStandort").modal("show");
    }

    async assignNewStandort() {
        let res = (await RNSAPI.assignLocation(ko.toJS(this.standLawfirmID()), ko.toJS(this.standUserID), ko.toJS(this.standPassword)));
        if (res.Type === "AssignmentSuccessful") {
            this.standLawfirmID("");
            this.standUserID("");
            this.standPassword("");
            $("#newStandort").modal("hide");
            this.statusModalTitle('<i class="far fa-check-circle  mr-2"></i> Erfolgreich');
            this.statusModalText('Der Standort wurde erfolgreich hinzugefügt.');
            $("#statusModal").modal('show');
            this.getStandorte();
        }
        else {
            switch (ko.toJS(res.Payload.Reason)) {
                case "0 - Access denied":
                    $("#newStandort").modal("hide");
                    this.statusModalTitle('<i class="fas fa-exclamation-circle  mr-2"></i> Keine Berechtigung');
                    this.statusModalText('Sie sind nicht berechtigt einen Standort zu hinterlgen.');
                    $("#statusModal").modal('show');
                    break;
                case "1 - Lawfirm does not exist":
                    this.standLawfirmID("");
                    break;
                case "2 - Assignment already exists":
                    $("#newStandort").modal("hide");
                    this.statusModalTitle('<i class="fas fa-exclamation-circle  mr-2"></i> Standort vorhanden');
                    this.statusModalText('Sie haben bereits Zugriff auf den eingegebenen Standort.');
                    $("#statusModal").modal('show');
                    break;
                case "3 - UserID or Password are incorrect":
                    this.standUserID("");
                    this.standPassword("");
                    break;
                default:
                    $("#newStandort").modal("hide");
                    this.statusModalTitle('<i class="fas fa-exclamation-circle  mr-2"></i> Fehlerhafte Daten');
                    this.statusModalText('Sie haben fehlerhafte Daten eingegeben.');
                    $("#statusModal").modal('show');
            }
        }
    }

    openNewAccountModal() {
        this.accounts().uname("")
        this.accounts().email("")
        this.accounts().password("")
        this.clearInbox()
        this.clearProvider()
        this.currentView() == 'beA' ? this.isBeaProtocol(true) : this.isBeaProtocol(false);
        this.newMailUser(false);
        this.mailModalTitle(ko.toJS(this.provider().name));
        this.provider().in(["imap.gmail.com", "pop.gmail.com",]);
        this.provider().out("smtp.gmail.com");
        this.provider().imapPort("993");
        this.provider().pop3Port("995");
        this.provider().smtp(["587", "465"]);
        this.provider().encryption(["SSL", "TLS"]);
        this.mailAppend("@gmail.com");
        this.inbox().OUT_Host(ko.toJS(this.provider().out()))
        this.inbox().IN_ProtokollType(0);
        this.inbox().IN_Port(ko.toJS(this.provider().pop3Port()));
        if (ko.toJS(this.newMailUser())) {
            this.mailModalTitle("Neuer Account");
        }
        $(".accounts_auswahl_email").val("GMail")
        $('#accounts').modal('hide');
        $('#accounts').modal('show');
    }

    accountSave() {
        this.inbox().EMail_Address(ko.toJS(this.accounts().email) + this.mailAppend());
        this.inbox().IN_UserLogin(ko.toJS(this.accounts().email) + this.mailAppend());
        this.inbox().IN_UserPassword(ko.toJS(this.accounts().password));
        this.inbox().OUT_UserLogin(ko.toJS(this.accounts().email) + this.mailAppend());
        this.inbox().OUT_UserPassword(ko.toJS(this.accounts().password));
        this.inbox().OUT_Port(ko.toJS(this.provider().smtp()[0]));
        this.inbox().OUT_Host(ko.toJS(this.provider().out));
        this.selectProtocol();
        $("#createMail").modal('show');
        $("#accounts").modal('hide');
    }

    accountSaveBe() {
        this.inbox().EMail_Address(ko.toJS(this.accounts().email) + this.mailAppend());
        this.inbox().IN_UserLogin(ko.toJS(this.accounts().email) + this.mailAppend());
        this.inbox().IN_UserPassword(ko.toJS(this.accounts().password));
        this.inbox().OUT_UserLogin(ko.toJS(this.accounts().email) + this.mailAppend());
        this.inbox().OUT_UserPassword(ko.toJS(this.accounts().password));
        this.inbox().OUT_Port(ko.toJS(this.provider().smtp()[0]));
        this.inbox().OUT_Host(ko.toJS(this.provider().out));
        this.selectProtocol();
        $("#createMail").modal('show');
        $("#createBea").modal('hide');
    }

    newCreateUser() {
        let obj = {
            Anrede: ko.observable(null),
            Vorname: ko.observable(null),
            Nachname: ko.observable(null),
            Telefon: ko.observable("+49"),
            EMail: ko.observable(null),
            Rolle: ko.observable(null),
            Benutzer: ko.observable(null),
            Passwort: ko.observable(null),
            PasswortRetry: ko.observable(null),
            Recherche_EMail: ko.observable(""),
            Recherche_Passwort: ko.observable(""),
            Recherche_PasswortRetry: ko.observable(""),
            isAdmin: ko.observable(false),
            externalSWRights: ko.observable(0)
        };

        return ko.observable(obj);
    };

    createUser = this.newCreateUser();

    newDeadlineReasons() {
        let obj = {
            DeadlineDescription: ko.observable(""),
            DeadlineDuration: ko.observable("2"),
            DeadlineNumber: ko.observable(""),
            DeadlineType: ko.observable(""),
            PreDeadlineDuration: ko.observable("")

        };

        return ko.observable(obj);
    };

    deadlineReason = this.newDeadlineReasons();

    newRachtsform() {
        let obj = {
            Anrede: ko.observable(""),
            Briefanrede: ko.observable(""),
            NameID: ko.observable(""),
            AnredeForm: ko.observable(""),
            RechtsformType: ko.observable("")
        };

        return ko.observable(obj);
    };

    rachtsform = this.newRachtsform();

    newResubmissionReasons() {
        let obj = {
            deadLineName: ko.observable(""),
            deadLineElapseTime: ko.observable("2"),
            deadLineNr: ko.observable(""),
            deadLineArt: ko.observable(""),
            editResubmission: ko.observable(false)
        };

        return ko.observable(obj);
    };

    resubmission = this.newResubmissionReasons();

    newRefrate() {
        let obj = {
            referatName: ko.observable(""),
            referatId: ko.observable("")
        };

        return ko.observable(obj);
    };

    referat = this.newRefrate();

    EmailAdds = ko.observableArray([{
        Index: 0,
        beApost: ko.observable(''),
    }]);

    addEmailAdd() {
        this.EmailAdds.push({
            Index: this.EmailAdds().length,
            beApost: ko.observable(''),
        });
    }

    removeEmailAdd = (data) => {
        if (data.Index < 1) return;
        this.EmailAdds.remove((item) => item.Index === data.Index);
    }

    newEditUser() {
        let obj = {
            Anrede: ko.observable(null),
            Vorname: ko.observable(null),
            Nachname: ko.observable(null),
            Telefon: ko.observable('+49'),
            EMail: ko.observable(null),
            Rolle: ko.observable(""),
            Benutzer: ko.observable(null),
            Passwort: ko.observable(""),
            PasswortRetry: ko.observable(""),
            Recherche_EMail: ko.observable(""),
            Recherche_Passwort: ko.observable(""),
            Recherche_PasswortRetry: ko.observable(""),
            isAdmin: ko.observable(false),
            externalSWRights: ko.observable(0)
        };

        return ko.observable(obj);
    };

    editUser = this.newEditUser();

    proto = ko.observableArray(["POP3", "IMAP"]);

    protoval = ko.observableArray([0, 1]);

    protocols = ["POP3", "IMAP"];

    isBeaProtocol = ko.observable(false)

    newBasicInbox() {
        let obj = {
            "EMailAccount_ID": ko.observable(""),
            "EMail_Address": ko.observable(""),
            "IN_ProtokollType": ko.observable(1),
            "IN_UserLogin": ko.observable(""),
            "IN_UserPassword": ko.observable(""),
            "IN_Host": ko.observable(""),
            "IN_Port": ko.observable("993"),
            "IN_ImapFolder": ko.observable("Inbox"),
            "IN_Authentification": ko.observable(1),
            "OUT_ProtokollType": ko.observable(0),
            "OUT_UserLogin": ko.observable(""),
            "OUT_UserPassword": ko.observable(""),
            "OUT_Host": ko.observable(""),
            "OUT_Port": ko.observable(465),
            "OUT_Authentification": ko.observable(0),
            "checkCertificate": ko.observable(true),
            "DeleteAfterDownload": ko.observable(false),
            "UnseenOnly": ko.observable(false),
            "GrabMails": ko.observable(true),
            "IsAutoInsertAddress": ko.observable(true)
        };

        return ko.observable(obj);
    };

    clearInbox() {
        this.inbox().EMailAccount_ID("");
        this.inbox().EMail_Address("");
        this.inbox().IN_ProtokollType(1);
        this.inbox().IN_UserLogin("");
        this.inbox().IN_UserPassword("");
        this.inbox().IN_Host("");
        this.inbox().IN_Port("993");
        this.inbox().IN_ImapFolder("Inbox");
        this.inbox().IN_Authentification(1);
        this.inbox().OUT_ProtokollType(0);
        this.inbox().OUT_UserLogin("");
        this.inbox().OUT_UserPassword("");
        this.inbox().OUT_Host("");
        this.inbox().OUT_Port(465);
        this.inbox().OUT_Authentification(0);
        this.inbox().checkCertificate(true);
        this.inbox().DeleteAfterDownload(false);
        this.inbox().UnseenOnly(false);
        this.inbox().GrabMails(true);
        this.inbox().IsAutoInsertAddress(true);
    }

    clearProvider() {
        this.provider().name('')
        this.provider().in([])
        this.provider().out('')
        this.provider().imapPort('')
        this.provider().pop3Port('')
        this.provider().smtp([])
        this.provider().encryption([])
    }

    IsEditMode = ko.observable(false);

    inbox = this.newBasicInbox()

    currentView: ko.Observable<String>;
    public showUsers() {
        this.statusVisible(false);
        if (ko.toJS(this.bl())) {
            MainViewModel.RoutingTable.showSettingsView({ view: "users" }, true);
            this.currentView('users');
        }
    }

    public wiedervorlagegruende() {
        MainViewModel.RoutingTable.showSettingsView({ view: "wiedervorlagegruende" }, true);
        this.currentView('wiedervorlagegruende');
    }

    public frist() {
        MainViewModel.RoutingTable.showSettingsView({ view: "fristgruende" }, true);
        this.currentView('fristgruende');
    }

    public fristverlaengerungsgrund() {
        MainViewModel.RoutingTable.showSettingsView({ view: "fristverlaengerungsgruende" }, true);
        this.currentView('fristverlaengerungsgruende');
    }

    public showZPEMail() {
        MainViewModel.RoutingTable.showSettingsView({ view: "zpemail" }, true);
        this.currentView('zpemail');
        this.filterType('mail')
    }

    public showAbrechnung() {
        MainViewModel.RoutingTable.showSettingsView({ view: "abrechnung" }, true);
        this.currentView('abrechnung');
    }

    public showStandorte() {
        MainViewModel.RoutingTable.showSettingsView({ view: "standorte" }, true);
        this.currentView('standorte');
    }

    public showKonto() {
        MainViewModel.RoutingTable.showSettingsView({ view: "konto" }, true);
        this.currentView('konto');
    }

    public showRachts() {
        MainViewModel.RoutingTable.showSettingsView({ view: "rechtsform" }, true);
        this.currentView('rechtsform');
    }

    public showRefrate() {
        MainViewModel.RoutingTable.showSettingsView({ view: "refrate" }, true);
        this.currentView('refrate');
    }

    public showProfile(e) {
        this.statusVisible(false);
        MainViewModel.RoutingTable.showSettingsView({ view: "profile" }, true);
        this.currentView('profile');
    }

    public showPost() {
        MainViewModel.RoutingTable.showSettingsView({ view: "beA" }, true);
        this.currentView('beA');
        this.filterType('bea')
    }

    public showFKA() {
        MainViewModel.RoutingTable.showSettingsView({ view: "fristen-kalender-adresse" }, true);
        this.currentView('fristen-kalender-adresse');
        this.filterType('fristen-kalender-adresse')
    }

    public showInstances() {
        MainViewModel.RoutingTable.showSettingsView({ view: "instances" }, true);
        this.currentView('instances');
        this.filterType('instances')
    }

    public showCourts() {
        MainViewModel.RoutingTable.showSettingsView({ view: "courts" }, true);
        this.currentView('courts');
        this.filterType('courts')
    }    

    cancel() {
        this.error("");
    }

    async deleteData() {
        $("#deleteConfirm").modal('hide');
        try {
            if (ko.toJS(this.temp) == "resubmission") {
                await RNSAPI.deleteWeider(this.deleteDataValue.toString());
                this.updateWeider();
            }
            else if (ko.toJS(this.temp) == "deadline") {
                await RNSAPI.deleteFrist(this.deleteDataValue.toString());
                this.updateFrist();
            }
            else if (ko.toJS(this.temp) == "referat") {
                await RNSAPI.deleteReferat(this.deleteDataValue.toString());
                this.updateReferat();
            }
            else if (ko.toJS(this.temp) == "deleteUser") {
                let res = await RNSAPI.deleteLawFirmUser(this.deleteDataValue.toString());
                if (res.Type == "DeleteUserUnSuccessfullStillJoined") {
                    this.assignedUserCases(ko.toJS(res.Payload.assignedCases));
                    $("#userAssigned").modal("show");
                }
                this.updateUsers();
            }
            else {
                await RNSAPI.deleteRechtsformen(this.deleteDataValue.toString());
                this.updateLegalForms();
            }
        }
        catch (error) {
            alert("Beim Löschen ist ein Fehler aufgetreten!");
        }
    }

    async navigateToCase(data) {
        window.open(`/#/new/${encodeURIComponent(data)}`)
    }

    newFrist() {
        this.deadlineReason().DeadlineDescription("");
        this.deadlineReason().DeadlineDuration("2");
        this.deadlineReason().DeadlineNumber("");
        this.deadlineReason().DeadlineType("");
        this.fristModalTitle("FRISTGRUND ANLEGEN");
        this.fristStatus(true);
        this.createDeadlineReason(true);
        $("#fristModal").modal('show');
    }

    newFristExtenstionReason() {
        this.DeadlineExtensionReason('');
        $("#extendFristReasonModal").modal('show');
    }

    newLegalform() {
        this.rachtsform().Anrede("");
        this.rachtsform().Briefanrede("");
        this.rachtsform().NameID("");
        this.rachtsform().AnredeForm("");
        this.rachtsform().RechtsformType("");
        this.rachtsModalTitle("NEUE RECHTSFORM");
        $("#newRachtsform").modal('show');
    }

    resub() {
        this.resubmission().deadLineName("");
        this.resubmission().deadLineElapseTime("2");
        this.resubmission().deadLineNr("");
        this.resubmission().deadLineArt("");
        this.resubmission().editResubmission(false);
        this.weiderModalTitle("NEUER WIEDERVORLAGENGRUND");
        $("#weider").modal('show');
    }

    newreferat() {
        this.referat().referatName("");
        this.referat().referatId("");
        this.referatModalTitle("NEUR STANDORT");
        (<HTMLInputElement>document.getElementById("referatId")).disabled = false;
        $("#newRefrate").modal('show');
    }

    async updateWeider() {
        let reasons = await RNSAPI.getResubmissionReasons();
        this.reasons(reasons.Payload.Reasons.sort((a, b) => a.DeadLineName.trim().toLowerCase().localeCompare(b.DeadLineName.trim().toLowerCase())).map((c) => {
            for (let duration of this.durations) {
                if (c.DeadLineElapseTime && c.DeadLineElapseTime.startsWith(duration)) {
                    const item = c.DeadLineElapseTime.split(duration).join('');
                    const index = this.durations.indexOf(duration);
                    c.DeadLineElapseTimeDisplay = item + " " + (Number(item) > 1 ? this.durationsPlural[index] : this.durationsSinglar[index]);
                }
                else {
                    const item = c.DeadLineElapseTime.split(duration).join('');
                    if (Number(item) >= 1)
                        c.DeadLineElapseTimeDisplay = item + " " + (Number(item) > 1 ? "Wochen" : "Woche");
                }
            }

            c.actionHandlers = [{
                icon: "paste",
                name: "Kopieren",
                action: async () => {
                    this.resubmission().deadLineName(c.DeadLineName);
                    for (let item of this.durations) {
                        if (c.DeadLineElapseTime.startsWith(item)) {
                            console.log(item);
                            this.expirationTime(item);
                            this.resubmission().deadLineElapseTime(c.DeadLineElapseTime.split(item).join(''));
                            console.log(this.resubmission().deadLineElapseTime());
                        }
                    }
                    this.resubmission().deadLineNr(c.DeadLineNr);
                    this.resubmission().deadLineArt(c.DeadLineArt);
                    this.weiderStatus(true);
                    this.weiderModalTitle("WIEDERVORLAGENRUND \"" + c.DeadLineNr + "\" KOPIEREN");
                    $("#weider").modal('show');
                }
            }, {
                icon: "pencil-alt",
                name: "Bearbeiten",
                action: async () => {
                    this.resubmission().deadLineName(c.DeadLineName);
                    this.resubmission().deadLineElapseTime(c.DeadLineElapseTime);
                    this.resubmission().deadLineNr(c.DeadLineNr);
                    this.resubmission().deadLineArt(c.DeadLineArt);
                    for (let item of this.durations) {
                        if (c.DeadLineElapseTime.startsWith(item)) {
                            console.log(c.DeadLineElapseTime);
                            this.expirationTime(item)
                            this.resubmission().deadLineElapseTime(c.DeadLineElapseTime.split(item).join(''));
                        }
                    }
                    this.weiderStatus(false);
                    this.weiderModalTitle("WIEDERVORLAGENRUND \"" + c.DeadLineNr + "\" ÄNDERN");
                    this.resubmission().editResubmission(true);
                    $("#weider").modal('show');
                }
            },
            {
                icon: "trash-alt", name: "Löschen", action: async () => {
                    this.deleteDataValue = ko.toJS(c.DeadLineNr);
                    this.temp("resubmission");
                    this.deleteModalTitle("WIEDERVORLAGENRUND LÖSCHEN");
                    this.deleteModalText("Möchten Sie den Wiedervorlagengrund \"" + c.DeadLineNr + "\" wirklich löschen?");
                    this.confirmDeletion();
                }
            }];
            return c;
        }));
    }

    confirmDeletion() {
        $("#deleteConfirm").modal('show');
    }

    async updateFrist() {
        let fristReasons = await RNSAPI.getDeadlineReasons();
        let duration_names = { s: { d: 'Tag', w: 'Woche', m: 'Monat', y: 'Jahr' }, p: { d: 'Tage', w: 'Wochen', m: 'Monate', y: 'Jahre' } }
        this.fristReasons(fristReasons.Payload.Reasons.map((c) => {
            let oldDuration = ko.toJS(c.DeadlineDuration).slice(1);
            if (oldDuration.startsWith("0"))
                oldDuration = oldDuration.slice(1);
            if (/^\w\d+$/.test(c.DeadlineDuration)) {
                let duration = Number(c.DeadlineDuration.slice(1));
                let dtype = c.DeadlineDuration[0];
                c.DeadlineDuration = duration + ' ' + duration_names[duration == 1 ? 's' : 'p'][dtype];
            }
            if (ko.toJS(c.PreDeadlineDuration) !== '') {
                try {
                    let days = parseInt(c.PreDeadlineDuration)
                    if (days > 1)
                        c.PreDeadlineDuration = c.PreDeadlineDuration + " Tage";
                    else
                        c.PreDeadlineDuration = c.PreDeadlineDuration + " Tag";
                } catch{ }
            }

            c.actionHandlers = [{
                icon: "paste",
                name: "Kopieren",
                action: async () => {
                    this.deadlineReason().DeadlineDescription(c.DeadlineDescription);
                    this.deadlineReason().DeadlineDuration(oldDuration);
                    this.deadlineReason().DeadlineNumber(c.DeadlineNumber);
                    this.deadlineReason().DeadlineType(c.DeadlineType);
                    this.deadlineReason().PreDeadlineDuration(c.PreDeadlineDuration.split(' ')[0])
                    this.fristStatus(true);
                    this.fristModalTitle("FRISTGRUND \"" + c.DeadlineNumber + "\" KOPIEREN");
                    this.createDeadlineReason(true);
                    $("#fristModal").modal('show');
                }
            },
            {
                icon: "pencil-alt",
                name: "Bearbeiten",
                action: async () => {
                    this.deadlineReason().DeadlineDescription(c.DeadlineDescription);
                    this.deadlineReason().DeadlineDuration(oldDuration);
                    this.deadlineReason().DeadlineNumber(c.DeadlineNumber);
                    this.deadlineReason().DeadlineType(c.DeadlineType);
                    this.deadlineReason().PreDeadlineDuration(c.PreDeadlineDuration.split(' ')[0])
                    this.fristModalTitle("FRISTGRUND \"" + c.DeadlineNumber + "\" ÄNDERN");
                    this.fristStatus(false);
                    this.createDeadlineReason(false);
                    $("#fristModal").modal('show');
                }
            },
            {
                icon: "trash-alt", name: "Löschen", action: async () => {
                    this.temp("deadline");
                    this.deleteDataValue = ko.toJS(c.DeadlineNumber);
                    this.deleteModalTitle("FRISTGRUND \"" + c.DeadlineNumber + "\" LÖSCHEN ");
                    this.confirmDeletion();
                }
            }
            ];
            return c;
        }));
    }

    async updateReferat() {
        let units = await RNSAPI.getReferate();
        this.units(units.Payload.Units.sort((a, b) => a.Name.trim().toLowerCase().localeCompare(b.Name.trim().toLowerCase())).map((c) => {
            c.actionHandlers = [
                {
                    icon: "paste", name: "Kopieren", action: async () => {
                        this.referat().referatName(c.Name);
                        this.referat().referatId(c.Id);
                        this.referateStatus(true);
                        this.referatModalTitle("REFERAT \"" + c.Id + "\" KOPIEREN");
                        (<HTMLInputElement>document.getElementById("referatId")).disabled = false;
                        $("#newRefrate").modal('show');
                    }
                },
                {
                    icon: "pencil-alt",
                    name: "Bearbeiten",
                    action: async () => {
                        this.referat().referatName(c.Name);
                        this.referat().referatId(c.Id);
                        this.referateStatus(false);
                        this.referatModalTitle("REFERAT \"" + c.Id + "\" ÄNDERN");
                        (<HTMLInputElement>document.getElementById("referatId")).disabled = true;
                        $("#newRefrate").modal('show');
                    }
                },
                {
                    icon: "trash-alt", name: "Löschen", action: async () => {
                        $("#confirm").modal('show');
                        this.temp("referat");
                        this.deleteDataValue = ko.toJS(c.Id);
                        this.deleteModalTitle("REFERAT LÖSCHEN");
                        this.deleteModalText("Möchten Sie das Referat " + c.Id + " \"" + c.Name + "\" wirklich löschen?");
                        this.confirmDeletion();
                    }
                }
            ];
            return c;
        }));
    }

    async updateLegalForms() {
        let legalforms = await RNSAPI.getRechtsformen();
        this.legalforms(legalforms.Payload.LegalForms.map((c) => {
            c.actionHandlers = [{
                icon: "paste", name: "Kopieren", action: async () => {
                    this.rachtsform().NameID(c.NameID);
                    this.rachtsform().Briefanrede(c.Briefanrede);
                    this.rachtsform().Anrede(c.Anrede);
                    this.rachtsform().RechtsformType(c.RechtsformType);
                    this.rachtsform().AnredeForm(c.AnredeForm);
                    this.rachtsStatus(true);
                    this.rachtsModalTitle("RECHTSFORM \"" + c.NameID + "\" KOPIEREN");
                    $("#newRachtsform").modal('show');
                }
            },
            {
                icon: "pencil-alt",
                name: "Bearbeiten",
                action: async () => {
                    this.rachtsform().NameID(c.NameID);
                    this.rachtsform().Briefanrede(c.Briefanrede);
                    this.rachtsform().Anrede(c.Anrede);
                    this.rachtsform().RechtsformType(c.RechtsformType);
                    this.rachtsform().AnredeForm(c.AnredeForm);
                    this.rachtsStatus(false);
                    this.rachtsModalTitle("RECHTSFORM \"" + c.NameID + "\" ANLEGEN");
                    $("#newRachtsform").modal('show');
                }
            },
            {
                icon: "trash-alt", name: "Löschen", action: async () => {
                    this.deleteDataValue = ko.toJS(c.NameID);
                    this.deleteModalTitle("RECHTSFORM \"" + c.NameID + "\" LÖSCHEN");
                    this.confirmDeletion();

                }
            }];
            return c;
        }));
    }

    async saveReferat() {
        this.error("");
        if (this.referat().referatName() !== "" && this.referat().referatId() !== "") {
            if (this.referat().referatId().length < 2) {
                this.error("Beim Kürzel können nur 2 Ziffern eingegeben werden.");
                return;
            }
            if (this.referat().referatName().length > 30) {
                this.error(" Der Referatsname darf nicht länger als 30 Zeichen sein.");
                return;
            }
            try {
                if (this.referateStatus() == true) {
                    let result = await RNSAPI.addReferat(this.referat().referatId(), this.referat().referatName());
                    this.updateReferat();
                    if (result !== null) {
                        $('#newRefrate').modal('hide');
                    }
                } else {
                    let result = await RNSAPI.updateReferat(this.referat().referatId(), this.referat().referatName());
                    this.updateReferat();
                    if (result !== null) {
                        $('#newRefrate').modal('hide');
                    }
                }
            } catch (e) {
                this.referatResult("<i class='fas fa-exclamation-circle '></i> Das Referat konnte nicht hinzugef&uuml;gt werden.");
            }
        } else {
            this.error("Bitte füllen Sie die Pflichtfelder aus!");
            this.referatResult("<i class='fas fa-exclamation-circle '></i> Es wurden nicht alle Felder ausgef&uuml;llt.");
        }
    }

    async saveWeider() {
        let elapseTime = ""
        if (ko.toJS(this.resubmission().deadLineElapseTime) == "") {
            elapseTime = "0"
        } else {
            elapseTime =  this.expirationTime() + this.resubmission().deadLineElapseTime();
        }
        if (ko.toJS(this.resubmission().deadLineName) == "" || ko.toJS(this.resubmission().deadLineNr) == "") {
            this.error("Bitte füllen Sie die Pflichtfelder aus!");
        }
        else {
            try {
                const request = ko.toJS(this.resubmission())
                if (this.weiderStatus() == true) {
                    request.deadLineElapseTime = elapseTime
                    let result = await RNSAPI.addWeider(request);
                    this.updateWeider();
                    if (result !== null) {
                        $('#weider').modal('hide');
                    }
                } else {
                    request.deadLineElapseTime = elapseTime
                    let result = await RNSAPI.updateWeider(request);
                    this.updateWeider();
                    if (result !== null) {
                        $('#weider').modal('hide');
                    }
                }
            } catch (e) {
                alert("Die Wiedervorlage konnte nicht gespeichert werden.")
            }
        }
    }

    async saveFristExtensionReason() {
        let res = (await RNSAPI.insFristDeadlineReasons(ko.toJS(this.DeadlineExtensionReason)));
        if (res.Type === "InsertFristExtensionReasonsSuccessful") {
            this.getFristDeadlineReasons();
            $("#extendFristReasonModal").modal("hide")
        }
        else {
            this.statusModalTitle('<i class="fas fa-exclamation-circle  mr-2"></i> Fehler beim Hinzufügen');
            this.statusModalText('Der Verlängerungsgrund konnte nicht gespeichert werden.');
            $("#statusModal").modal('show');
        }
    }

    async saveFrist() {
        if (ko.toJS(this.deadlineReason().DeadlineNumber) == "" || ko.toJS(this.deadlineReason().DeadlineDescription) == "") {
            this.error("Bitte füllen Sie die Pflichtfelder aus!");
        } else {
            if (parseInt(ko.toJS(this.deadlineReason().DeadlineDuration)) < 10) {

                this.deadlineReason().DeadlineDuration("0" + ko.toJS(this.deadlineReason().DeadlineDuration))
            }

            this.deadlineReason().DeadlineDuration(ko.toJS(this.expirationTimeF) + ko.toJS(this.deadlineReason().DeadlineDuration));

            try {
                if (this.fristStatus() == true) {
                    let result = await RNSAPI.addFrist(ko.toJS(this.deadlineReason()));
                    this.updateFrist();
                    if (result !== null) {
                        $('#fristModal').modal('hide');
                    }
                } else {
                    let result = await RNSAPI.updateFrist(ko.toJS(this.deadlineReason()));
                    this.updateFrist();
                    if (result !== null) {
                        $('#fristModal').modal('hide');
                    }
                }
            } catch (e) {
                this.referatResult("<i class='fas fa-exclamation-circle '></i> Das Referat konnte nicht hinzugef&uuml;gt werden.");
            }
        }
    }

    async saveRachts() {
        if (ko.toJS(this.rachtsform().NameID) == "") {
            this.error("Bitte füllen Sie die Pflichtfelder aus!");
        } else {
            try {
                if (this.rachtsStatus() == true) {
                    let result = await RNSAPI.addRechtsformen(ko.toJS(this.rachtsform()));
                    if (result !== null) {
                        this.updateLegalForms();
                        $('#newRachtsform').modal('hide');
                    }
                    else {
                        this.referatResult("<i class='fas fa-exclamation-circle '></i> Das Referat konnte nicht hinzugef&uuml;gt werden.");
                    }
                } else {
                    let result = await RNSAPI.updateRechtsformen(ko.toJS(this.rachtsform()));
                    if (result !== null) {
                        this.updateLegalForms();
                        $('#newRachtsform').modal('hide');
                    }
                }
            } catch (e) {
                this.referatResult("<i class='fas fa-exclamation-circle '></i> Das Referat konnte nicht hinzugef&uuml;gt werden.");
            }
        }
    }

    async updateMail() {
        this.inbox().EMail_Address("");
        this.inbox().IN_ProtokollType(0);
        this.inbox().IN_UserLogin("");
        this.inbox().IN_UserPassword("");
        this.inbox().IN_Host("");
        this.inbox().IN_Port("993");
        this.inbox().OUT_UserLogin("");
        this.inbox().OUT_UserPassword("");
        this.inbox().OUT_Host("");
        this.inbox().OUT_Port(465);
        this.IsEditMode(false);
        $("#createMail").modal('show');
    }

    changedRoll() {
        if (ko.toJS(this.createUser().Rolle) === "Administrator") {
            (<HTMLInputElement>document.getElementById("addAdminRights")).checked = true;
            (<HTMLInputElement>document.getElementById("addAdminRights")).disabled = true;
        }
        else {
            (<HTMLInputElement>document.getElementById("addAdminRights")).checked = false;
            (<HTMLInputElement>document.getElementById("addAdminRights")).disabled = false;
        }

        if (ko.toJS(this.editUser().Rolle) === "Administrator") {
            (<HTMLInputElement>document.getElementById("editAdminRights")).checked = true;
            (<HTMLInputElement>document.getElementById("editAdminRights")).disabled = true;
        }
        else {
            (<HTMLInputElement>document.getElementById("editAdminRights")).checked = false;
            (<HTMLInputElement>document.getElementById("editAdminRights")).disabled = false;
        }
    }

    async updateData() {

        this.editUser().Benutzer(this.currentUserSymbol());
        var height = $(window).scrollTop();
        if (!Utils.checkErrors(["Anrede", "Vorname", "Nachname", "EMail", "Benutzer", "Rolle"], this.editUser(), "Edit", [Utils.checkString])) {
            this.statusText('Es wurden nicht alle erforderlichen Daten eingegeben.');
            this.statusVisible(true);
            return;
        }

        if (!Utils.checkErrors(["EMail"], this.editUser(), "Edit", [Utils.checkEmail])) {
            this.statusText('Ung&uuml;ltiges E-Mail Adressen Format.');
            this.statusVisible(true);
            return;
        }

        if (this.editUser().Passwort() !== null && this.editUser().PasswortRetry() !== null) {
            if (this.editUser().Passwort() !== this.editUser().PasswortRetry()) {
                Utils.checkErrors(["Passwort", "PasswortRetry"], this.createUser(), "New", [Utils.checkString])
                this.statusText('Die neu eingegebenen Passw&ouml;rter stimmen nicht &uuml;berein.');
                this.statusVisible(true);
                return;
            }
        }
        if (ko.toJS(this.editUser().Passwort()) === ko.toJS(this.editUser().PasswortRetry()) && /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/.test(ko.toJS(this.editUser().Passwort()).toString())) {

        }
        else {
            this.statusText('Bitte mindestens 8 Zeichen und eine Kombination aus Kleinbuchstaben, Großbuchstaben, Ziffern und Sonderzeichen verwenden..');
            this.statusVisible(true);
            return;
        }
        const editUser = ko.toJS(this.editUser())

        if (this.editUser().Rolle() === "Anwalt") {
            editUser.Rolle = '0';
        }
        else if (this.editUser().Rolle() === "Administrator")
            editUser.Rolle = '2'
        else if (this.editUser().Rolle() === "PA / Sekretariat")
            editUser.Rolle = '3'
        else {
            editUser.Rolle = '1';
        }

        this.editUser().isAdmin((<HTMLInputElement>document.getElementById("editAdminRights")).checked);

        let result = await RNSAPI.updateLawFirmUser(editUser);
        if (result.Type === "UpdateUserSuccessful") {
            $("#editUser").modal('hide');
            this.updateUsers();
            this.statusModalTitle('<i class="far fa-check-circle  mr-2"></i> Erfolgreich');
            this.statusModalText('Der Benutzer <span class="text-default">' + this.editUser().Benutzer() + '</span> wurde erfolgreich bearbeitet.');
            $("#statusModal").modal('show');

        } else {
            this.statusText('Es ist ein Fehler aufgetreten.<br />Versuchen Sie es sp&auml;ter erneut oder kontaktieren Sie unseren Support unter info@rainmaker.de');
            this.statusVisible(true);
        }
    }

    async updateUsers() {
        let users = await RNSAPI.getLawFirmUsers();
        this.users(users.Payload.Users.map((c) => {
            c.actionHandlers = [
                {
                    icon: "pencil-alt", name: "Bearbeiten", action: async () => {
                        let data = await RNSAPI.getLawFirmUser(c.Shorthandsymbol);
                        if (data.Type === "GetUserSuccessful") {

                            this.editUser().Anrede(data.Payload.User.Anrede);

                            this.editUser().Vorname(data.Payload.User.Vorname);
                            this.editUser().Nachname(data.Payload.User.Nachname);
                            this.editUser().Telefon(data.Payload.User.Telefon);
                            this.editUser().EMail(data.Payload.User.EMail);
                            this.editUser().Benutzer(data.Payload.User.Benutzer);
                            this.editUser().externalSWRights(data.Payload.User.externalSWRights);
                            this.currentUserSymbol(data.Payload.User.Benutzer);

                            if (data.Payload.User.RollenName.includes("Admin")) {
                                (<HTMLInputElement>document.getElementById("editAdminRights")).checked = true;
                            }
                            else {
                                (<HTMLInputElement>document.getElementById("editAdminRights")).checked = false;
                            }

                            if (data.Payload.User.Rolle == '1') {
                                this.editUser().Rolle("ReFa");
                            }
                            else if (data.Payload.User.Rolle == '2') {
                                this.editUser().Rolle("Administrator");
                            }
                            else if (data.Payload.User.Rolle == '3') {
                                this.editUser().Rolle("PA / Sekretariat");
                            }
                            else {
                                this.editUser().Rolle("Anwalt");
                            }

                            this.editUser().Recherche_EMail(data.Payload.User.Recherche_EMail);
                            this.editUser().Recherche_Passwort(data.Payload.User.Recherche_Passwort);
                            this.editUser().Recherche_PasswortRetry(data.Payload.User.Recherche_PasswortRetry);
                            if (data.Payload.User.Recherche_EMail == null) {
                                this.editUser().Recherche_EMail("");
                            }
                            if (data.Payload.User.Recherche_Passwort == null) {
                                this.editUser().Recherche_Passwort("");
                            }
                            if (data.Payload.User.Recherche_PasswortRetry == null) {
                                this.editUser().Recherche_PasswortRetry("");
                            }
                            $("#editUser").modal('show');
                            this.showUserError('');
                        }
                    }
                }
            ];
            if (ko.toJS(c.Shorthandsymbol) !== ko.toJS(RNSAPI.User().username)) {
                if (c.IsLocked) {
                    let lockAction = {
                        icon: "fas fa-lock", name: "Nutzer entsperren", action: async () => {
                            await RNSAPI.unlockLawFirmUser(c.Shorthandsymbol);
                            this.updateUsers();
                        }
                    }
                    c.actionHandlers.push(lockAction);
                }
                else {
                    let lockAction = {
                        icon: "fas fa-unlock", name: "Nutzer sperren", action: async () => {
                            await RNSAPI.lockLawFirmUser(c.Shorthandsymbol);
                            this.updateUsers();
                        }
                    }
                    c.actionHandlers.push(lockAction);
                }

                let deleteAction = {
                    icon: "trash-alt", name: "Löschen", action: async () => {
                        this.deleteDataValue = ko.toJS(c.Shorthandsymbol);
                        this.temp("deleteUser");
                        this.deleteModalTitle("Benutzer " + c.Name + " LÖSCHEN ");
                        this.confirmDeletion();
                    }
                }
                c.actionHandlers.push(deleteAction);
            }

            return c;
        }));
    }

    async postData() {

        if (!Utils.checkErrors(["Anrede", "Rolle", "Vorname", "Nachname", "Benutzer", "Passwort", "PasswortRetry"], this.createUser(), "New", [Utils.checkString])) {
            this.showUserError('Es wurden nicht alle erforderlichen Daten eingegeben.')
            return;
        }

        if (!Utils.checkErrors(["EMail"], this.createUser(), "New", [Utils.checkEmail])) {
            this.showUserError('Ung&uuml;ltiges E-Mail Adressen Format.')
            return;
        }

        if (this.createUser().Passwort.toString() !== this.createUser().PasswortRetry.toString()) {
            Utils.checkErrors(["Passwort", "PasswortRetry"], this.createUser(), "New", [Utils.checkString])
            this.showUserError('Die eingegebenen Passw&ouml;rter f&uuml;r ihre Anmeldung stimmen nicht &uuml;berein.')
            return;
        }

        this.createUser().isAdmin((<HTMLInputElement>document.getElementById("addAdminRights")).checked);

        if (ko.toJS(this.createUser().Passwort) === ko.toJS(this.createUser().PasswortRetry) && /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/.test(ko.toJS(this.createUser().Passwort).toString())) {
            let res = await RNSAPI.createLawFirmUser(this.createUser());
            if (res.Type === "CreateUserSuccessful") {
                this.statusModalTitle('<i class="far fa-check-circle mr-2"></i> Erfolgreich');
                this.statusModalText('Der Benutzer <span class="text-default">' + this.createUser().Vorname() + ' ' + this.createUser().Nachname() + '</span> wurde erfolgreich angelegt.');
                $("#addNewUser").modal('hide');
                $("#statusModal").modal('show');
                this.createUser().Anrede("Herr");
                this.createUser().Vorname("");
                this.createUser().Nachname("");
                this.createUser().Telefon("+49");
                this.createUser().EMail("");
                this.createUser().Rolle("Anwalt");
                this.createUser().Benutzer("");
                this.createUser().Passwort("");
                this.createUser().PasswortRetry("");
                this.createUser().Recherche_EMail("");
                this.createUser().Recherche_Passwort("");
                this.createUser().Recherche_PasswortRetry("");
                this.update();
            } else {
                // this.statusModalTitle('<i class="far fa-times-circle  mr-2"></i> Fehler');
                this.statusModalText('Der Benutzer mit dem K&uuml;rzel <b>' + this.createUser().Benutzer() + '</b> existiert bereits.<br />Bitte w&auml;hlen Sie einen anderen Benutzer aus.');
                // $("#addNewUser").modal('hide');
                // $("#statusModal").modal('show');
                this.showUserError('Der Benutzer mit dem K&uuml;rzel <b>' + this.createUser().Benutzer() + '</b> existiert bereits.<br />Bitte w&auml;hlen Sie einen anderen Benutzer aus.')
            }
        }
        else {
            this.statusText('Bitte mindestens 8 Zeichen und eine Kombination aus Kleinbuchstaben, Großbuchstaben, Ziffern und Sonderzeichen verwenden.');
            this.showUserError('Bitte mindestens 8 Zeichen und eine Kombination aus Kleinbuchstaben, Großbuchstaben, Ziffern und Sonderzeichen verwenden.')
            this.hasError(true);
            this.statusVisible(true);
            this.createUser().PasswortRetry("");
            return false;
        }
    }



    loadInbox = async (id: string) => {
        let result = (await RNSAPI.getInboxById(id));
        let inbox = result.Payload;
        let tempInbox = this.inbox();
        for (let key in tempInbox) {
            if (tempInbox[key] === undefined) {
                tempInbox[key] = inbox[key];
            } else {
                tempInbox[key](inbox[key]);
            }
        }

        this.inbox(tempInbox);
    };

    async update() {
        this.updateUsers();
        this.updateReferat();
        this.updateLegalForms();
        this.updateFrist();
    }    

    async getCurrentUserDetails() {
        const lawFirm = (await RNSAPI.getCurrentLoggedInUser());
        const user = (await RNSAPI.getLawFirmUser(RNSAPI.User().username)).Payload.User;
        this.lawFirmData(this.convertBackendObjectToLawFirmData(user, lawFirm.LawFirmId));

        if (user.RollenName.includes('Admin'))
        {
            this.lawFirmData().isAdmin = ko.observable<boolean>(true);
            this.bl(true);
            $("#fileName").val(ko.toJS(lawFirm.LawFirmId));
        }
        this.initTooltip()
    }

    initTooltip() {
        $("[data-toggle='tooltip']").tooltip();
    }

    profilePhotoUrl = ko.observable();

    passwort = ko.observable({
        Passwort: ko.observable(''),
        PasswortRetry: ko.observable(''),
        Recherche_Passwort: ko.observable(''),
        Recherche_PasswortRetry: ko.observable('')
    });

    profilePhoto = (data, e) => {
        if (e.target.files[0].size / 1024 / 1024 > 1) {
            window.alert('Dateigröße nicht überschritten 1 MB');
            return;
        }

        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onloadend = (onloadend_e) => {
            let result = reader.result;
            this.profilePhotoUrl(result);
        };

        if (file) { reader.readAsDataURL(file); }
    };

    resetPasswortObject = () => {
        return ko.observable({
            Passwort: ko.observable(''),
            PasswortRetry: ko.observable(''),
            Recherche_Passwort: ko.observable(''),
            Recherche_PasswortRetry: ko.observable('')
        })
    }

    passwordsMatch = (create) => {
        var passwort = create ? this.createUser().Passwort() : this.editUser().Passwort();
        var passwortRetry = create ? this.createUser().PasswortRetry() : this.editUser().PasswortRetry();
        if (passwort !== passwortRetry) {
            this.showUserError('Die eingegebenen Passw&ouml;rter f&uuml;r ihre Anmeldung stimmen nicht &uuml;berein.');
        }
        else {
            this.showUserError('')
        }
    }

    clone(obj) {
        var target = new obj.constructor();
        for (var prop in obj) {
            target[prop] = ko.observable(obj[prop])
        }
        return target;
    }

    async getStandorte() {
        var res = (await RNSAPI.getAssignements()).Payload.Lawfirms;
        if (res.length > 0) {
            this.allStandorte([]);

            this.allStandorte(res.map((standort) => {
                standort.actionHandlers = [{
                    icon: "trash-alt",
                    name: "Löschen",
                    action: async () => {
                        let res = (await RNSAPI.removeLocation(standort.LawfirmID, standort.LawFirmUser))
                        if (res.Type === "RemoveSuccessful") {
                            this.statusModalTitle('<i class="far fa-check-circle  mr-2"></i> Erfolgreich');
                            this.statusModalText('Der Standort wurde erfolgreich entfernt.');
                            $("#statusModal").modal('show');
                            this.getStandorte();
                        }
                        else {
                            this.statusModalTitle('<i class="fas fa-exclamation-circle  mr-2"></i> Fehler beim Löschen');
                            this.statusModalText('Der Standort konnte nicht gelöscht werden.');
                            $("#statusModal").modal('show');
                        }
                    }
                }];
                return standort;
            }));
        }
    }

    async getFKA() {
        let res = (await RNSAPI.getOutlook());
        this.allFKA([]);
        this.allFKA(res.Payload.OutlookAccounts.map((fka) => {
            fka.actionHandlers = [{
                icon: "pencil-alt",
                name: "Bearbeiten",
                action: async () => {
                    this.editFKA(true);
                    this.EWSURL(fka.EWSURL);
                    this.UserLogin(fka.UserLogin);
                    this.UserPassword("");
                    this.useOAuth_Creation(fka.UseOAuth2);
                    this.OutlookAccountID(fka.OutlookAccountID);
                    $("#newFKA").modal("show");
                }
            },{
                icon: "trash-alt",
                name: "Löschen",
                action: async () => {
                    let res = (await RNSAPI.removeOutlook(fka.OutlookAccountID))
                    if (res.Type === "DeleteSuccessful") {
                        this.statusModalTitle('<i class="far fa-check-circle  mr-2"></i> Erfolgreich');
                        this.statusModalText('Das Konto wurde erfolgreich entfernt.');
                        $("#statusModal").modal('show');
                        this.getFKA();
                    }
                    else {
                        this.statusModalTitle('<i class="fas fa-exclamation-circle  mr-2"></i> Fehler beim Löschen');
                        this.statusModalText('Das Konto konnte nicht gelöscht werden.');
                        $("#statusModal").modal('show');
                    }
                }
            }];
            return fka;
        }));
    }

    async createInstance() {
        await RNSAPI.createInstance(ko.toJS(this.createInstanceName)).then((response) => {
            this.getInstances();
            $("#createInstance").modal("hide");
        }).catch((error) => {
            $("#createInstance").modal("hide");
            this.statusModalTitle('<i class="fas fa-exclamation-circle  mr-2"></i> Fehler beim Anlegen');
            this.statusModalText('Die Instanz konnte nicht angelegt werden.');
            $("#statusModal").modal('show');
        });
    }

    async createCourt(){
        await RNSAPI.createCourt(ko.toJS(this.Ger_Name), ko.toJS(this.Ger_City), ko.toJS(this.Ger_Name), ko.toJS(this.Ger_Mail), ko.toJS(this.Ger_Phone), ko.toJS(this.Ger_Postcode), ko.toJS(this.Ger_Street), ko.toJS(this.Ger_State)).then((response) => {
            this.getGerichte();
            $("#createCourt").modal("hide");
        }).catch((error) => {
            $("#createCourt").modal("hide");
            this.statusModalTitle('<i class="fas fa-exclamation-circle  mr-2"></i> Fehler beim Anlegen');
            this.statusModalText('Das Gericht konnte nicht angelegt werden.');
            $("#statusModal").modal('show');
        });
    }

    async switchStateOfCourt(){
        await RNSAPI.setCourtStateByKeyword(ko.toJS(this.Edit_Ger_Keyword), ko.toJS(this.Edit_Ger_State)).then((response) => {
            this.getGerichte();
            $("#setCourtState").modal("hide");
        }).catch((error) => {
            $("#setCourtState").modal("hide");
            this.statusModalTitle('<i class="fas fa-exclamation-circle  mr-2"></i> Fehler beim Speichern');
            this.statusModalText('Die Daten konnten nicht gespeichert werden.');
            $("#statusModal").modal('show');
        });
    }

    async editInstance() {
        await RNSAPI.updateInstance(ko.toJS(this.editInstanceId), ko.toJS(this.editInstanceName)).then((response) => {
            this.getInstances();
            $("#updateInstance").modal("hide");
        }).catch((error) => {
            $("#updateInstance").modal("hide");
            this.statusModalTitle('<i class="fas fa-exclamation-circle  mr-2"></i> Fehler beim Bearbeiten');
            this.statusModalText('Die Instanz konnte nicht bearbeitet werden.');
            $("#statusModal").modal('show');
        });
    }

    async getInstances() {
        await RNSAPI.getInstances().then((response) => {
            let res = response as any;
            res = res.map((a) => {
                a.actionHandlers = [{
                    icon: "pencil-alt",
                    name: "Instanz bearbeiten",
                    action: () => {
                        this.editInstanceId(a.Id);
                        this.editInstanceName(a.Name);
                        $("#updateInstance").modal("show");
                    }
                }];
                return a;
            })
            this.allInstances(res);
            
        }).catch((error) => {});
    }

    async getGerichte() {
        await RNSAPI.getAdressPagin(8, "").then((addresses) => {
            let addr = addresses as any;
            this.allGerichtAddresses(addr.map((a) => {
                if (a['Phone'] === '+49') a['Phone'] = '';

                a['CreatedAt'] = moment(a['CreatedAt']).format("DD.MM.YYYY");
                if (a['CreatedAt'] === 'Invalid date')
                    a['CreatedAt'] = "";
                    if (a.Name.includes("ýý")) {
                        console.log(a);
                        return "";
                    }
                    a.actionHandlers = [{
                        icon: "pencil-alt",
                        name: "Gericht bearbeiten",
                        action: () => {
                            this.Edit_Ger_Keyword(a.Keyword);
                            this.Edit_Ger_State(a.State);
                            $("#setCourtState").modal("show");
                    }
                    }]                
                    
                    a.StateName = this.statesTable[a.State];

                return a;
            }));
        }
        ).catch((error) => {
            console.log(error);
            this.allGerichtAddresses([]);
        });
    }

    async getFristDeadlineReasons() {
        let res = (await RNSAPI.getFristDeadlineReasons());
        if (res.Type === "GetFristExtensionReasonsSuccessful") {
            this.allDeadlineExtensionReasons([]);
            let list = res.Payload.DeadlineExtendReasonTypes.map((deadlineExtenstionRea) => {
                deadlineExtenstionRea.actionHandlers = [{
                    icon: "trash-alt",
                    name: "Löschen",
                    action: async () => {
                        let res = (await RNSAPI.delFristDeadlineReasons(deadlineExtenstionRea.ID))
                        if (res.Type === "DeleteFristExtensionReasonsSuccessful") {
                            this.statusModalTitle('<i class="far fa-check-circle  mr-2"></i> Erfolgreich');
                            this.statusModalText('Der Verlängerungsgrund wurde erfolgreich entfernt.');
                            $("#statusModal").modal('show');
                            this.getFristDeadlineReasons();
                        }
                        else {
                            this.statusModalTitle('<i class="fas fa-exclamation-circle  mr-2"></i> Fehler beim Löschen');
                            this.statusModalText('Der Verlängerungsgrund konnte nicht gelöscht werden.');
                            $("#statusModal").modal('show');
                        }
                    }
                }];
                return deadlineExtenstionRea;
            })
            list.sort((a, b) => a.Reason.localeCompare(b.Reason))

            this.allDeadlineExtensionReasons(list);
        }
    }

    async saveUserProfile() {
        this.statusVisible(false);
        $("html, body").animate({ scrollTop: 0 }, "slow");

        if (!Utils.checkMultipleErrors(["Firstname", "Lastname"], this.lawFirmData(), "", [Utils.checkString])) {
            this.statusText('Bitte Pflichtfelder ausfüllen');
            this.hasError(true);
            this.statusVisible(true);
            return;
        }
        if (!Utils.checkMultipleErrors(["Mail"], this.lawFirmData(), "", [Utils.checkEmail])) {
            this.statusText('Ung&uuml;ltiges E-Mail Adressen Format.');
            this.hasError(true);
            this.statusVisible(true);
            return;
        }

        else {
      
            if(this.lawFirmData().Password) {
                const isValid = this.checkAndValidatePassword();
                if(isValid) {
                    Postbox.publisher().publish(true, "DoesUserChangedThePassword");
                } else {
                    return;
                }
            }
            $("#VerifyPasswordDialogExt").modal('show');
        }
    }

    private convertLawFirmDataToBackendObject(): any {    
        const object = {
            Anrede: this.lawFirmData().FormOfAddress,
            Vorname: this.lawFirmData().Firstname,
            Nachname: this.lawFirmData().Lastname,
            Adresszusatz: this.lawFirmData().AdditionalAddress,
            Strasse: this.lawFirmData().Address,
            Postleitzahl: this.lawFirmData().PostCode,
            Ort: this.lawFirmData().Place,
            Telefon: this.lawFirmData().Phone,
            EMail: this.lawFirmData().Mail,
            Website: this.lawFirmData().Website,
            Recherche_EMail: this.lawFirmData().SoldanMail,
            Passwort: this.lawFirmData().Password,
            PasswortRetry: this.lawFirmData().PasswordRetry,
            Rolle: this.lawFirmData().UserRole,
            RollenName: this.lawFirmData().RoleName,
            Mobile: this.lawFirmData().Mobile,
            Title: this.lawFirmData().Title,
            Recherche_Passwort: this.lawFirmData().SoldanPassword,
            Recherche_PasswortRetry: this.lawFirmData().SoldanPassword,
            Benutzer: this.lawFirmData().Username,
            IsLocked: this.lawFirmData().IsLocked,
            Kanzleiname: this.lawFirmData().LawFirmName,
            isAdmin: this.lawFirmData().isAdmin
        };
        return object;
    }

    private convertBackendObjectToLawFirmData(data: any, lawFirmId: string): any {
        const object = {
            FormOfAddress: data.Anrede,
            Firstname: data.Vorname,
            Lastname: data.Nachname,
            AdditionalAddress: data.Adresszusatz,
            Address: data.Strasse,
            PostCode: data.Postleitzahl,
            Place: data.Ort,
            Phone: data.Telefon,
            Mail: data.EMail,
            Website: data.Website,
            SoldanMail: data.Recherche_EMail,
            Password: data.Passwort,
            PasswordRetry: data.PasswortRetry,
            UserRole: data.Rolle,
            RoleName: data.RollenName,
            Mobile: data.Mobile,
            Title: data.Title,
            SoldanPassword: data.Recherche_Passwort,
            Username: data.Benutzer,
            IsLocked: data.IsLocked,
            LawFirmName: data.LawfirmName,
            LawFirmId: lawFirmId === "" ? "" : lawFirmId,
        }
        return object;
    }

    private checkAndValidatePassword(): boolean {
        if(!this.lawFirmData().Password || !this.lawFirmData().PasswordRetry) {
            this.statusText('Das wiederholte Passwort für Ihre Anmeldung darf nicht leer sein.');
            this.hasError(true);
            this.statusVisible(true);
            return false;
        }

        if(this.lawFirmData().Password.toString() === "" || this.lawFirmData().PasswordRetry.toString() === "") {
            this.statusText('Das wiederholte Passwort für Ihre Anmeldung darf nicht leer sein.');
            this.hasError(true);
            this.statusVisible(true);
            return false;
        }

        if(this.lawFirmData().Password !== this.lawFirmData().PasswordRetry) {
            this.statusText('Die eingegebenen Passw&ouml;rter stimmen nicht &uuml;berein.');
            this.hasError(true);
            this.statusVisible(true);
            return false;
        }

        if (this.lawFirmData().Password.toString().includes('"')) {
            this.statusText('Das Kennwort darf kein Anf&uuml;hrungszeichen (") enthalten.');
            this.hasError(true);
            this.statusVisible(true);
            return false;
        }

        if (/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/.test(this.lawFirmData().Password.toString())) return true;

        this.statusText('Bitte mindestens 8 Zeichen und eine Kombination aus Kleinbuchstaben, Großbuchstaben, Ziffern und Sonderzeichen verwenden.');
        this.hasError(true);
        this.statusVisible(true);
        this.lawFirmData().Password("");
        this.lawFirmData().PasswordRetry("");
        return false;
    }

    constructor(params: any) {

        this.referateStatus(true);
        this.weiderStatus(true);
        this.fristStatus(true);
        this.rachtsStatus(true);
        this.getInstances();
        this.getFKA();
        this.getFristDeadlineReasons();
        this.getGerichte();

        this.inbox().EMail_Address.subscribe((newValue) => {
            if (this.inbox().IN_UserLogin() === "") this.inbox().IN_UserLogin(newValue);
            if (this.inbox().OUT_UserLogin() === "") this.inbox().OUT_UserLogin(newValue);
        });

        this.currentView = ko.observable(params.view);

        if (params.view == "showUsers") {
            this.currentView = ko.observable('profile');
        }
        else {
            this.currentView = ko.observable(params.view);
        }

     

        this.filteredInboxes = ko.computed({
            owner: this,
            read: () => {
                const inboxes = this.filterType() === 'bea' ? this.inboxes()
                    .filter(inbox => inbox.CertificatePin === 'B') :
                    this.inboxes()
                        .filter(inbox => !inbox.CertificatePin)

                return inboxes
            }
        });
        this.update();
        this.getCurrentUserDetails()
        $('.menu>li a').removeClass('active');

        Postbox.publisher().subscribe(async (isVerified) => {
            if(isVerified) await this.executeSaveUserProfile();
        }, "VerifiedPassword");
        
    }

    private async executeSaveUserProfile() {
        await RNSAPI.updateUserData(this.convertLawFirmDataToBackendObject()).then((data) => {
            this.statusText('Erfolgreich gespeichert.');
            this.hasError(false);
            this.statusVisible(true);
        }).catch((error) => {
            this.statusText('Benutzeraktualisierung war nicht erfolgreich.');
            this.hasError(true);
            this.statusVisible(true);
        });
    }
}

let html = fs.readFileSync(__dirname + '/overview.html', 'utf8');

ko.components.register("settings-view", {
    viewModel: SettingsViewModel,
    template: html
});