import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import '../extended/new';
import '../dialog/AppointmentDialog'
import { IntermediateListItem, Color } from '../dataTable/dataTable';
import { Utils } from '../../utils';
import * as moment from "moment"
import "../../../node_modules/moment/locale/de.js";
import * as fs from 'fs';
import { Postbox } from "../postbox/postbox";
import "../dialog/CompleteDeadlineDialog";
import "../dialog/CompleteAppointmentDialog";

enum Status {
    Done = 'E',
    New = 'N',
    Running = 'L',
    FurtherTerminating = 'W',
}
export class TfwViewModel {
    addressTypes = ko.observableArray(["Mandant", "Gegner", "Sonstige", "Gericht", "Versicherung", "Behörde", "Anwalt"]);
    columnHeaders = ko.observableArray(["status", "Ablaufdatum", "Standort", "Typ", "Akte", "Rubrum", "Grund", "Notiz", "Fristenkontrolle"]);
    columns = ko.observableArray(['stars', 'date', 'ref', 'entryTypeName', 'number', 'rubrum', 'subject', 'note', 'SB'])
    appointments = ko.observableArray([]);
    deadlines = ko.observableArray([]);
    resubmissions = ko.observableArray([]);
    todos = ko.observableArray([]);
    lastWorkedOn = ko.observableArray([]);
    isempty = ko.observable("-/-");
    isemptyDate = ko.observable("TT.MM.JJJJ")
    selectedAddress = ko.observable(this.addressTypes()[0]);
    defaultSachbearbeiter = ko.observable();
    Rubrum = ko.observable("Kein Rubrum vorhanden.");
    AllSB = ko.observableArray();
    newSB = ko.observable("");
    newSB1 = ko.observable("");
    openDashboardAppointment = ko.observable();
    appointmentId = ko.observable();
    filter = ko.observable("");
    sachbearbeiter = ko.observable("");
    ProcessPartner = ko.observable("");
    ProcessPartnerId = ko.observable("");
    Fristenkontrolle = ko.observable("");
    FristenkontrolleId = ko.observable("");
    range = ko.observable(false);
    rStartDate = ko.observable("");
    rEndDate = ko.observable("");
    dailyReportDate = ko.observable("");
    filters = ko.observableArray([]);
    values = ko.observable({
        complete: ko.observable(true),
        green: ko.observable(true),
        yellow: ko.observable(true),
        red: ko.observable(true),
    })
    types = ko.observable({
        Frist: ko.observable(true),
        Termin: ko.observable(true),
        Wiedervorlage: ko.observable(true),
    })
    currentID = ko.observable('');
    hasButton = ko.observable(true);
    registerNumber = ko.observable('')
    oldDeadline = ko.observable(null);
    extendDeadlineDate = ko.observable();
    extendPreDeadlineDate = ko.observable();
    extendDeadlineNote = ko.observable();
    extendDeadlineClerk = ko.observable();
    checkDeadline = ko.observable(true);
    checkApp = ko.observable(true);
    checkResub = ko.observable(true);
    tooltips = ko.observableArray([{ name: 'PreDeadline', col: 'entryTypeName' }, { name: 'TypeId', col: 'subject' }])
    showTermine = ko.observable(false);
    showFristen = ko.observable(false);
    showWiedervorlagen = ko.observable(false);
    calledfirst = ko.observable(true);
    allStandorte = ko.observableArray([]);
    hasRightsForStandorte = ko.observable(false);
    hasReferatCount = ko.observable(0);
    allStandortSelected = ko.observable(false);
    firstCall = ko.observable(true);
    currentRubrum = ko.observable('');
    AllReferate = ko.observable();
    showNotification = ko.observable(false);
    showParameterMessage = ko.observable(false);
    allDeadlineExtensionReasons = ko.observableArray([]);
    allExtendsionReasonsLength = ko.observable(0);
    extendDeadlineReason = ko.observable('');
    instancesInCase = ko.observableArray([]);

    fKAId = ko.observable('');
    fkAName = ko.observable('');
    showError = ko.observable(false);
    showExternError = ko.observable(false);
    showSuccess = ko.observable(false);
    showExternSuccess = ko.observable(false);
    showOutlookLoader = ko.observable(false);
    OutlookAccounts = ko.observableArray([]);

    selectedFilterStandort = ko.observable("");
    selectedFilterAccount = ko.observable("");
    selectedFilterAccountKontrollblatt = ko.observable("");
    selectedInstance = ko.observable("");
    pastFristApproved = ko.observable(false);

    verifyDeadlineDate = ko.observable("YYYY-MM-DD");
    notifyVerifyDeadline = ko.observable(false);

    attendeesType() {
        let obj = {
            EMailAddress: ko.observable(''),
            IsRequired: ko.observable(true)
        }
        return obj
    }
    attendees = ko.observableArray([]);

    allDifferentLawfirmDeadlines = ko.observableArray([]);

    ReportFilter = ko.observable({
        CaseID: ko.observable(""),
        DoneOnly: ko.observable(false),
        Locations: ko.observableArray(null),
        ClerkID: ko.observable(""),
        Standorte: ko.observableArray(null)
    })

    readonly DANGER_COLOR: Color = {
        color: "white",
        "background-color": "#e21b38"
    }

    wholeday = ko.observable(false);

    currentView = ko.observable("ftw");
    filteredTodos: ko.Computed<any>;

    pickedWeekDate = ko.observable(moment.utc().startOf("day"));

    today = () => this.pickedWeekDate(moment.utc().startOf("day"));

    filterByRange() {
        this.range(true)
    }

    filterType(type: string) {
        if (type == 'frist') {
            this.types().Frist(this.checkDeadline())
        }
        else if (type == 'resubmission') {
            this.types().Wiedervorlage(this.checkResub())
        }
        else if (type == 'termin') {
            this.types().Termin(this.checkApp())
        }
        this.filter(this.filter());
    }

    locationChecked(obj: any) {
        console.log(obj);
    }

    startOfDay = ko.computed(() => {
        let weekDate = this.pickedWeekDate().clone();
        return weekDate;
    });

    endOfDay = ko.computed(() => {
        let weekDate = this.pickedWeekDate().clone();
        return weekDate.endOf("day");
    });

    startOfThisMonth: moment.Moment;

    startOfNextMonth: moment.Moment;

    deadlineToBeModified = ko.observable(null);

    totalInactive = ko.observable();

    ErledigtFr = ko.observable(false);
    UnerledigtFr = ko.observable(true);

    filterWithRange(item) {
        let eDate = this.rEndDate() ? this.rEndDate() : '9999-01-01';
        if (ko.toJS(this.rEndDate) !== "" && ko.toJS(this.rStartDate) === "") {
            this.rStartDate(ko.toJS(moment().format("YYYY-MM-DD")))
        }
        if (ko.toJS(this.rEndDate) === "" && ko.toJS(this.rStartDate) !== "") {
            this.rEndDate(ko.toJS(moment().format("YYYY-MM-DD")))
        }
        if (item['date'] && this.rStartDate() && moment(item['date'], 'DD.MM.YYYY').isSameOrAfter(this.rStartDate()) && moment(item['date'], 'DD.MM.YYYY').isSameOrBefore(eDate)) {
            return true
        }
        return false
    }

    filterWithStars(item, filters) {
        if (item['stars'] && filters.indexOf(item['stars']) !== -1) return true
        return false
    }

    filterWithType(item, filters) {
        if (item['entryTypeName'] && filters.indexOf(item['entryTypeName']) !== -1) return true
        return false
    }

    filterWithStandort(item) {
        if (item['ref'] && ko.toJS(this.selectedFilterStandort) === item['ref']) return true
        return false
    }

    filterWithFKA(item) {
        if (item['fka'] && ko.toJS(this.selectedFilterAccount) === item['fka']) return true
        return false
    }
    filterWithInstance(item) {
        if (item['instanceId'] && ko.toJS(this.selectedInstance) === item['instanceId']) return true
        return false
    }

    loggedInUserTechnicalRights = ko.observable();

    getNextWV(items) {
        if (items.length > 0) {
            let found = false;
            let intervall = 1;
            let notify = false;
            for (let i = items.length - 1; i >= 0; i--) {
                if (items[i].stars === "red")
                    notify = true;
                if (items[i].entryType === "resubmission") {
                    if (Number(moment(ko.toJS(items[i].date), "DD.MM.YYYY").format("YYYYMMDD")) >= Number(moment().format("YYYYMMDD"))) {
                        found = true;
                    }
                    else {
                        if (found) {
                            $("#NextWVCase").removeClass("hide");
                            $("#NextWVCaseLbl").removeClass("hide");
                            $("#grundcaseWV").val(items[i + intervall].subject);
                            $("#deadlineDateCaseWV").val(moment(items[i + intervall].date, "DD.MM.YYYY").format("YYYY-MM-DD"));
                            $("#SBcaseWV").val(items[i + intervall].SB);
                            break;
                        }
                    }
                    intervall = 1;
                }
                else {
                    intervall++;
                }
            }

            if (notify) {
                $("#redNotificationCircle").attr("style", "font-size: 0.75rem; vertical-align: middle !important; margin-bottom: 0.4rem; display: inline-block");
                $("#redNotificationCircleTFW").attr("style", "font-size: 0.5rem; vertical-align: middle !important; margin-bottom: 0.1rem; display: inline-block")
            }
        }
    }

    public getVM = () => this;

    async updateTodos(allAppointments: any, allDeadlines: any) {
        try {
            let appointments;
            let deadlines;

            if (ko.toJS(this.currentID) !== '')
                appointments = allAppointments.Payload.Appointments
            else
                appointments = allAppointments.Payload.Appointments.filter((a: any) => a.AppointmentType !== "F" && !a.Completed)

            deadlines = allDeadlines.Payload.Deadlines;

            let transformedAppointments = appointments.map(obj => this.transformAppointmentData(obj, "appointment"));
            if (transformedAppointments.length > 0)
                this.showTermine(true);
            else
                this.showTermine(false);

            let transformedDeadlines = deadlines.map(obj => this.transformDeadlineData(obj, "deadline"));

            if (transformedDeadlines.length > 0)
                this.showFristen(true);
            else
                this.showFristen(false);

            let transformedResubmissions = [];

            let todos: any;
            if (this.checkDeadline() && !this.checkResub() && this.checkApp()) {
                todos = (transformedAppointments.concat(transformedDeadlines));
            }
            if (this.checkDeadline() && this.checkResub() && !this.checkApp()) {
                todos = (transformedDeadlines.concat(transformedResubmissions));
            }
            if (!this.checkDeadline() && this.checkResub() && this.checkApp()) {
                todos = (transformedAppointments
                    .concat(transformedResubmissions));
            }
            if ((this.checkDeadline() && this.checkResub() && this.checkApp()) || !this.checkDeadline() && !this.checkResub() && !this.checkApp()) {
                todos = (transformedAppointments.concat(transformedDeadlines)
                    .concat(transformedResubmissions));
            }
            if ((this.checkDeadline() && (!this.checkResub() && !this.checkApp()))) {
                todos = transformedDeadlines;
            }
            if ((!this.checkDeadline() && this.checkResub()) && !this.checkApp()) {
                todos = transformedDeadlines;
            }
            if ((!this.checkDeadline() && (this.checkResub() && !this.checkApp()))) {
                todos = transformedResubmissions;
            }
            if ((!this.checkDeadline() && (!this.checkResub() && this.checkApp()))) {
                todos = transformedAppointments;
            }
            let sortedTodos = todos.sort((t1: IntermediateListItem, t2: IntermediateListItem) => {
                if (t1.dateObj.isBefore(t2.dateObj)) {
                    return 1;
                } else {
                    return -1;
                }
            }).map(obj => {
                if (obj.entryTypeName === "Frist" || obj.entryType === "ecase" || obj.entryTypeName === "Vorfrist" || obj.entryTypeName === "Wiedervorlage" || obj.entryTypeName === "Termin") {
                    obj.columnActions = {
                        "number": MainViewModel.RoutingTable.generateLink(`/new/${encodeURIComponent(obj.number)}`)
                    };
                }
                return obj;
            }).reverse();

            this.todos(sortedTodos);
        }
        catch (e) {
            console.log(e);
        }
    }

    updateTooltip() {
        setTimeout(() => {
            $("[data-toggle='tooltip']").tooltip();
        }, 10000)
    }

    public showFtw() {
        MainViewModel.RoutingTable.showTfwView({ view: "ftw" }, true);
        this.currentView('ftw');
    }

    transformAppointmentData(obj: any, type: string) {
        let actionList = [
            {
                icon: "pencil-alt", name: "Bearbeiten", action: () => {
                    this.openDashboardAppointment(true)
                    this.IsEditMode(true)
                    this.appointmentId(obj.Id)
                    Postbox.publisher().publish(obj.Id, "FillAppointmentDataWithID");
                    $("#AppointmentTFWmodal").modal("show");
                }
            },
            {
                icon: "trash-alt", name: "Löschen", action: async () => {
                    Postbox.publisher().publish({ Id: obj.Id , Complete: false}, "CompleteAppointmentModalData");
                }
            },
        ];

        if (!obj.Completed) {
            actionList.push({
                icon: "check", name: "Abhaken", action: async () => {
                    Postbox.publisher().publish({ Id: obj.Id , Complete: true}, "CompleteAppointmentModalData");
                }
            });
        }

        let stars = "";
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        let d = new Date(obj.EndDate);
        d.setHours(0, 0, 0, 0);
        if (obj.Completed)
            stars = "complete"
        else {
            if (d < today) {
                stars = "red";
            } else {
                stars = "yellow";
            }
        }

        if(ko.toJS(this.AllReferate).length > 0)
        {
            let refs = ko.toJS(this.AllReferate);
            let loc = refs.filter(a => a.Id == obj.LocationId)
            if(loc.length === 1 )
                obj.locationID = loc[0].Name;
            else
                obj.locationID = "";
        }
        else
            obj.locationID ="";

        obj.fka = obj.Fka;

        return new IntermediateListItem(stars, type, nameTable[type], obj.StartDate, obj.AppointmentSubject, obj.CaseId, obj, actionList, undefined, obj.Rubrum, obj.SB, obj.AppointmentNote, obj.locationID, obj.fka);
    }

    loaderTransferExtern = ko.observable(false);
    externalDeadlineExport = ko.observable();
    externalAddresses = ko.observableArray([]);

    transformDeadlineData(obj: any, type: string) {
        obj.Note = obj.Note1;
        let check = [
            {
                icon: "check", name: "Abschließen", action: async () => {
                    obj.CompletionDate = "";
                    obj.CompletionDate2 = "";
                    Postbox.publisher().publish({ Reason: obj.TypeDescription, CaseId: obj.CaseId, Id: obj }, "CompleteDeadlineModalData");
                }
            }

        ];

        let delegate = [
            {
                icon: "user-circle", name: "Delegieren", action: async () => {
                    this.deadlineToBeModified(obj);
                    this.getFKA(obj.CaseId);
                    $('#delegateDeadlineModal').modal('show');
                    $('#newSB').val(ko.toJS(obj.Clerk));
                }
            }
        ];

        let extendable = [
            {
                icon: "pencil-alt", name: "Verlängern", action: async () => {
                    this.deadlineToBeModified(obj);
                    this.getFKA(obj.CaseId);
                    $('#extendDeadlineModal').modal('show');
                    this.editPastFrist();
                    this.extendDeadlineDate(ko.toJS(moment(this.deadlineToBeModified().Deadline).format("YYYY-MM-DD")));
                    this.extendPreDeadlineDate(ko.toJS(moment(this.deadlineToBeModified().PreDeadline).format("YYYY-MM-DD")));
                    this.extendDeadlineNote(ko.toJS(this.deadlineToBeModified().Note));
                    this.extendDeadlineClerk(RNSAPI.getCurrentLoggedInUser().ShortHandSymbol)
                }
            }
        ];

        let transferToExternal = [
            {
                icon: "file-import", name: "An externe Software übertragen", action: async () => {
                    this.loaderTransferExtern(true);
                    $('#exportToExternalOrOutlook').modal('show');
                    await RNSAPI.redoExternalDeadlineExport(obj).then((data) => {
                        this.showExternalExportError(false);
                        this.showExternalExportSuccess(true);
                        setTimeout(() => {
                            this.showExternalExportSuccess(false);
                            this.update();
                            $('#exportToExternalOrOutlook').modal('hide');
                        }, 3000);
                    }).catch((error) => {
                        console.log(error);
                        this.loaderTransferExtern(false);
                        this.showExternalExportError(true);
                        setTimeout(() => {
                            this.showExternalExportError(false);
                            $('#exportToExternalOrOutlook').modal('hide');
                        }, 5000);

                    });
                }
            }
        ];

        let transferExternalSuccessfull = [
            {
                icon: "check-circle", name: "Datensatz wurde erfolgreich an externe Software übermittelt.", action: async () => {}
            }
        ];

        let transferExternalFailed = [
            {
                icon: "times-circle", name: "Datensatz konnte nicht erfolgreich an externe Software übermittelt werden.", action: async () => { }
            }
        ];

        let actionList = [
            {
                icon: "history", name: "Verlauf", action: async () => {
                    this.calledfirst(true);

                    this.getDeadlineLength(obj.TypeId)
                    if (!obj.EditedObj)
                        obj.EditedObj = false;

                    obj.Deadline = ko.toJS(moment(ko.toJS(obj.Deadline)).format("YYYY-MM-DD"));
                    obj.StartDate = ko.toJS(moment(ko.toJS(obj.StartDate)).format("YYYY-MM-DD"));

                    this.startRepDeadline(obj.Deadline);
                    this.endRepDeadline(obj.Deadline);
                    this.caseRepDeadline(obj.CaseId);

                    obj.PreDeadline = ko.toJS(moment(ko.toJS(obj.PreDeadline)).format("YYYY-MM-DD"));


                    if (!obj.EditedObj) {
                        obj.CreatedBy = ko.toJS(await this.getUserForShorthand(obj.CreatedBy)).replace('Bitte wählen Sie', '');
                        if (obj.CreationDate !== null && obj.CreationDate !== "")
                            obj.CreationDate = ko.toJS(moment(ko.toJS(obj.CreationDate)).format("DD.MM.YYYY"));
                        else
                            obj.CreationDate === "";

                        obj.EditedFromId = ko.toJS(await this.getUserForShorthand(obj.EditedFromId)).replace('Bitte wählen Sie', '');
                        if (obj.EditedAt !== null && obj.EditedAt !== "")
                            obj.EditedAt = ko.toJS(moment(ko.toJS(obj.EditedAt)).format("DD.MM.YYYY"));
                        else
                            obj.EditedAt = "";

                        obj.CompletedBy = ko.toJS(await this.getUserForShorthand(obj.CompletedBy)).replace('Bitte wählen Sie', '');
                        if (obj.CompletionDate !== null && obj.CompletionDate !== "")
                            obj.CompletionDate = ko.toJS(moment(ko.toJS(obj.CompletionDate)).format("DD.MM.YYYY"));
                        else
                            obj.CompletionDate = "";
                        obj.EditedObj = true;
                    }
                    this.deadlineToBeModified(obj);
                    $('#historyDeadlineModal').modal('show');
                }
            }
        ];
        
        if(ko.toJS(this.AllReferate).length > 0)
        {
            let refs = ko.toJS(this.AllReferate);
            let loc = refs.filter(a => a.Id == obj.LocationId)
            if(loc.length === 1 )
                obj.locationID = loc[0].Name;
            else
                obj.locationID = "";
        }
        else
            obj.locationID ="";

        obj.fka = obj.FKAName;

        let uid = obj.ExternalCaseUniqueId;

        if (obj.CompletionDate === null) {
                if (ko.toJS(this.loggedInUserTechnicalRights) === 1) {
                    if (uid) {
                        if (obj.ExportToExternal === true) {
                            actionList.push(transferExternalSuccessfull[0]);
                        }
                        else {
                            actionList.push(transferExternalFailed[0]);
                        }
                    }
                }
            if (ko.toJS(this.loggedInUserTechnicalRights) === 1)
                if (uid !== "")
                    actionList.push(transferToExternal[0]);

            actionList.push(delegate[0]);
            if (!ko.toJS(this.isNotfrist(obj.TypeId)))
                actionList.push(extendable[0]);
            actionList.push(check[0]);
        }

        let stars = "";
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        let d = new Date(obj.Deadline);
        d.setHours(0, 0, 0, 0);
        if (obj.CompletionDate !== "" && obj.CompletionDate !== null) {
            stars = "complete";
        }
        else {

            if (d < today) {
                if (obj.CompletionDate == "" || obj.CompletionDate == null) {
                    stars = "red";
                } else {
                    stars = "complete";
                }
            }
            else {
                stars = "yellow";
            }
        }

        if (ko.toJS(this.instancesInCase).length > 0) {
            obj.InstanceId = obj.CourtInstanceId;
            for (let inst of ko.toJS(this.instancesInCase)) {
                if (obj.CourtInstanceId === inst.InstanceId) {
                    obj.Instance = inst.InstanceType +" "+ inst.CourtId;
                    break;
                }
            }
            if (!obj.Instance)
                obj.Instance = "";
        }

        return new IntermediateListItem(stars, type, nameTable[type], obj.Deadline, obj.TypeDescription, obj.CaseId, obj, actionList, undefined, obj.Rubrum, obj.SB, obj.Note, obj.locationID, obj.fka, obj.Instance, obj.CourtInstanceId);
    }

    showExternalExportSuccess = ko.observable(false);
    showOutlookExportSuccess = ko.observable(false);
    showExternalExportError = ko.observable(false);
    showOutlookExportError = ko.observable(false);

    async transferDeadlineToOutlook() {
        (<HTMLButtonElement>document.getElementById("transferToExt_btn")).disabled = true;
        this.loaderTransferExtern(true);
        if ((<HTMLInputElement>document.getElementById("customCheckOutlook")).checked && this.externalDeadlineExport().ExportToOutlook === false) {
            await RNSAPI.redoOutlookDeadlineExport(this.externalDeadlineExport(), this.externalAddresses(), true).then((data) => {
                console.log(data);
                this.update();
                $('#exportToExternalOrOutlook').modal('hide');
            }).catch((error) => {
                this.showOutlookExportError(true);
                console.log(error);
            });
        }

        if ((<HTMLInputElement>document.getElementById("customCheckExternal")).checked) {
            await RNSAPI.redoExternalDeadlineExport(this.externalDeadlineExport()).then((data) => {
                console.log(data);
            }).catch((error) => {
                this.showExternalExportError(true);
                console.log(error);
            });
        }
        this.loaderTransferExtern(false);
        (<HTMLButtonElement>document.getElementById("transferToExt_btn")).disabled = false;


    }

    transformResubmissionData(rObj: any, type: string) {
        let checkAction = {
            icon: "check",
            name: "Abschließen",
            action: async () => {
                await RNSAPI.completeResubmission(rObj.ID);
                this.update();
            }
        };

        let stars = "";
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        let d = new Date(rObj.DeadLineDate);
        d.setHours(0, 0, 0, 0);
        if (d < today) {
            if (rObj.ErledigtDatum == "" || rObj.ErledigtDatum == null) {
                stars = "red";
            } else {
                stars = "complete";
            }
        }
        else if (d >= today) {
            if (rObj.ErledigtDatum == "" || rObj.ErledigtDatum == null) {
                stars = "yellow";
            } else {
                stars = "complete";
            }
        }

        let actionList = [
            {
                icon: "pencil-alt", name: "Edit", action: async () => {
                    $('#extendResubmissionModal').modal('show');
                    this.akteExtendResubmission(ko.toJS(rObj));
                    rObj.DeadLineDate = moment(rObj.DeadLineDate).format('YYYY-MM-DD');
                    $('#wiedervorlage').val(rObj.DeadLineDate);

                }
            },
            {
                icon: "user-circle", name: "Delegieren", action: async () => {
                    this.akteExtendResubmission(ko.toJS(rObj));
                    $('#delegateResubmissionModal').modal('show');
                    $('#delegate').val(rObj.Sachbearbeiter);
                }
            },
        ]

        if (rObj.ErledigtDatum === null)
            actionList.push(checkAction);

        return new IntermediateListItem(stars, type, nameTable[type], rObj.DeadLineDate, rObj.DeadLineReason, rObj.Case_ID || rObj.Sachbearbeiter, rObj, actionList, undefined);
    }

    allUsers = ko.observableArray();

    startRepDeadline = ko.observable('');
    endRepDeadline = ko.observable('');
    caseRepDeadline = ko.observable('');
    deadlineLength = ko.observable('');

    isNotfrist(TypeId: string) {
        switch (TypeId) {
            case "AV":
                return true;
            case "B":
                return true;
            case "ER":
                return true;
            case "ES":
                return true;
            case "NZ":
                return true;
            case "R":
                return true;
            case "SB":
                return true;
            case "RV":
                return true;
            case "We":
                return true;
            case "EP":
                return true;
            case "WK":
                return true;
            case "GE":
                return true;
            case "EA":
                return true;
            case "Er":
                return true;
            case "WA":
                return true;
            default:
                return false;
        }
    }

    async getUserForShorthand(shorthand: string) {
        for (let i = 0; i < this.allUsers().length; i++) {
            if (ko.toJS(this.allUsers())[i].Shorthandsymbol === shorthand)
                return ko.toJS(this.allUsers())[i].Name;
        }
        return "";
    }

    async getAllUsers() {
        let res = (await RNSAPI.getLawFirmUsers()).Payload.Users
        this.allUsers(res);
    }

    async getDeadlineLength(TypeId: string) {
        let res = (await RNSAPI.getDeadlineReasons()).Payload.Reasons
        for (let i = 0; i < res.length; i++) {
            if (TypeId === res[i].DeadlineNumber) {
                if (res[i].DeadlineDuration !== "") {
                    this.deadlineLength(this.formatDeadlineDuration(res[i].DeadlineDuration, res[i].PreDeadlineDuration));
                }
                break;
            }
        }
    }

    formatDeadlineDuration(deadduration: string, preduration = "") {
        let duration_names = { s: { t: 'Tag', w: 'Woche', m: 'Monat', j: 'Jahr' }, p: { t: 'Tage', w: 'Wochen', m: 'Monate', j: 'Jahre' } }
        let oldDuration = ko.toJS(deadduration).slice(1);
        let durationtext = "";
        if (oldDuration.startsWith("0"))
            oldDuration = oldDuration.slice(1);
        if (/^\w\d+$/.test(deadduration)) {
            let duration = Number(deadduration.slice(1));
            let dtype = deadduration[0];
            durationtext = duration + ' ' + duration_names[duration == 1 ? 's' : 'p'][dtype];
        }
        if (ko.toJS(preduration) !== "") {
            try {
                let days = parseInt(preduration)
                if (days > 1)
                    preduration = preduration + " Tage";
                else
                    preduration = preduration + " Tag";

                durationtext = durationtext + " | Vorfrist: " + preduration;
            } catch{ }
        }
        return durationtext;
    }

    showPrintLoader = ko.observable(false);
    showPrintError = ko.observable(false);

    async printDailyReportDeadline() {
        (<HTMLButtonElement>document.getElementById("printDailyReportDeadline_btn")).disabled = true;
        this.showNotification(false);
        let place = "";
        let placeName = "";
        let accountNames = ko.toJS(this.OutlookAccounts);
        for(let i =0; i< accountNames.length; i++)
        {            
            if(accountNames[i].OutlookAccountID === ko.toJS(this.selectedFilterAccountKontrollblatt)){   
                place = accountNames[i].UserLogin;
                try{
                    placeName = accountNames[i].UserLogin.replace('@', '_').replace('$', '');
                }
                catch{}
                break;
            }
        }

        this.dailyReportDate((<HTMLInputElement>document.getElementById("dailyReportDate")).value);
        this.showPrintLoader(true);
        try {
            let res = (await RNSAPI.reportDeadlinePerDay(moment(ko.toJS(this.dailyReportDate), "YYYY-MM-DD"), place, ko.toJS(this.selectedFilterAccountKontrollblatt)));
            if (res.Type === "ReportPerDaySuccessful") {
                setTimeout(() => { }, 1000)
                let from = moment(ko.toJS(this.dailyReportDate), "YYYY-MM-DD").format("DD.MM.YYYY");

                let element = document.createElement('a');
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(res.Payload.DocumentData);
                } else {
                    element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + res.Payload.DocumentData);
                    element.setAttribute('download', `${'Kontrollblatt vom ' + from + '(' + placeName + ')'}.docx`);

                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                    (<HTMLButtonElement>document.getElementById("printDailyReportDeadline_btn")).disabled = false;
                    $("#reportDailyDeadlineModal").modal("hide");
                    this.showPrintLoader(false);
                }
            }
        }
        catch {
            this.showPrintLoader(false);
            this.showPrintError(true);
            setTimeout(() => {
                this.showPrintError(false);
                (<HTMLButtonElement>document.getElementById("printDailyReportDeadline_btn")).disabled = false;
            }, 3000);
        }
    }

    async printDeadline(exact) {
        if (!ko.toJS(this.calledfirst)) {
            this.showNotification(false);
            this.showParameterMessage(false);
            if (exact) {
                this.startRepDeadline((<HTMLInputElement>document.getElementById("startRepDeadline")).value);
                this.endRepDeadline((<HTMLInputElement>document.getElementById("endRepDeadline")).value);
            }

            this.ReportFilter().CaseID(ko.toJS(this.caseRepDeadline));

            this.ReportFilter().ClerkID(ko.toJS(this.ProcessPartnerId));

            if (ko.toJS((<HTMLInputElement>document.getElementById("inlineRadio2")).checked))
                this.ReportFilter().DoneOnly(true);
            else
                this.ReportFilter().DoneOnly(false);

            if (this.AllReferate().length > 0 && exact) {
                let standortId = "";
                let oneSelect = false;
                let multiSelect = false;
                let noStandort = (<HTMLInputElement>document.getElementById("noStandort_cb")).checked;
                if (noStandort) {
                    oneSelect = true;
                    standortId = "";
                }

                for (let i = 0; i < this.AllReferate().length; i++) {
                    let isChecked = (<HTMLInputElement>document.getElementById(this.AllReferate()[i].Id + "_2_checkbox")).checked;
                    if (isChecked) {
                        if (oneSelect) {
                            multiSelect = true;
                            break;
                        }
                        oneSelect = true;
                        standortId = this.AllReferate()[i].Id;
                    }
                }
                if (!oneSelect || multiSelect) {
                    this.showNotification(true);
                    return;
                }
                this.ReportFilter().Standorte([]);
                this.ReportFilter().Standorte.push(standortId);
            }
            
            this.ReportFilter().ClerkID("");
            if(exact)
                this.ReportFilter().ClerkID(ko.toJS(this.FristenkontrolleId));

            try {
                this.showPrintLoader(true);
                let res = (await RNSAPI.reportDeadline(moment(ko.toJS(this.startRepDeadline), "YYYY-MM-DD"), moment(ko.toJS(this.endRepDeadline), "YYYY-MM-DD"), ko.toJS(this.ReportFilter)));
                if (res.Type === "ReportSuccessful") {
                    setTimeout(() => { }, 1000)
                    let from = moment(ko.toJS(this.startRepDeadline), "YYYY-MM-DD").format("DD.MM.YYYY");
                    let to = moment(ko.toJS(this.endRepDeadline), "YYYY-MM-DD").format("DD.MM.YYYY");

                    let element = document.createElement('a');
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(res.Payload.DocumentData);
                    } else {
                        element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + res.Payload.DocumentData);
                        element.setAttribute('download', `${'Fristenübersicht von ' + from + ' bis ' + to}.docx`);

                        element.style.display = 'none';
                        document.body.appendChild(element);

                        element.click();

                        document.body.removeChild(element);
                        (<HTMLButtonElement>document.getElementById("printDailyReportDeadline_btn")).disabled = false;
                        $("#reportDailyDeadlineModal").modal("hide");
                        this.showPrintLoader(false);
                    }

                    $("#reportDeadlineModal").modal("hide");
                    $("#historyDeadlineModal").modal("hide");
                    this.startRepDeadline('');
                    this.endRepDeadline('');
                    this.caseRepDeadline('');
                    this.ReportFilter().DoneOnly(false);
                    this.ReportFilter().CaseID("")
                    this.ReportFilter().Locations([]);
                }
            }
            catch {
                this.showPrintLoader(false);
                this.showParameterMessage(true);
            }
        }
        else
            this.calledfirst(false);
    }

    sbId = ko.observable("");
    showReportError = ko.observable(false);

    async printDeadlineLeadpartner() {
        this.startRepDeadline((<HTMLInputElement>document.getElementById("startRepDeadline")).value);
        this.endRepDeadline((<HTMLInputElement>document.getElementById("endRepDeadline")).value);
        let sb = ko.toJS(this.ProcessPartnerId);
        this.showPrintLoader(true);
        await RNSAPI.reportLeadPartnerDeadline(moment(ko.toJS(this.startRepDeadline), "YYYY-MM-DD"), moment(ko.toJS(this.endRepDeadline), "YYYY-MM-DD"), ko.toJS(sb)).then((data) => {
            let element = document.createElement('a');
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(data);
            } else {
                element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + data);
                element.setAttribute('download', `${'Fristenübersicht von ' + ko.toJS(this.startRepDeadline) + ' bis ' + ko.toJS(this.endRepDeadline)}.docx`);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
                this.ProcessPartner("");
                this.ProcessPartnerId("");
                $('#reportLeadpartnerDeadlineModal').modal('hide');
            }
        }).catch((error) => {
            this.showPrintLoader(false);
            setTimeout(() => {
                this.showReportError(false);
            }, 5000);
            this.showReportError(true);
        });
    }

    async download(name: string, DocumentData: any) {

        let element = document.createElement('a');
        let doc = DocumentData;
        let IsRTF = atob(doc.DocumentData.substr(0, 50)).indexOf("rtf") !== -1;
        let type = IsRTF ? "rtf" : doc.OLE2Type.trim().toLowerCase();

        let blob = Utils.base64ToBlob(doc.DocumentData, 'application/octet-stream');

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
        } else {
            element.setAttribute('href', 'data:application/octet-stream;charset=utf-16le;base64,' + doc.DocumentData);
            element.setAttribute('download', `${name.trim()}.${type}`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }

    async updateDeadlines(allDeadlines: any) {
        try {
            let deadlines = allDeadlines.Payload.Deadlines.map(d => ({ ...d }));
            let deadlinesSorted = deadlines.sort((d1, d2) => {
                if (moment.utc(d1.Deadline).isBefore(moment.utc(d2.Deadline))) {
                    return 1;
                } else {
                    return -1;
                }
            });
            var DeletedItems = 0;
            let transformed = deadlinesSorted.map(obj => {
                let copy = $.extend(true, {}, obj);
                obj.columnActions = {
                    "CaseId": MainViewModel.RoutingTable.generateLink(`/new/${encodeURIComponent(obj.CaseId)}`)
                };
                obj.actionHandlers = [
                    {
                        icon: "pencil-alt", name: "Verlängern", action: async () => {
                            this.deadlineToBeModified(obj);                            
                            $('#extendDeadlineModal').modal('show');
                            this.editPastFrist();
                            this.extendDeadlineDate(ko.toJS(moment(this.deadlineToBeModified().Deadline).format("YYYY-MM-DD")));
                        }
                    },
                    {

                        icon: "user-circle",
                        name: "Delegieren",
                        action: async () => {
                            $('#delegateDeadlineModal').modal('show');
                            $('#newSB').val(ko.toJS(obj.Clerk));
                            var obj1 = {};
                            for (let key in obj1) {
                                obj1[key] = ko.observable(obj[key]);
                            }
                            this.basicDeadline(obj);
                        }
                    },
                    {
                        icon: "check",
                        name: "Abschließen",
                        action: async () => {
                            if (obj.PreDeadline === "Keine Vorfrist") {
                                obj.PreDeadline = "";
                            }
                            obj.CompletionDate = "";
                            obj.CompletionDate2 = "";
                            await RNSAPI.completeDeadline(obj);
                            this.update();
                        }
                    }
                ];

                obj.Deadline = moment(obj.Deadline).format("DD.MM.YYYY");

                obj.PreDeadline = obj.PreDeadline ? moment(obj.PreDeadline).format("DD.MM.YYYY") : "Keine Vorfrist";

                obj.TypeDescription = obj.TypeId + obj.TypeDescription;

                var Today = Number(moment().format("YYYYMMDD"));
                var FristTimestamp = Number(moment(obj.Deadline, "DD.MM.YYYY").format("YYYYMMDD"));

                if (Today < FristTimestamp) {
                    if (obj.PreDeadline !== "Keine Vorfrist") {
                        var PreFrTimestamp = Number(moment(obj.PreDeadline, "DD.MM.YYYY").format("YYYYMMDD"));
                        if (Today < PreFrTimestamp) {
                            DeletedItems++;
                            return 0;
                        }
                        else {
                            obj.color = PreFrTimestamp < Today ? this.DANGER_COLOR : undefined;
                        }
                    }
                    else {
                        DeletedItems++;
                        return 0;
                    }
                }
                else {
                    obj.color = FristTimestamp < Today ? this.DANGER_COLOR : undefined;
                }
                obj.color = undefined;
                return obj;
            });

            this.TermiCount(Number(transformed.length - DeletedItems));
            this.deadlines(transformed);

        } catch (e) {
            console.log("Could not retrieve deadlines.");
            console.log(e);
        }
    }

    dateConverter(date: string) {

        let d = date.split('.');

        return `${d[2]}-${d[1]}-${d[0]}`;
    }

    public async update() {
        let allAppointments;
        let allDeadlines;

        if (ko.toJS(this.currentID) !== '')
            allAppointments = await RNSAPI.getAppointmentsForCase(ko.toJS(this.currentID), moment("2000-01-01", "YYYY-MM-DD").toDate(), moment("2099-12-31", "YYYY-MM-DD").toDate());
        else
            allAppointments = await RNSAPI.getAppointmentsByRange(moment(ko.toJS(this.rStartDate)).toDate(), moment(ko.toJS(this.rEndDate)).add("1", "day").toDate());

        for (let appointment of allAppointments.Payload.Appointments) {
            appointment['AppointmentType'] = (appointment['AppointmentType'] == "B") ? "Besprechungstermin" : "Gerichtstermin";
        }

        if (ko.toJS(this.currentID) !== '') {
            allDeadlines = await RNSAPI.getDeadlinesForCase(ko.toJS(this.currentID));
            let resCase = (await RNSAPI.getExtendedCase(ko.toJS(this.currentID))).Payload;
            this.instancesInCase(resCase.Instances);
        }
        else {
            allDeadlines = await RNSAPI.getDeadlinesByRange(moment(ko.toJS(this.rStartDate)), moment(ko.toJS(this.rEndDate)));
            this.instancesInCase([]);
        }

        this.updateTodos(allAppointments, allDeadlines);
    }

    async deadlinesForLawfirms() {
        let lawfirms = [];
        for (let i = 0; i < this.allStandorte().length; i++) {
            let el = (<HTMLInputElement>document.getElementById(ko.toJS(this.allStandorte()[i].LawfirmID) + "_filter_checkbox")).checked;

            if (el)
                lawfirms.push(this.allStandorte()[i].LawfirmID)
        }
        let res = await RNSAPI.getDedlinesForLawfirms(moment(ko.toJS(this.rStartDate)), moment(ko.toJS(this.rEndDate)), lawfirms)
        return res;
    }

    changedLawfirms(caller: string) {
        for (let i = 0; i < this.allStandorte().length; i++) {
            if (ko.toJS(this.allStandorte()[i].LawfirmID) === caller)
                this.allStandorte()[i].Checked = !(<HTMLInputElement>document.getElementById(caller + "_filter_checkbox")).checked;
        }
        this.update();
    }

    newAkteFrist() {
        let obj = {
            SachbearbeiterId: ko.observable(RNSAPI.User().username),
            Sachbearbeiter: ko.observable(null),
            Clerk: ko.observable(null),
            CaseId: ko.observable(null),
            Rubrum: ko.observable("Kein Rubrum vorhanden."),
            DeadlineNumber: ko.observable("-/-"),
            DeadlineText: ko.observable(null),
            CompletedBy: ko.observable(""),
            CompletionDate: ko.observable(""),
            CreatedBy: ko.observable(""),
            StartDate: ko.observable(moment().format("YYYY-MM-DD")),
            PreDeadline: ko.computed(() => {
                if (this.predeadlineNr().startsWith("w")) {
                    let weeks = this.predeadlineNr()[1] + this.predeadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(weeks, "w");
                    return FristDay.format("YYYY-MM-DD");
                }
                else if (this.predeadlineNr().startsWith("m")) {
                    let months = this.predeadlineNr()[1] + this.predeadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(months, "M");
                    return FristDay.format("YYYY-MM-DD");
                }
                else if (this.predeadlineNr().startsWith("j")) {
                    let year = this.predeadlineNr()[1] + this.predeadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(year, "y");
                    return FristDay.format("YYYY-MM-DD");
                }
                else {
                    return moment().format("YYYY-MM-DD");
                }
            }),
            TheDeadline: ko.computed(() => {
                if (this.deadlineNr().startsWith("w")) {
                    let weeks = this.deadlineNr()[1] + this.deadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(weeks, "w");
                    if (FristDay.day() == 0) {
                        FristDay.add(1, 'day');
                    }
                    else if (FristDay.day() == 6) {
                        FristDay.add(2, 'day');
                    }
                    return FristDay.format("YYYY-MM-DD");
                }
                else if (this.deadlineNr().startsWith("m")) {
                    let months = this.deadlineNr()[1] + this.deadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(months, "M");
                    if (FristDay.day() == 0) {
                        FristDay.add(1, 'day');
                    }
                    else if (FristDay.day() == 6) {
                        FristDay.add(2, 'day');
                    }
                    return FristDay.format("YYYY-MM-DD");
                }
                else if (this.deadlineNr().startsWith("j")) {
                    let year = this.deadlineNr()[1] + this.deadlineNr()[2];
                    let today = moment().format("YYYY-MM-DD");
                    let FristDay = moment(this.akteFrist().StartDate(), "YYYY-MM-DD").add(year, "y");
                    if (FristDay.day() == 0) {
                        FristDay.add(1, 'day');
                    }
                    else if (FristDay.day() == 6) {
                        FristDay.add(2, 'day');
                    }
                    return FristDay.format("YYYY-MM-DD");
                }
                else {
                }
            })
        };
        return ko.observable(obj);
    };

    deadlineNr = ko.observable("");
    predeadlineNr = ko.observable("");
    IsEditMode = ko.observable(false)

    akteFrist = this.newAkteFrist();
    basicDeadline = this.akteFrist;
    IsDeadlineNecessary = ko.observable(true);

    DeadLineNr = ko.observable("");
    deadlineCreationDate = ko.observable(moment().format("YYYY-MM-DD"));
    wiedervorlage = ko.observable(moment().format("YYYY-MM-DD"));
    deadlineDeadlineDate = ko.computed(() => {
        if (this.deadlineNr().startsWith("w")) {
            let weeks = this.deadlineNr()[1] + this.deadlineNr()[2];
            let today = moment().format("YYYY-MM-DD");
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(weeks, "w");
            if (FristDay.day() == 0) {
                FristDay.add(1, 'day');
            }
            else if (FristDay.day() == 6) {
                FristDay.add(2, 'day');
            }
            return FristDay.format("YYYY-MM-DD");
        }
        else if (this.deadlineNr().startsWith("m")) {
            let months = this.deadlineNr()[1] + this.deadlineNr()[2];
            let today = moment().format("YYYY-MM-DD");
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(months, "M");
            if (FristDay.day() == 0) {
                FristDay.add(1, 'day');
            }
            else if (FristDay.day() == 6) {
                FristDay.add(2, 'day');
            }
            return FristDay.format("YYYY-MM-DD");
        }
        else if (this.deadlineNr().startsWith("j")) {
            let year = this.deadlineNr()[1] + this.deadlineNr()[2];
            let today = moment().format("YYYY-MM-DD");
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(year, "y");
            if (FristDay.day() == 0) {
                FristDay.add(1, 'day');
            }
            else if (FristDay.day() == 6) {
                FristDay.add(2, 'day');
            }

            return FristDay.format("YYYY-MM-DD");
        }
        else {
        }
    });
    deadlinePrelineDate = ko.computed(() => {
        if (this.predeadlineNr().startsWith("w")) {
            let weeks = this.predeadlineNr()[1] + this.predeadlineNr()[2];
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(weeks, "w");
            return FristDay.format("YYYY-MM-DD");
        }
        else if (this.predeadlineNr().startsWith("m")) {
            let months = this.predeadlineNr()[1] + this.predeadlineNr()[2];
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(months, "M");
            return FristDay.format("YYYY-MM-DD");
        }
        else if (this.predeadlineNr().startsWith("j")) {
            let year = this.predeadlineNr()[1] + this.predeadlineNr()[2];
            let FristDay = moment(this.deadlineCreationDate(), "YYYY-MM-DD").add(year, "y");
            return FristDay.format("YYYY-MM-DD");
        }
        else {
            return moment(this.deadlineCreationDate(), "YYYY-MM-DD").format("YYYY-MM-DD");
        }
    });
    creationDate = ko.observable(moment().format("YYYY-MM-DD"));
    deadlineDate = ko.computed(() => {
        if (ko.toJS(this.DeadLineNr()) == "") {
            return moment().format("YYYY-MM-DD");
        }
        else {
            let total_days = Number(ko.toJS(this.DeadLineNr())) * 7;
            if (ko.toJS(this.akteResubmission().DeadLineNr) == 'm1') {
                total_days = 30;
            }
            if (!isNaN(total_days)) {
                let potential_date = moment(ko.toJS(this.creationDate()), "YYYY-MM-DD").add(total_days, 'days');
                if (potential_date.day() == 0) {
                    potential_date.subtract(2, 'day');
                }
                else if (potential_date.day() == 6) {
                    potential_date.subtract(1, 'day');
                }
                return (potential_date.format("YYYY-MM-DD"));
            }
        }
    });

    parseDate = (dateStr: string) => moment.utc(dateStr, "DD.MM.YYYY", true);

    newAkteResubmission() {
        let rObj = {
            AdressTyp: ko.observable(""),
            AngelegtVon: ko.observable(""),
            AnlageDatum: ko.computed(() => this.parseDate((moment(this.creationDate(), "YYYY-MM-DD").format("DD.MM.YYYY"))).toISOString()),
            Case_ID: ko.observable(""),
            Comment: ko.observable(""),
            Copies: ko.observable(""),
            DeadLineDate: ko.computed(() => this.parseDate((moment(this.deadlineDate(), "YYYY-MM-DD").format("DD.MM.YYYY"))).toISOString()),
            DeadLineNr: ko.observable(""),
            DeadLineReason: ko.observable(""),
            EDAVerfahren: ko.observable(false),
            ErledigtDatum: ko.observable(null),
            ErledigtVon: ko.observable(""),
            ForderungskontoDrucken: ko.observable(false),
            Formulardruk: ko.observable(false),
            HonorarForderung: ko.observable(false),
            Keyword: ko.observable(""),
            PapierArtID: ko.observable(""),
            Quotelung: ko.observable(0),
            Referat: ko.observable(""),
            Sachbearbeiter: ko.observable(RNSAPI.User() ? RNSAPI.User().username : "GR"),
            Standardtext: ko.observable(""),
            Status: ko.observable("N"),
        };

        return ko.observable(rObj);
    };

    akteResubmission = this.newAkteResubmission();
    akteExtendResubmission: ko.Observable<any> = ko.observable();

    async extendResubmission() {
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        this.akteExtendResubmission()['DeadLineDate'] = ko.observable($('#wiedervorlage').val());
        this.akteExtendResubmission()['Status'] = ko.observable('L');
        try {
            let result = await RNSAPI.updateResubmission(ko.toJS(this.akteExtendResubmission()));
            if (result.Type === "EditSuccess") {
                $('#extendResubmissionModal').modal('hide');
                this.update();
            } else {
                alert("Fehler beim Speichern.");
            }
        } catch (e) {
            console.log(e);
            alert("Fehler beim Speichern: " + e.responseText);
        }
    }

    async approvePastDeadline() {
        $("#DeadlineExtension_Body").removeClass("hide");
        $("#DeadlineExtension_Body").addClass("show");
        $("#approvePastExtensionDeadlineBody").removeClass("show");
        $("#approvePastExtensionDeadlineBody").addClass("hide");
        this.pastFristApproved(true);
        this.extendDeadline();
    }
    async editPastFrist() {
        this.pastFristApproved(false);
        $("#DeadlineExtension_Body").removeClass("hide");
        $("#DeadlineExtension_Body").addClass("show");
        $("#approvePastExtensionDeadlineBody").removeClass("show");
        $("#approvePastExtensionDeadlineBody").addClass("hide");
    }

    async verifyDeadline(){
        if(ko.toJS(this.extendDeadlineDate()) !== ko.toJS(this.verifyDeadlineDate()))
        {
            this.notifyVerifyDeadline(true);
        }
        else{
            $("#DeadlineExtension_Body").removeClass("hide");
            $("#DeadlineExtension_Body").addClass("show");
            $("#verifyExtensionDeadlineBody").removeClass("show");
            $("#verifyExtensionDeadlineBody").addClass("hide");
            this.extendDeadline();
        }
    }

    async checkDeadlineExtension(){
        $("#DeadlineExtension_Body").removeClass("show");
        $("#DeadlineExtension_Body").addClass("hide");
        $("#verifyExtensionDeadlineBody").removeClass("hide");
        $("#verifyExtensionDeadlineBody").addClass("show");
    }

    async cancelVerifyFrist(){
        this.verifyDeadlineDate("YYYY-MM-DD");
        this.notifyVerifyDeadline(false);
        $("#DeadlineExtension_Body").removeClass("hide");
        $("#DeadlineExtension_Body").addClass("show");
        $("#verifyExtensionDeadlineBody").removeClass("show");
        $("#verifyExtensionDeadlineBody").addClass("hide");
    }

    async extendDeadline() {
        let deadlineObj = ko.toJS(this.deadlineToBeModified);
        let now = Number(moment(moment.now()).format("YYYYMMDD"));
        let deadlineBeforeToday = Number(moment(moment.now()).format("YYYYMMDD")) > Number(moment(ko.toJS(this.extendDeadlineDate())).format("YYYYMMDD"));
        let predeadBeforeToday =  now > Number(moment(ko.toJS(this.extendPreDeadlineDate())).format("YYYYMMDD"));
        
        if ((deadlineBeforeToday || predeadBeforeToday)  && ko.toJS(this.pastFristApproved()) === false) {
            $("#DeadlineExtension_Body").removeClass("show");
            $("#DeadlineExtension_Body").addClass("hide");
            $("#approvePastExtensionDeadlineBody").removeClass("hide");
            $("#approvePastExtensionDeadlineBody").addClass("show");
            return;
        }
        else {
            this.pastFristApproved(false);
        }

        let res = "";
        try {
            if (ko.toJS(this.allExtendsionReasonsLength) !== 0) {
                res = (<HTMLSelectElement>document.getElementById("DeadlineExtensionSelect")).value
            }
            (<HTMLButtonElement>document.getElementById("postExtension")).disabled = true;
            let Export = false;
            if (this.fKAId() !== '')
                Export = (<HTMLInputElement>document.getElementById('notifyExtention')).checked;
            this.showOutlookLoader(true);

            await RNSAPI.extendDeadline(deadlineObj, ko.toJS(this.extendDeadlineDate()), ko.toJS(this.extendPreDeadlineDate()), ko.toJS(this.extendDeadlineNote()), ko.toJS(this.extendDeadlineClerk()), Export, res).then((response) => {
                let result = response as any;
                RNSAPI.createHistoryEntry(deadlineObj.CaseId, "Frist angelegt");
                if (Export) {
                    this.showOutlookLoader(false);
                    if (result.OutlookExport === "SUCCESS") {
                        this.showError(false);
                        this.showSuccess(true);
                    }
                    else if (result.OutlookExport === "FAILURE") {
                        this.showSuccess(false);
                        this.showError(true);
                    }
                    else {
                        this.showError(false);
                        this.showSuccess(false);
                    }

                    if (result.ExternalExport === "SUCCESS") {
                        this.showExternError(false);
                        this.showExternSuccess(true);
                    }
                    else if (result.ExternalExport === "FAILURE") {
                        this.showExternSuccess(false);
                        this.showExternError(true);
                    }
                    else {
                        this.showExternError(false);
                        this.showExternSuccess(false);
                    }
                }

                setTimeout(() => {
                    this.showError(false);
                    this.showExternError(false);
                    this.showSuccess(false);
                    this.showExternSuccess(false);
                    this.update();
                    $('#extendDeadlineModal').modal('hide');
                    (<HTMLButtonElement>document.getElementById("postExtension")).disabled = false;
                }, 1000);

            }).catch((error) => {
                (<HTMLButtonElement>document.getElementById("postExtension")).disabled = false;
                alert("Fehler beim Speichern.");
            });
        } catch (e) {
            (<HTMLButtonElement>document.getElementById("postExtension")).disabled = false;
            this.showOutlookLoader(false);
            this.showError(false);
            alert("Fehler beim ..");
            console.log(e);
        }
    }


    async delegateResubmission() {
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        this.akteExtendResubmission()['Sachbearbeiter'] = ko.observable($('#delegate').val());
        try {
            let result = await RNSAPI.updateResubmission(ko.toJS(this.akteExtendResubmission()));
            if (result.Type == "EditSuccess") {
                $('#delegateResubmissionModal').modal('hide');
                this.update();
            } else {
                alert("Fehler beim Speichern.");
            }
        } catch (e) {
            $('#delegateResubmissionModal').modal('hide');
        }
    }

    async delegateDeadline() {
        $(".form-group").each(function () { $(this).removeClass("has-error"); });
        let delegateobj = ko.toJS(this.deadlineToBeModified());
        let keys = ['Id', 'AddressTypeId', 'CaseId', 'Clerk', 'CompletedBy', 'CompletionDate', 'CompletionDate2', 'CreatedBy', 'CreationDate', 'Deadline', 'EditedAt', 'EditedFromId', 'ExportToOutlook', 'IMEX', 'IsDoubleCheck', 'IsPasswordProtected', 'NotaryOrLawyer', 'Note', 'Note1', 'OutlookMessageId', 'PreDeadline', 'PrDeadline2', 'ProcessTime', 'RecordId', 'ReplacedWith', 'ReviewAt', 'Rubrum', 'StartDate', 'TypeDescription', 'TypeId'];
        let obj = {};
        for (let key in keys) {
            obj[keys[key]] = delegateobj[keys[key]];
        }
        try {
            (<HTMLButtonElement>document.getElementById("postDelegation")).disabled = true;
            let result = await RNSAPI.delegateDeadline(ko.toJS(obj), ko.toJS(this.newSB));
            if (result.Type == "DelegationSuccessful") {
                $('#delegateDeadlineModal').modal('hide');
                this.update();

            } else {
                alert("Fehler beim Speichern.");
            }
        } catch (e) {
            console.log(e);
            console.log("here is the problem: " + e.responseText);
        }

    }

    async getFKA(caseId) {
        let AccountId = await this.getFristenKalenderForCaseId(caseId);
        let res = (await RNSAPI.getOutlook()).Payload.OutlookAccounts;
        if (res.length > 0) {
            let account = "";
            let accountId = "";
            for (let acc of res) {
                if (acc.OutlookAccountID === AccountId.FKAId) {
                    account = acc.UserLogin;
                    accountId = acc.OutlookAccountID;
                }
            }
            this.fkAName(account);
            this.fKAId(accountId);
        }
    }

    async getFristenKalenderForCaseId(caseId): Promise<any> {
        let result = { FKA: "", FKAId: "" };
        if (caseId !== "" && caseId !== null) {
            let res = (await RNSAPI.getExtendedCase(caseId)).Payload;
            result.FKA = res.Akte.DeadlineCalendarAccount;
            result.FKAId = res.Akte.DeadlineCalendarAccountId;
            this.attendees(res.Akte.WeitereSachbearbeiterRecords);
        }
        return result;
    }

    async getOutlookAccounts() {
        let res = (await RNSAPI.getOutlook()).Payload.OutlookAccounts;
        this.OutlookAccounts(res);
    }

    times = [];

    startDate = ko.observable(moment().format("YYYY-MM-DD"));
    startTime = ko.observable("00:00");

    endDate = ko.observable(moment().format("YYYY-MM-DD"));
    endTime = ko.observable("00:00");

    async showextExtensionReasons() {
        this.pickGeneric("Verlängerungsgrund", ["Reason"], ['Verlängerungsgrund'], ko.toJS(this.allDeadlineExtensionReasons));
        this.modalHandleSelection((selectedObject) => {
            this.extendDeadlineReason(selectedObject()["Reason"])
        });
        $('#modal').modal('show');
    }

    async pickCase5() {
        let cases = [];
        await RNSAPI.getCasesOverview(0, ko.toJS(20)).then((data) => {
            cases = data as any;
        }).catch((error) => {
            cases = [];
        });

        this.pickGeneric("Akte", ["caseid", "rubrum", "court_caseid_1", "refId"], ["Akte", "Rubrum", "AZ-Gericht 1. Instanz", "Standort"], cases);
        this.modalHandleSelection((selectedObject) => {
            this.currentRubrum(selectedObject()["rubrum"]);
            this.filter(selectedObject()["caseid"])
        });
        $('#modal').modal('show');
    };

    async pickClerk5() {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Fristenkontrolle wählen", ["Sachbearbeiter"], ["Fristenkontrolle"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            this.sachbearbeiter(selectedObject()["Sachbearbeiter"])
            this.sbId(selectedObject()["Sachbearbeiter_ID"])
        });
        $('#modal').modal('show');
    };

    async pickClerkInstance(position) {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Fristenkontrolle wählen", ["Sachbearbeiter"], ["Fristenkontrolle"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            if (position === "DeadlineExtension") {
                this.extendDeadlineClerk(selectedObject()["Sachbearbeiter_ID"]);
            }
            if (position === "ProcessPartner") {

                this.ProcessPartner(selectedObject()["Sachbearbeiter"]);
                this.ProcessPartnerId(selectedObject()["Sachbearbeiter_ID"])
            }
            if (position === "Fristenkontrolle") {

                this.Fristenkontrolle(selectedObject()["Sachbearbeiter"]);
                this.FristenkontrolleId(selectedObject()["Sachbearbeiter_ID"])
            }
        });
        $('#modal').modal('show');
    };

    pickSachbearbeiter1 = async () => {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => this.newSB1(selectedObject()["Sachbearbeiter_ID"]));
        $('#modal').modal('show');
    };
    pickclerk = async () => {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => this.newSB(selectedObject()["Sachbearbeiter_ID"]));
        $('#modal').modal('show');
    };
    pickSachbearbeiter = async () => {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Sachbearbeiter", ["Sachbearbeiter"], ["Sachbearbeiter"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            this.akteFrist().SachbearbeiterId(selectedObject()["Sachbearbeiter_ID"]);
            this.akteFrist().Sachbearbeiter(selectedObject()["Sachbearbeiter_ID"]);
            this.akteResubmission().Sachbearbeiter(selectedObject()["Sachbearbeiter_ID"])
        });
        $('#modal').modal('show');
    };

    async pickCase() {
        let cases = [];
        await RNSAPI.getCasesOverview(0, ko.toJS(20)).then((data) => {
            cases = data as any;
        }).catch((error) => {
            cases = [];
        });
        this.pickGeneric("Akte", ["caseid", "rubrum", "court_caseid_1", "refId"], ["Akte", "Rubrum", "AZ-Gericht 1. Instanz", "Standort"], cases);
        this.modalHandleSelection((selectedObject) => {
            this.akteFrist().CaseId(selectedObject()["caseid"]);
            this.akteFrist().Rubrum(selectedObject()["rubrum"])
            this.akteResubmission().Case_ID(selectedObject()["caseid"]);
            this.Rubrum(selectedObject()["rubrum"])
            this.caseRepDeadline(selectedObject()["caseid"])
        });
        $('#modal').modal('show');
    };

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();
    TermiCount = ko.observable(0);

    akte = ko.observable({
        Registernummer: ko.observable(null),
        Referat: ko.observable(null),
        SachbearbeiterId: ko.observable(null),
        Rubrum: ko.observable(null),
        Wegen: ko.observable(null),
        Sachstand: ko.observable(null),
        AnlageDatum: ko.observable(moment().format("DD.MM.YYYY")),
        Note1: ko.observable(null),
        Wiedervorlage: ko.observable(""),
        WertGericht1: ko.observable(0),
        IsSelected: ko.observable(true),
        delegate: ko.observable(""),
        delegateapp: ko.observable("")
    });

    async getFristDeadlineReasons() {
        let res = (await RNSAPI.getFristDeadlineReasons());
        if (res.Type === "GetFristExtensionReasonsSuccessful") {
            this.allDeadlineExtensionReasons([]);
            this.allDeadlineExtensionReasons(res.Payload.DeadlineExtendReasonTypes.map((deadlineExtenstionRea) => {
                return deadlineExtenstionRea;
            }));
            this.allExtendsionReasonsLength(this.allDeadlineExtensionReasons().length)
        }
    }

    async getReferate() {
        let referate = (await RNSAPI.getReferate()).Payload.Units;
        this.hasReferatCount(referate.length);
        let ref = referate.map((r) => {
            r.Checked = false;
            return r;
        });
        this.AllReferate(ref);
    }

    initTooltip() {
        $("[data-toggle='tooltip']").tooltip();
    }

    async getLoggedInUserForTechnicalRights(){
        let currentSB = (await RNSAPI.getLawFirmUser(RNSAPI.getCurrentLoggedInUser().ShortHandSymbol)).Payload.User;
        this.loggedInUserTechnicalRights(currentSB.externalSWRights);
    }

    constructor(params: any) {
        moment.locale("de");
        this.hasButton(true);
        if (params.id !== undefined) {
            this.currentID(ko.toJS(params.id));
            this.filter(ko.toJS(params.id));
            this.hasButton(false);
            Postbox.publisher().publish("", "checkReferate");
        }
        else {
            this.rStartDate(ko.toJS(moment().format("YYYY-MM-DD")));
            this.rEndDate(ko.toJS(moment().format("YYYY-MM-DD")));
        }

        this.getLoggedInUserForTechnicalRights();

        this.getFristDeadlineReasons();

        this.getAllUsers();

        this.update();
        this.endOfDay.subscribe(() => this.update());

        this.startOfThisMonth = moment.utc().startOf("month");
        this.startOfNextMonth = moment.utc().startOf("month").add(1, "month");

        Postbox.publisher().subscribe(() => {
            this.update();
        }, 'updateTFW')

        this.filteredTodos = ko.computed({
            owner: this,
            read: () => {
                let filter = this.filter()
                let sachbearbeiter = this.sachbearbeiter()
                let range = this.range()
                let filters = []

                if (this.values().complete()) {
                    filters.push('complete')
                } else {
                    filters.indexOf('complete') !== -1 ? filters.splice(filters.indexOf('complete'), 1) : null
                }

                if (this.values().green()) {
                    filters.push('green')
                } else {
                    filters.indexOf('green') !== -1 ? filters.splice(filters.indexOf('green'), 1) : null
                }

                if (this.values().yellow()) {
                    filters.push('yellow')
                } else {
                    filters.indexOf('yellow') !== -1 ? filters.splice(filters.indexOf('yellow'), 1) : null
                }
                if (this.values().red()) {
                    filters.push('red')
                } else {
                    filters.indexOf('red') !== -1 ? filters.splice(filters.indexOf('red'), 1) : null
                }

                const filterTypes = Object.keys(this.types()).filter(item => this.types()[item]())

                let items = this.todos();

                if (ko.toJS(this.selectedFilterStandort()) !== "")
                    items = items.filter(item => this.filterWithStandort(item))

                if (ko.toJS(this.selectedFilterAccount()) !== "")
                    items = items.filter(item => this.filterWithFKA(item))
                if (ko.toJS(this.selectedInstance()) !== "")
                    items = items.filter(item => this.filterWithInstance(item))

                if (filters.length) {
                    items = items.filter(item => this.filterWithStars(item, filters))
                }
                if (filterTypes.length) {
                    items = items.filter(item => this.filterWithType(item, filterTypes))
                }
                if (filter) {
                    items = items.filter(item => item['number'] && item['number'].indexOf(filter) !== -1)
                }
                if (sachbearbeiter) {
                    items = items.filter(item => item['SB'] && item['SB'].includes(sachbearbeiter))
                }

                if (ko.toJS(this.currentID) !== '' && ko.toJS(this.currentID) !== "undefined") {
                    this.getNextWV(items);

                }
                return items
            }
        });

        this.rStartDate.subscribe(() => this.update());
        this.rEndDate.subscribe(() => this.update());

        Postbox.publisher().subscribe((start: string, end: string) => {
            this.startRepDeadline(start);
            this.endRepDeadline(end);
        }, 'UpdateRepDeadlines');

        this.oldDeadline = params;
        this.updateTooltip()

        this.getReferate();

        this.getOutlookAccounts();
    }
}


let nameTable: { [key: string]: string; } = {
    "appointment": "Termin",
    "deadline": "Frist",
    "predeadline": "Vorfrist",
    "inbox": "Posteingang",
    "resubmission": "Wiedervorlage",
    "ecase": "EAkte"
};

var html = fs.readFileSync(__dirname + '/tfw.html', 'utf8');

ko.components.register("tfw-view", {
    viewModel: TfwViewModel,
    template: html
});
