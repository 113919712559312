import '../node_modules/jquery/dist/jquery.js';
import '../node_modules/jwt-decode/build/jwt-decode.min.js';
import * as ko from 'knockout';
import Navigo = require("navigo");
import './components/login/login';
import './components/extended/new';
import './components/case/easycase';
import './components/case/overview';
import './components/address/overview';
import './components/address/address_form';
import './components/tfw/tfw';
import './components/tfw/protocol';
import './components/settings/overview';
import './components/register/register';
import './components/layout/layout';
import './components/layout/header';
import { RNSAPI, Routing } from './api';
import { User } from './UserData';
import { Utils } from './utils';
import * as $ from 'jquery';
window["$"] = $;
window["jQuery"] = $;

export class MainViewModel {
    public currentRoute = ko.observable("login-view")
    public showSideNav = ko.observable(false)
    public currentParams = ko.observable(undefined)
    public showLogout = ko.observable(false);

    public logout() {
        MainViewModel.RoutingTable.showLoginView();
        vm.showLogout(false);
    }

    public router = new Navigo(null, true);
    public static paused = false;
    cookieExist = Utils.readCookie("access_token");

    constructor() {
        this.router.on(
            '/login', () => {
                this.currentRoute("login-view")
                this.showSideNav(false);
            },
            {
                before: (done, params) => { MainViewModel.disallowView(done) }
            }
        )

        this.router.on('/register', () => {
            this.currentRoute("register-view")
            this.showSideNav(false);
        },
            {
                before: (done, params) => { MainViewModel.disallowView(done) }
            }
        )

        this.router.on(
            '/', () => this.router.navigate('/dashboard'), {
            before: (done, params) => {
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/cases', () => this.currentRoute("caseoverview-view"), {
            before: (done, params) => {
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/cases/:all', (params) => {
                this.currentRoute("caseoverview-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/new/:caseId', (params) => {
                this.currentRoute('new-view');
                this.currentParams(params);
            }, {
            before: (done, params) => {
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/new_modal', () => (params) => {
                this.currentRoute("new_modal-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                MainViewModel.allowView(done)
            }
        }
        )

        this.router.on(
            '/easycase', () => this.currentRoute("easycase-view"), {
            before: (done, params) => {
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/easycase/:caseId', (params) => {
                this.currentRoute('easycase-view');
                this.currentParams(params);
            }, {
            before: (done, params) => {
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/addresses', () => this.currentRoute("addressoverview-view"), {
            before: (done, params) => {
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/address_form/:id', (params) => {
                this.currentRoute("address_form-view");
                this.currentParams(params);
            }, {
            before: (done, params) => {
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/settings',
            () => {
                this.currentRoute("settings-view");
                this.currentParams({ view: "showUsers" });
            }, {
            before: (done, params) => {
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )

        this.router.on(
            '/settings/:view', (params) => {
                if (!MainViewModel.paused) {
                    this.currentRoute("settings-view");
                    this.currentParams(params);
                }
                MainViewModel.paused = false;
            }, {
            before: (done, params) => {
                MainViewModel.allowView(done, params)
                this.showSideNav(true);
            }
        }
        )
        this.router.on(
            '/tfw',
            () => {
                this.currentRoute("tfw-view");
                this.currentParams({ view: "showTfw" });
            }, {
            before: (done, params) => {
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )
        this.router.on(
            '/protocol',
            () => {
                this.currentRoute("protocol-view");
            }, {
            before: (done, params) => {
                MainViewModel.allowView(done)
                this.showSideNav(true);
            }
        }
        )
        this.router.on(
            '/tfw/:view', (params) => {
                if (!MainViewModel.paused) {
                    this.currentRoute("tfw-view");
                    this.currentParams(params);
                }
                MainViewModel.paused = false;
            }, {
            before: (done, params) => {
                MainViewModel.allowView(done, params)
                this.showSideNav(true);
            }
        }
        )

        this.router.notFound((query) => {
            console.log('Show 404 page', query)
            this.router.navigate('/tfw')
        });

        this.router.resolve()

        let cookie = Utils.readCookie("access_token");
        if (cookie === null) {
            MainViewModel.RoutingTable.showLoginView();
        } else {
            RNSAPI.Token = cookie;
            let decoded = (window as any).jwt_decode(RNSAPI.Token);
            RNSAPI.User(new User(decoded.ShortHandSymbol, decoded.Role));
        }

    }

    public static allowView(cb, params?) {
        let cookie = Utils.readCookie("access_token");
        if (cookie === null) {
            MainViewModel.RoutingTable.showLoginView();
            cb(false)
        } else {
            cb(true)
        }
    }

    public static disallowView(cb, params?) {

        let cookie = Utils.readCookie("access_token");
        if (cookie === null) {
            cb(true)
        } else {
            MainViewModel.RoutingTable.showTfwView()
            cb(false)
        }
    }

    public static RoutingTable = {
        generateLink: function (route: string) {
            return vm.router.link('').indexOf('#') !== -1 ? vm.router.link(route) : vm.router.link('#' + route)
        },
        showEasyCaseView: function (params?: any) {
            if (params) vm.router.navigate(`/easycase/${encodeURIComponent(params.caseId)}`);
            else vm.router.navigate("/easycase");
        },
        showCaseOverview: function () { vm.router.navigate("/cases") },
        showSettingsView: function (params?: any, ignore = false) {
            if (ignore) MainViewModel.paused = true;
            if (params) {
                vm.router.navigate(`/settings/${params.view}`);
            }
            else vm.router.navigate("/settings");
        },
        showTfwView: function (params?: any, ignore = false) {
            if (ignore) MainViewModel.paused = true;
            if (params) {
                vm.router.navigate(`/tfw/${params.view}`);
            }
            else vm.router.navigate("/tfw");
        },
        showDashboardView: function (params?: any, ignore = false) {
            if (ignore) MainViewModel.paused = true;
            if (params && params.viewType) {
                vm.router.navigate(`/dashboard/${params.viewType}`);
            }
            if (params) {
                vm.router.navigate(`/dashboard/${params.view}`);
            }
            else vm.router.navigate("/dashboard");
        },
        showAddressView: function (params?: any) {
            if (params) vm.router.navigate(`/address/${params.type}/${encodeURIComponent(params.keyword)}`);
            else vm.router.navigate("/address");
        },
        showAddressOverview: function () { vm.router.navigate("/addresses"); },
        showLoginView: () => { window.location.href = "./#/login";},
        showRegisterView: function () {vm.router.navigate(`/register`)},
        showNewView: function (params?: any) {
            if (params) {
                vm.router.navigate(`/new/${encodeURIComponent(params.caseId)}`);
            }
            else vm.router.navigate("/dashboard");
        },
        setOrigin: function (origin: string) { RNSAPI.origin = origin; },
        showSettings: () => vm.router.navigate(`/settings`)
    }
}

let vm = new MainViewModel();

ko.applyBindings(vm);

module.exports = MainViewModel.RoutingTable;
