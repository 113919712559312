import * as fs from "fs";
import * as ko from "knockout";
import * as moment from "moment";
import { RNSAPI } from "../../api";
import { Postbox } from '../postbox/postbox'
import './dialog';
import './message';

export class resubmissionDialogViewModel {

    parseDate = (dateStr: string) => moment.utc(dateStr, "DD.MM.YYYY", true);
    creationDate = ko.observable(moment().format("YYYY-MM-DD"));
    DeadLineNr = ko.observable("");
    DeadLineElapseTime = ko.observable("");
    Rubrum = ko.observable("Kein Rubrum vorhanden.");
    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();
    fixCase = ko.observable(false);
    givenId = "NewResubmissionDialogExt";

    akteResubmission = this.newAkteResubmission();

    deadLineDateValue = ko.observable("");


    private formatStandardDeadlineDate(): string {
        if(!this.DeadLineElapseTime()) {
            return moment().format("YYYY-MM-DD");
        }
        let potentialDate = moment(ko.toJS(this.creationDate()), "YYYY-MM-DD"); 
        potentialDate.add(Number(this.DeadLineElapseTime()), 'w');
        return potentialDate.format("YYYY-MM-DD");
    }


    newAkteResubmission() {
        let rObj = {
            AdressTyp: ko.observable(""),
            AngelegtVon: ko.observable(""),
            AnlageDatum: ko.computed(() =>
                this.parseDate(
                    moment(this.creationDate(), "YYYY-MM-DD").format("DD.MM.YYYY")
                ).toISOString()
            ),
            Case_ID: ko.observable(""),
            Comment: ko.observable(""),
            Copies: ko.observable(""),
            DeadLineDate: ko.computed(() => this.parseDate(moment(ko.toJS(this.deadLineDateValue), "YYYY-MM-DD").format("DD.MM.YYYY")).toISOString()),
            DeadLineNr: ko.observable(""),
            DeadLineReason: ko.observable(""),
            EDAVerfahren: ko.observable(false),
            ErledigtDatum: ko.observable(null),
            ErledigtVon: ko.observable(""),
            ForderungskontoDrucken: ko.observable(false),
            Formulardruk: ko.observable(false),
            HonorarForderung: ko.observable(false),
            Keyword: ko.observable(""),
            PapierArtID: ko.observable(""),
            Quotelung: ko.observable(0),
            Referat: ko.observable(""),
            Sachbearbeiter: ko.observable(
                RNSAPI.User() ? RNSAPI.User().username : "GR"
            ),
            Standardtext: ko.observable(""),
            Status: ko.observable("N"),
        };

        return ko.observable(rObj);
    }

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    }

    async postResubmissionData() {
        if (ko.toJS(this.akteResubmission().Case_ID) === '') {
            $("#caseInput_div").addClass("has-error");
            return;
        }
        else {
            $("#caseInput_div").removeClass("has-error");
        }
        if (ko.toJS(this.akteResubmission().DeadLineNr) === "") {
            $("#reasonInput_div").addClass("has-error");
            return;
        }
        else {
            $("#reasonInput_div").removeClass("has-error");
        }
        let resubmissionObj = ko.toJS(this.akteResubmission());
        resubmissionObj["DeadLineDate"] = moment(ko.toJS(this.deadLineDateValue)).format("YYYY-MM-DD");
        try {
            let result = await RNSAPI.createResubmission(resubmissionObj);
            if (result.Type === "CreateSuccess") {
                this.akteResubmission().Case_ID("");
                this.akteResubmission().Sachbearbeiter("");
                this.akteResubmission().DeadLineNr("");
                this.creationDate(moment().format("YYYY-MM-DD"));
                RNSAPI.createHistoryEntry(resubmissionObj.Case_ID, "Wiedervorlage angelegt");
                $("#NewResubmissionDialogExt").modal("hide");
                $("#CaseNewResubmissionDialogExt").modal("hide");
            } else {
                alert("Fehler beim Anlegen der Wiedervorlage.");
            }
        } catch (e) {
            console.log(e);
            alert("Fehler beim Anlegen : " + e.responseText);
        }

        if (document.location.href.includes('dashboard'))
            Postbox.publisher().publish("", "updateDashboard")
        else if (document.location.href.includes('tfw'))
            Postbox.publisher().publish("", "updateTFW")

    }

    async postResubmission() {
        this.postResubmissionData();
    }

    async pickCase() {
        let cases = (await RNSAPI.getCases()).Payload.Cases;
        this.pickGeneric(
            "Akte",
            ["Registernummer", "Rubrum", "Wegen"],
            ["Akte", "Rubrum", "Wegen"],
            cases
        );
        this.modalHandleSelection((selectedObject) => {
            this.akteResubmission().Case_ID(selectedObject()["Registernummer"]);
            this.Rubrum(selectedObject()["Rubrum"]);
        });
        $("#Resubmodal").modal("show");
    }

    pickSachbearbeiter = async () => {
        let sachbearbeiter = (
            await RNSAPI.getSachbearbeiter()
        ).Payload.Clerks.filter((s) => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric(
            "Sachbearbeiter",
            ["Sachbearbeiter"],
            ["Sachbearbeiter"],
            sachbearbeiter
        );
        this.modalHandleSelection((selectedObject) => {
            this.akteResubmission().Sachbearbeiter(
                selectedObject()["Sachbearbeiter_ID"]
            );
        });
        $("#Resubmodal").modal("show");
    };

    private calculateResubmissionDate(elapseTime: string): string {
        const timeIndication = elapseTime[0];
        const timeDuration = elapseTime[1];

        let potentialDate = moment(ko.toJS(this.creationDate()), "YYYY-MM-DD"); 
        switch (timeIndication) {
            case 'D':
                potentialDate.add(Number(timeDuration), 'd');
                break;
            case 'W':
                potentialDate.add(Number(timeDuration), 'w');
                break;
            case 'M':
                potentialDate.add(Number(timeDuration), 'M');
                break;
            case 'Y':
                potentialDate.add(Number(timeDuration), 'y');
                break;
        }
        return potentialDate.format("YYYY-MM-DD");
    }

    async pickDeadlineNumberResubmission() {
        let reasons = (await RNSAPI.getResubmissionReasons()).Payload.Reasons;
        this.pickGeneric("Grund", ["DeadLineName"], ["Beschreibung"], reasons);
        let isInDuration: boolean = false;
        this.modalHandleSelection((selectedObject) => {
            const durations = ['D', 'W', 'M', 'Y'];
            this.akteResubmission().DeadLineReason(selectedObject().DeadLineName);
            for (let d of durations) {
                if (selectedObject().DeadLineElapseTime.includes(d)) {
                    isInDuration = true;
                    const calculatedDate = this.calculateResubmissionDate(selectedObject().DeadLineElapseTime);
                    this.akteResubmission().DeadLineNr(selectedObject().DeadLineNr);
                }
            }

            if (selectedObject().DeadLineNr && !isInDuration) {
                this.DeadLineNr(selectedObject().DeadLineNr);
                this.akteResubmission().DeadLineNr(selectedObject().DeadLineNr);
                this.DeadLineElapseTime(selectedObject().DeadLineElapseTime);
                this.calcResubmission();
            }
        });
        $("#Resubmodal").modal("show");
    }

    async getRubrumFromCase() {
        this.Rubrum((await RNSAPI.getCaseByCaseId(ko.toJS(this.akteResubmission().Case_ID))).Payload.Case.Rubrum);
    }

   private clearResubmissionForm(): void {
       this.Rubrum("");
       this.akteResubmission().DeadLineReason("");
       this.deadLineDateValue("");
       this.akteResubmission().Case_ID("");
       this.akteResubmission().Sachbearbeiter(RNSAPI.User() ? RNSAPI.User().username : "GR");
       this.akteResubmission().DeadLineNr("");
    }

    async calcResubmission() {
        if (ko.toJS(this.akteResubmission().DeadLineNr) !== "") {
            let res = (await RNSAPI.calculateResubmission({
                deadlineNumber: ko.toJS(this.akteResubmission().DeadLineNr),
                StartDate: moment(ko.toJS(this.creationDate))
            })).Payload;
            if (res.Deadline !== null)
                this.deadLineDateValue(moment(res.Deadline).format("YYYY-MM-DD"));
        }
    }
    
    constructor(params: any) {
        if (params.Case) {
            this.akteResubmission().Case_ID(params.Case);
            this.getRubrumFromCase();
        } 

        if (params.prefix) {
            this.givenId = params.prefix + this.givenId;
        }

        $(window).on('shown.bs.modal', function() { 
            if($("#NewResubmissionDialogExt").attr("caseid")) {
                $("#CaseIDResubmissionInput").val($("#NewResubmissionDialogExt").attr("caseid")).trigger('change');
            } else {
                $("#CaseIDResubmissionInput").trigger("change");
            }
        });

        this.creationDate.subscribe(() => this.calcResubmission())

        document.getElementById("CaseIDResubmissionInput").onchange = (event) => {
            let inputCase = (<HTMLInputElement>event.target).value;
            if(this.akteResubmission().Case_ID() !== inputCase) {
                this.akteResubmission().Case_ID(inputCase);
                this.getRubrumFromCase();
            } else {
                if(inputCase === "") {
                    this.clearResubmissionForm();
                }
            }
        };
    }
}

let html = fs.readFileSync(__dirname + "/resubmissionDialog.html", "utf8");

ko.components.register("resubmission-dialog-view", {
  viewModel: resubmissionDialogViewModel,
  template: html,
});