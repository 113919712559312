import * as ko from "knockout";
import { RNSAPI } from "../../api";
import '../extended/new';
import "moment/locale/de";
import * as fs from 'fs';
import * as moment from "moment"
import { MainViewModel } from "../../main";

export class ProtocolViewModel {
    filteredTodos: ko.Computed<any>;
    filter = ko.observable("");
    filterType(type: string) {
        if (type == 'frist') {
            this.types().Frist(this.checkDeadline())
        }
        else if (type == 'termin') {
            this.types().Termin(this.checkApp())
        }
        this.filter(this.filter());
    }
    checkApp = ko.observable(true);
    checkDeadline = ko.observable(true);
    sachbearbeiter = ko.observable("");
    values = ko.observable({
        complete: ko.observable(false),
        green: ko.observable(true),
        yellow: ko.observable(true),
        red: ko.observable(true),
        server: ko.observable(true),
        stopped: ko.observable(true)
    });
    types = ko.observable({
        Frist: ko.observable(true),
        Termin: ko.observable(true)
    });
    AllReferate = ko.observable();
    OutlookAccounts = ko.observableArray([]);
    showTermine = ko.observable(false);
    showFristen = ko.observable(false);
    todos = ko.observableArray([]);
    selectedFilterStandort = ko.observable("");
    selectedFilterAccount = ko.observable("");
    rStartDate = ko.observable("");
    rEndDate = ko.observable("");
    lastSyncDate = ko.observable("");
    lastSyncTime = ko.observable("");
    selectedStatus = ko.observable(0);
    syncDeadline = ko.observable(true);
    outlookError = ko.observable(false);
    externalError = ko.observable(false);
    outlookExport = ko.observable(false);
    externalExport = ko.observable(false);
    outlookPending = ko.observable(false);
    externalPending = ko.observable(false);
    transmisionMethod = ko.observable("");

    modalTitle = ko.observable("");
    modalKeys = ko.observableArray([]);
    modalColumns = ko.observableArray([]);
    modalData = ko.observableArray([]);
    modalHandleSelection = ko.observable();

    async pickGeneric(title, keys, columns, data) {
        this.modalTitle(title);
        this.modalKeys(keys);
        this.modalColumns(columns);
        this.modalData(data);
    };

    filterWithStars(item, filters) {
        if (item['stars'] && filters.indexOf(item['stars']) !== -1) return true;
        return false;
    }

    filterWithType(item, filters) {
        if (item['entryTypeName'] && filters.indexOf(item['entryTypeName']) !== -1) return true
        return false
    }

    filterWithStandort(item) {
        if (item['ref'] && ko.toJS(this.selectedFilterStandort) === item['ref']) return true
        return false
    }

    filterWithFKA(item) {
        if (item['Deadline']["LawfirmCase"]["DeadlineCalendarAccount"] && ko.toJS(this.selectedFilterAccount) === item['Deadline']["LawfirmCase"]["DeadlineCalendarAccount"]) return true
        return false
    }

    async pickCase() {
        let cases = [];
        await RNSAPI.getCasesOverview(0, ko.toJS(20)).then((data) => {
            cases = data as any;
        }).catch((error) => {
            cases = [];
        });

        this.pickGeneric("Akte", ["caseid", "rubrum", "court_caseid_1", "refId"], ["Akte", "Rubrum", "AZ-Gericht 1. Instanz", "Standort"], cases);
        this.modalHandleSelection((selectedObject) => {
            this.filter(selectedObject()["caseid"])
        });
        $('#modal').modal('show');
    };

    async pickClerk() {
        let sachbearbeiter = (await RNSAPI.getSachbearbeiter()).Payload.Clerks.filter(s => s.Sachbearbeiter_ID.trim() !== "");
        this.pickGeneric("Fristenkontrolle wählen", ["Sachbearbeiter"], ["Fristenkontrolle"], sachbearbeiter);
        this.modalHandleSelection((selectedObject) => {
            this.sachbearbeiter(selectedObject()["Sachbearbeiter_ID"]);
        });
        $('#modal').modal('show');
    };

    async getReferate() {
        let res = (await RNSAPI.getReferate()).Payload.Units;
        this.AllReferate(res);
    }

    async getOutlookAccounts() {
        let res = (await RNSAPI.getOutlook()).Payload.OutlookAccounts;
        this.OutlookAccounts(res);
    }

    async update(){
        let deadlines = await this.getDeadlineTasks();
        let appointments = await this.getAppointmentTasks();
        if(deadlines.length > 0)
            this.showFristen(true);
        else
            this.showFristen(false);
        if(appointments.length > 0)
            this.showTermine(true);
        else
            this.showTermine(false);
        this.todos(deadlines.concat(appointments));
    }

    async getAppointmentTasks(){
        let res = (await RNSAPI.getAppointmentProtocolTasks(moment(ko.toJS(this.rStartDate)), moment(ko.toJS(this.rEndDate))));
        let mappedAppointments = res as any;
        return this.mapEntries(mappedAppointments, false);
    }

    async getDeadlineTasks(){
        let res = (await RNSAPI.getDeadlineProtocolTasks(moment(ko.toJS(this.rStartDate)), moment(ko.toJS(this.rEndDate))));
        let mappedDeadlines = res as any;
        return this.mapEntries(mappedDeadlines);
    }

    mapEntries(input, isDeadline=true)
    {
        let mappedDeadlines = input as any;
        mappedDeadlines = mappedDeadlines.map((a) => {
            if(a.TaskState === 0)
                a.stars = "yellow";
            else if(a.TaskState === 1)
                a.stars = "red";
            else if(a.TaskState === 2)
                a.stars = "complete";
            else if(a.TaskState === 3)
                a.stars = "stopped";
            else if(a.TaskState === 4)
                a.stars = "server";

            a.transmission = moment(a.CreatedAt).format("DD.MM.YYYY HH:mm");

            if(isDeadline){
                a.entryTypeName = "Frist";
                a.date = moment(a.Deadline.DeadlineDate).format("DD.MM.YYYY");
                a.ref = a.Deadline.LawfirmCase.Ref;
                a.number = a.Deadline.LawfirmCase.CaseId;
                a.rubrum = a.Deadline.LawfirmCase.Rubrum;
                a.subject = a.Deadline.DeadlineReasonName;
                a.SB = a.Deadline.Clerk.User_Nummer;
            }
            else{
                a.entryTypeName = "Termin";
                a.date = moment(a.Appointment.StartDate).format("DD.MM.YYYY");
                a.ref = a.Appointment.LawfirmCase.Ref;
                a.number = a.Appointment.LawfirmCase.CaseId;
                a.rubrum = a.Appointment.LawfirmCase.Rubrum;
                a.subject = a.Appointment.Subject;
                a.SB = a.Appointment.Clerk.User_Nummer;
            }

            a.columnActions = {
                "number": MainViewModel.RoutingTable.generateLink(`/new/${encodeURIComponent(a.number)}`)
            };

            let externealExport ={
                icon: "file-import", name: "Externer Export", action: async () => {}
            }
            let outlookExportFlag = {
                icon: "calendar", name: "Outlook Export", action: async () => {}
            }

            let editTask = {
                icon: "info-circle", name: "Übertragungsinformationen", action: async () => {
                    this.syncDeadline(true);
                    if(a.Method === 0)
                        this.transmisionMethod("Anlage");
                    else if(a.Method === 1)
                        this.transmisionMethod("Verschiebung");
                    else if(a.Method === 2)
                        this.transmisionMethod("Erledigung");
                    else if(a.Method === 3)
                        this.transmisionMethod("Löschung");

                    this.outlookExport(false);
                    this.externalExport(false);

                    if(a.ExportWay === 1){
                        this.outlookExport(true);
                        if(a.TaskState === 0){
                            this.outlookPending(true);
                            this.outlookError(false);
                        }
                        else
                        {
                            this.outlookPending(false);
                            if(a.TaskState === 2)
                                this.outlookError(false);
                            else 
                                this.outlookError(true);
                        }
                    }
                    else if(a.ExportWay === 2)
                    {
                        this.externalExport(true);
                        if(a.TaskState === 0)
                        {
                            this.externalPending(true);
                            this.externalError(false);
                        }
                        else{
                            this.externalPending(false);
                            if(a.TaskState === 2)
                                this.externalError(false);
                            else
                                this.externalError(true);
                        }
                    }
                    else if(a.ExportWay === 3)
                    {
                        this.externalExport(true);
                        this.outlookExport(true);
                        if(a.TaskState === 0)
                        {
                            this.outlookPending(true);
                            this.externalPending(true);
                            this.externalError(false);
                            this.outlookError(false);
                        }
                        else{
                            this.outlookPending(false);
                            this.externalPending(false);
                            if(a.Deadline.OutlookId)
                                this.outlookError(false);
                            else 
                                this.outlookError(true);
                            if(a.Deadline.ExternalProgramId === "FAILURE")
                                this.externalError(true);
                            else
                                this.externalError(false);
                        }
                    }

                    if(a.LastSync){
                        this.lastSyncDate(moment(a.LastSync).format("YYYY-MM-DD"));
                        this.lastSyncTime(moment(a.LastSync).format("hh:mm"));
                    }
                    else{
                        this.lastSyncDate("YYYY-MM-DD");
                        this.lastSyncTime("HH:mm");
                    }
                    this.selectedStatus(a.TaskState);

                    $("#stateModal").modal("show");
                }
            }

            a.actionHandlers = [];            
            if(a.ExportWay === 1 || a.ExportWay ===3)
                a.actionHandlers.push(outlookExportFlag);
            if(a.ExportWay === 2 || a.ExportWay ===3)
                a.actionHandlers.push(externealExport);
            a.actionHandlers.push(editTask);
            return a;
        });
        return mappedDeadlines;
    }

    constructor() {
        this.rStartDate(ko.toJS(moment().format("YYYY-MM-DD")));
        this.rEndDate(ko.toJS(moment().format("YYYY-MM-DD")));

        this.getReferate();
        this.getOutlookAccounts();
        
        this.update();

        this.filteredTodos = ko.computed({
            owner: this,
            read: () => {
                let filter = this.filter()
                let sachbearbeiter = this.sachbearbeiter()
                let filters = []

                if (this.values().complete()) {
                    filters.push('complete')
                } else {
                    filters.indexOf('complete') !== -1 ? filters.splice(filters.indexOf('complete'), 1) : null
                }

                if (this.values().green()) {
                    filters.push('green')
                } else {
                    filters.indexOf('green') !== -1 ? filters.splice(filters.indexOf('green'), 1) : null
                }

                if (this.values().yellow()) {
                    filters.push('yellow')
                } else {
                    filters.indexOf('yellow') !== -1 ? filters.splice(filters.indexOf('yellow'), 1) : null
                }
                if (this.values().red()) {
                    filters.push('red')
                } else {
                    filters.indexOf('red') !== -1 ? filters.splice(filters.indexOf('red'), 1) : null
                }
                if (this.values().server()) {
                    filters.push('server')
                } else {
                    filters.indexOf('server') !== -1 ? filters.splice(filters.indexOf('server'), 1) : null
                }
                if (this.values().stopped()) {
                    filters.push('stopped')
                } else {
                    filters.indexOf('stopped') !== -1 ? filters.splice(filters.indexOf('stopped'), 1) : null
                }

                const filterTypes = Object.keys(this.types()).filter(item => this.types()[item]())

                let items = this.todos();

                if (ko.toJS(this.selectedFilterStandort()) !== "")
                    items = items.filter(item => this.filterWithStandort(item))

                if (ko.toJS(this.selectedFilterAccount()) !== "")
                    items = items.filter(item => this.filterWithFKA(item))

                if (filters.length) {
                    items = items.filter(item => this.filterWithStars(item, filters))
                }
                if (filterTypes.length) {
                    items = items.filter(item => this.filterWithType(item, filterTypes))
                }
                if (filter) {
                    items = items.filter(item => item['number'] && item['number'].indexOf(filter) !== -1)
                }
                if (sachbearbeiter) {
                    items = items.filter(item => item['SB'] && item['SB'].includes(sachbearbeiter))
                }
                return items
            }
        });

        this.rStartDate.subscribe(() => this.update());
        this.rEndDate.subscribe(() => this.update());
    }
}

var html = fs.readFileSync(__dirname + '/protocol.html', 'utf8');

ko.components.register("protocol-view", {
    viewModel: ProtocolViewModel,
    template: html
});
