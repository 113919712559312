import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { MainViewModel } from "../../main";
import '../dataTable/dataTable';
import '../dataTable/caseTable';
import "../dialog/resubmissionDialog";
export class CaseOverviewViewModel {
    public static action = {
        edit: ""
    }
    cases = ko.observableArray([]);
    referate = ko.observableArray([]);
    emptyStandort = ko.observable(false);
    CurrentUser = ko.observable();
    caseOwn = ko.observable(false)
    ak = ko.observable();
    page = 1;
    totalCount = 0;
    newExternalId = ko.observable("");
    edmCaseId = ko.observable("");
    externalSoftware = ko.observable("");

    async loadCases() {
        let sb = (await RNSAPI.getSachbearbeiter()).Payload.Clerks;
        let currentSB = (await RNSAPI.getLawFirmUser(RNSAPI.getCurrentLoggedInUser().ShortHandSymbol)).Payload.User;
        let technicalRights = currentSB.externalSWRights;
        let SBs = new Map();
        sb.map((b) => { SBs.set(b.Sachbearbeiter_ID, b.Sachbearbeiter); return b })
        let referate = await RNSAPI.getReferate();
        if (referate.Payload.Units.length > 0)
            this.emptyStandort(false);

        let cases = [];

        await RNSAPI.getCasesOverview(this.page, ko.toJS(20)).then((data) => {
            cases = data as any;
        }).catch((error) => {
            cases = [];
        });

        cases.reverse();
        this.cases(cases.map((c) => {
            this.referate(referate.Payload.Units.map((x) => {
                if (x.Id === c.refId) {
                    c.Referat = x.Name;
                }
            }));
            c.SachbearbeiterName = SBs.get(c.processingPartner);

            c.instance = "1. Instanz";

            c.actionHandlers = [{
                icon: "pencil-alt",
                name: "Bearbeiten",
                action: () => {
                    MainViewModel.RoutingTable.showNewView({ caseId: c.caseid });
                }
            }];

            if (c.programid) {
                c.actionHandlers.push({
                    icon: "file-import",
                    name: "Import von " + c.programid + " UniqueId: " + c.uniqueid,
                    action: () => { }
                })
                if (technicalRights === 1) {
                    c.actionHandlers.push({
                        icon: "wrench",
                        name: "CMTpro-Unique ID anpassen",
                        action: () => {
                            this.newExternalId(c.uniqueid);
                            this.edmCaseId(c.caseid);
                            this.externalSoftware(c.programid);
                            $("#exportToExternal").modal("show");
                        }
                    })
                }
            }
            c.actionHandlers.reverse();

            c.columnActions = {
                caseid: MainViewModel.RoutingTable.generateLink(
                    `/new/${encodeURIComponent(c.caseid)}`
                ),
            };
            return c;
        }));
    }

    async editUidExternal() {
        let res = (await RNSAPI.editExternalUniqueId(ko.toJS(this.edmCaseId), ko.toJS(this.newExternalId), ko.toJS(this.externalSoftware)));
        if (res.Type === "UpdateExternalUniqueIdSuccessful") {
            $("#exportToExternal").modal("hide");
            this.loadCases();
        }
    }

    constructor(params: any) {
        this.loadCases();
    }
}

let html = fs.readFileSync(__dirname + '/overview.html', 'utf8');

ko.components.register("caseoverview-view", {
    viewModel: CaseOverviewViewModel,
    template: html
});