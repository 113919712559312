import * as fs from "fs";
import * as ko from "knockout";
import { RNSAPI } from "../../api";
import { Utils } from "../../utils";
import Navigo = require("navigo");
import { Postbox } from "../postbox/postbox";

declare var $: any;


export class HeaderViewModel {

    collapsedSidebar = ko.observable(false);

    timerPaused = ko.observable(false);
    trigger = ko.observable(false);
    currentUser = ko.observable(null);
    router = new Navigo(null, true);
    openDictationModal = ko.observable(false);
    opentimemonitoringModal = ko.observable(false);
    runningMonitoring = ko.observable(false);
    playPause = ko.pureComputed(() => {
        return this.timerPaused() ? 'fa fa-play' : 'far fa-pause-circle';
    });
    buttonType = ko.pureComputed(() => {
        return this.timerPaused() ? 'btn btn-success' : 'btn btn-danger';
    });
    assets = '/assets/';
    slider = ko.observableArray([{
        header: 'Danke, dass Sie sich für Rainmaker entschieden haben!',
        body: '',
        img: this.assets + '01.png',
        hasBack: false,
        hasNext: true
    }, {
        header: '1. Ergänzen Sie Ihr Kanzleiprofil',
        body: '<p class="text-center">Bevor Sie mit Rainmaker loslegen, sollten Sie ihre <strong>Kanzleidaten</strong> überprüfen und ergänzen. <a href="/#/settings"><strong>Klicken Sie hier</strong></a>, um zu Ihrem Kanzleiprofil zu gelangen.</p>',
        img: this.assets + '02.png',
        hasBack: true,
        hasNext: true
    }, {
        header: '2. Richten Sie Ihren Briefkopf ein',
        body: '<p class="text-center mb-1">Zum Konfigurieren des Briefkopfes gibt es zwei Möglichkeiten: </p><small><p class="text-center mb-1"><strong>Quick&amp;Easy Briefkopf</strong>: Überprüfen Sie den Briefkopf, den Rainmaker aus Ihren Kanzleidaten generiert hat und passen dessen Formatierung an. </p><p class="text-center mt-0 mb-2"><em>oder </em></p><p class="text-center"><strong>Eigener Briefkopf</strong>: Laden Sie Ihren Briefkopf im .rtf (Rich Text Format) hoch. Sie können dieses Format direkt aus Ihrer Textverarbeitung erzeugen.</p></small>',
        img: this.assets + '03.png',
        hasBack: true,
        hasNext: true
    }, {
        header: '3. Vervollständigen Sie Ihr Nutzerprofil',
        body: '<p class="text-center mb-3">Richten Sie nun die Kommunikationstruktur Ihrer Kanzlei ein, dazu hinterlegen Sie Nutzerdaten wie z.B. Ihre E-Mail Adresse sowie die beA-Konfiguration. </p><small><p class="text-center mb-2"><strong>E-Mail</strong>: Einstellungen für gängige E-Mail Dienste sind bereits vorkonfiguriert. Nutzen Sie einen anderen E-Mail Dienst, benötigen Sie ggf. einige Konfigurationsdaten, die Sie bei Ihrem Anbieter erhalten.</p><p class="text-center mb-2"><strong>beA: Ihr besonderes elektronisches Anwaltspostfach</strong> können Sie so einrichten, so dass Sie Ihre Nachrichten direkt in Rainmaker abrufen. Beachten Sie, dass Sie für die aktive beA Nutzung, also den Versand von beA Nachrichten, einige clientseitige Voraussetzungen erfüllen müssen. Informationen dazu <a href="#"><strong>finden Sie hier</strong></a>.</p></small>',
        img: this.assets + '04.png',
        hasBack: true,
        hasNext: true
    }, {
        header: '4. Nun legen Sie mit der Aktenanlage los',
        body: '<center><a href="/#/fastcase"><strong>Klicken Sie hier</strong></a></center>',
        img: this.assets + '05.png',
        hasBack: true,
        hasNext: true
    }, {
        header: '5. Fall erledigt? So rechnen Sie ab',
        body: '<center><a href="/#/calculator"><strong>Klicken Sie hier</strong></a></center>',
        img: this.assets + '06.png',
        hasBack: true,
        hasNext: false
    }]);
    currentSlide = ko.observable({});
    currentIndex = ko.observable(0);
    hasTutorial = ko.observable(false);
    currenTimeMonitoring = ko.observable({
        ID: ko.observable(''),
        CaseID: ko.observable(''),
        StartTime: ko.observable('')
    });
    StartTime = ko.observable('');

    logout() {
        Utils.eraseCookie('access_token');
        RNSAPI.sessionLogout();
        this.router.navigate('/login');
    }

    dictation() {
        $("#dictate").addClass("dictate1");
        this.openDictationModal(true);
    }

    timemonitoring() {
        $("#timemonitoring").addClass("dictate1");
        this.opentimemonitoringModal(false);
        this.opentimemonitoringModal(true);
    }

    gotoSettings(path) {
        this.router.navigate(path);
        $("html, body").animate({ scrollTop: 0 }, "fast");
    }

    opennewTab() {
        window.open("/#/cases");
    }

    async nextSlide() {
        if (this.currentIndex() == this.slider().length) return;
        if (this.currentIndex() < this.slider().length) {
            this.currentSlide(this.slider()[this.currentIndex() + 1]);
            this.currentIndex(this.currentIndex() + 1);
        }
        await RNSAPI.UpdateTutorial(this.currentIndex());
    }

    prevSlide() {
        if (this.currentIndex() > 0) {
            this.currentSlide(this.slider()[this.currentIndex() - 1]);
            this.currentIndex(this.currentIndex() - 1);
        }
    }

    slideContent(index) {
        if (index) {
            this.currentSlide(this.slider()[index]);
            this.currentIndex(index);
        }
    }

    hideModal() {
        this.hasTutorial(false);
    }

    formatDate(input: Date) {
        var hoursString = "";
        var minutesString = "";
        if (input.getHours() < 10)
            hoursString = "0" + input.getHours();
        else
            hoursString = "" + input.getHours();

        if (input.getMinutes() < 10)
            minutesString = "0" + input.getMinutes();
        else
            minutesString = "" + input.getMinutes();

        return hoursString + ":" + minutesString;
    }

    public getVM = () => this;

    pauseTimer() {
        this.trigger(true);
        this.timerPaused(!this.timerPaused());
    }

    private setUser() {
        this.currentUser(RNSAPI.getCurrentLoggedInUser());
    }

    constructor() {
        Postbox.publisher().subscribe((response) => {
            this.collapsedSidebar(response);
        }, "CollapsedSidebar");

        Postbox.publisher().subscribe(() => {
            this.setUser();
        }, "setLoggedInUserHeader");

        if (location.hash !== "#/login")
            this.setUser();

        this.currentSlide(this.slider()[0]);
        this.currentIndex(0);
       
    }
}

var html = fs.readFileSync(__dirname + '/header.html', 'utf8');

ko.components.register("header-view", {
    viewModel: HeaderViewModel,
    template: html
});